import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';

import { getDistanceFromLatLonInKm } from 'utils/latlngFunctions';
import { getQuickDropName } from 'utils/misc';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    Icon,
    colors,
    withTheme,
    Typography,
    DialogContentText,
    TextField,
    CircularProgress
} from '@material-ui/core';

import CustomFormTitle from 'components/MaterialUIExtensions/CustomFormTitle';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import ImageCapture from 'components/ImageUploads/ImageCapture';

const PictureConfirmation = ({
    imageURLs,
    imagePreviews,
    handleAddImage,
    handleDeleteImage,
    uploadingImage,
    customerLocation,
    onSnackbar,
    onBack,
    onNext,
    selectedDropLocation,
    onRefreshLocation,
    http,
    inProgress,
    setInProgress,
    overrideLocation,
    onOverrideLocation,
    GPSLoading,
    isLastStep,
    theme
}) => {
    const { lang } = useContext(LocalizationContext);
    const [overrideCodeDialogOpen, setOverrideCodeDialogOpen] = useState(false);
    const [overrideCode, setOverrideCode] = useState('');
    const [overrideCodeError, setOverrideCodeError] = useState(null);

    useEffect(() => {
        onRefreshLocation();
    }, []);

    let quickDropType = getQuickDropName(lang);

    let warningColor = colors.orange[500];
    let warningText = null;
    let warningTitle = null;
    let showWarning = false;
    if (_.isNil(customerLocation) && !overrideLocation) {
        showWarning = true;
        warningTitle = (
            <Typography variant="h6" style={{ color: 'white' }}>
                <b>{loc('pickupDialogDrop12', lang)}</b>
            </Typography>
        );
        warningText = <Typography style={{ color: 'white' }}>{loc('pickupDialogDrop13', lang)}</Typography>;
        warningColor = colors.red[500];
    } else if (!overrideLocation) {
        const distanceMeters =
            getDistanceFromLatLonInKm(
                selectedDropLocation.location.lat,
                selectedDropLocation.location.lng,
                customerLocation.lat,
                customerLocation.lng
            ) * 1000;

        if (distanceMeters > 50) {
            showWarning = true;
            warningTitle = (
                <Typography variant="h6" style={{ color: 'white' }}>
                    <b>{loc('youAreToFarAway', lang)}</b>
                </Typography>
            );
            warningText = (
                <Typography style={{ color: 'white' }}>
                    Please move closer to the drop location and press the refresh button.
                </Typography>
            );
        }
    }

    let disableNext = showWarning || _.isEmpty(imagePreviews);
    if (JSON.parse(process.env.REACT_APP_AUTOMATED_TESTER_MODE)) {
        disableNext = false; //TODO: add override for  testytest
    }

    const gpsRequired = _.get(selectedDropLocation, 'gpsRequired', true);
    if (showWarning && !gpsRequired) {
        disableNext = false;
        showWarning = false;
    }

    const handleVerifyOverrideCode = async () => {
        setInProgress(true);
        const res = await http.postJSON(
            `/collectors/${selectedDropLocation.collector._id}/verifyQuickdropOverrideCode`,
            {
                code: overrideCode
            }
        );
        if (res.ok) {
            if (res.data.valid) {
                onOverrideLocation(true);
                setOverrideCodeDialogOpen(false);
                onSnackbar('Override code was valid');
            } else {
                setOverrideCodeError(res.data.errorMessage);
            }
        } else {
            onSnackbar('error submitting code', 'error');
        }
        setInProgress(false);
    };

    return (
        <>
            <DialogContent>
                <CustomFormTitle
                    titleText={loc('pickupDialogDrop20', lang, {
                        type: quickDropType
                    })}
                    iconName="camera"
                />
                <DialogContentText
                    style={{
                        marginBottom: theme.spacing.unit * 2,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        maxWidth: 420 + theme.spacing.unit
                    }}
                >
                    {loc('pickupDialogDrop21', lang)}
                </DialogContentText>
                <ImageCapture
                    uploadingImage={uploadingImage}
                    imageURLs={imagePreviews}
                    handleAddImage={handleAddImage}
                    handleDeleteImage={handleDeleteImage}
                    placeholderText={<span>{loc('pickupDialogDrop22', lang)}</span>}
                    onSnackbar={onSnackbar}
                />
                <Dialog
                    open={showWarning}
                    PaperProps={{
                        style: {
                            backgroundColor: warningColor
                        }
                    }}
                >
                    <DialogTitle disableTypography>{warningTitle}</DialogTitle>
                    <DialogContent>
                        <div
                        /*style={{
                                margin: '0 auto',
                                marginTop: theme.spacing.unit * 2,
                                padding: theme.spacing.unit * 2,
                                borderRadius: '5px',
                                backgroundColor: warningColor,
                                maxWidth: 420 + theme.spacing.unit
                            }}*/
                        >
                            {warningText}
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Button
                                    data-cy="picture-confirmation-refresh-location"
                                    onClick={onRefreshLocation}
                                    size="small"
                                    variant="outlined"
                                    style={{
                                        color: 'white',
                                        borderColor: 'white',
                                        marginRight: theme.spacing.unit,
                                        marginTop: theme.spacing.unit
                                    }}
                                    disabled={GPSLoading}
                                >
                                    <span style={{ marginRight: theme.spacing.unit }}>
                                        {loc('pickupDialogDrop15', lang)}
                                    </span>
                                    {GPSLoading ? (
                                        <CircularProgress size={22} style={{ color: 'white' }} />
                                    ) : (
                                        <Icon style={{ color: 'white' }}>refresh</Icon>
                                    )}
                                </Button>
                                <Typography
                                    variant="caption"
                                    style={{
                                        textDecoration: 'underline',
                                        color: 'white',
                                        cursor: 'pointer',
                                        marginTop: theme.spacing.unit
                                    }}
                                    onClick={() => setOverrideCodeDialogOpen(true)}
                                >
                                    {loc('pickupDialogDrop14', lang)}
                                </Typography>
                            </span>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onBack} style={{ color: 'white' }}>
                            {loc('pickupDialogDrop16', lang)}
                        </Button>
                    </DialogActions>
                </Dialog>
            </DialogContent>
            <DialogActions style={{ marginBottom: 'max(8px, env(safe-area-inset-bottom, 8px))' }}>
                <Button color="primary" onClick={onBack}>
                    {loc('back', lang)}
                </Button>
                <Button
                    color="primary"
                    onClick={onNext}
                    data-cy="quickdrop-steps-next"
                    disabled={disableNext || GPSLoading}
                >
                    {loc(isLastStep ? 'submit' : 'next', lang)}
                </Button>
            </DialogActions>
            <Dialog open={overrideCodeDialogOpen} onClose={() => setOverrideCodeDialogOpen(false)}>
                <DialogContent>
                    <Typography>{loc('pickupDialogDrop17', lang)}</Typography>
                    <TextField
                        margin="normal"
                        variant="outlined"
                        value={overrideCode}
                        helperText={overrideCodeError}
                        error={!_.isNil(overrideCodeError)}
                        label={loc('pickupDialogDrop18', lang)}
                        onChange={e => setOverrideCode(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={inProgress} onClick={() => setOverrideCodeDialogOpen(false)} color="primary">
                        {loc('Cancel', lang)}
                    </Button>
                    <Button
                        disabled={_.isEmpty(overrideCode) || inProgress}
                        onClick={handleVerifyOverrideCode}
                        color="primary"
                    >
                        <span style={{ marginRight: theme.spacing.unit }}>{loc('pickupDialogDrop19', lang)}</span>{' '}
                        {inProgress && <CircularProgress size={16} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default withTheme()(PictureConfirmation);

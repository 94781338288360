import React, { useState } from 'react';

import _ from 'lodash';
import { convertToImportName } from 'utils/misc';

import { withTheme } from '@material-ui/core/styles';

import {
    Button,
    Grid,
    TextField,
    Typography,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    Table,
    Paper
} from '@material-ui/core';

import * as allIcons from '@mdi/js';
import { Icon as MDIcon } from '@mdi/react';

import { useEffect } from 'react';
import { useMemo } from 'react';

function CollectorInventoryWidget(props) {
    const { commodities, collectors, theme, http, onSnackbar, reload = () => {}, showEmptyInventory } = props;
    const trackedCommodities = useMemo(() => {
        const list = [];
        if (!_.isNil(commodities) && !_.isEmpty(commodities) && Array.isArray(commodities)) {
            commodities.forEach(c => {
                if (c.trackInventory) {
                    list.push(c);
                }
                _.get(c, 'subCommodities', []).forEach(sc => {
                    if (sc.trackInventory) {
                        list.push(sc);
                    }
                });
            });
        }
        return list;
    }, [commodities]);

    const [collectorInventory, setCollectorInventory] = useState(
        getFormFromCollectors(collectors, trackedCommodities, 1)
    );

    useEffect(() => {
        if (!_.isEmpty(trackedCommodities) && !_.isEmpty(collectors)) {
            setCollectorInventory(getFormFromCollectors(collectors, trackedCommodities));
        }
    }, [collectors, trackedCommodities]);

    const handleInventoryChange = (collector_id, commodity_id) => e => {
        let inventoryCopy = _.cloneDeep(collectorInventory);
        _.set(inventoryCopy, `${collector_id}.${commodity_id}`, parseInt(e.target.value));
        setCollectorInventory(inventoryCopy);
    };

    const handleInventorySubmit = async () => {
        const res = await http.post('/collector/updateInventory', {
            collector_ids: _.keys(collectorInventory),
            inventoryMap: collectorInventory
        });
        if (res.ok) {
            await reload();
            onSnackbar('Collector inventory updated');
        } else {
            onSnackbar('Error updating inventory', 'error');
        }
    };
    if (_.isEmpty(trackedCommodities) && !showEmptyInventory) {
        return <></>;
    }
    return (
        <div
            style={{
                padding: theme.spacing.unit,
                paddingLeft: theme.spacing.unit,
                paddingRight: theme.spacing.unit
            }}
        >
            <Grid container spacing={theme.spacing.unit}>
                <Grid item xs={12}>
                    <Paper>
                        <div
                            style={{
                                padding: theme.spacing.unit * 2,
                                paddingBottom: theme.spacing.unit * 2,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                variant="h6"
                                noWrap
                                style={{ flex: '1 0 auto', marginRight: theme.spacing.unit }}
                            >
                                Inventory
                            </Typography>
                            <Button variant="contained" color="primary" onClick={handleInventorySubmit}>
                                Save
                            </Button>
                        </div>
                        <div style={{ overflow: 'auto' }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="default">Collector</TableCell>
                                        {trackedCommodities.map((commodity, i) => {
                                            return (
                                                <TableCell key={i} padding={'default'}>
                                                    <span>
                                                        <MDIcon
                                                            path={_.get(
                                                                allIcons,
                                                                _.startsWith(commodity.iconName, 'mdi')
                                                                    ? commodity.iconName
                                                                    : convertToImportName(commodity.iconName)
                                                            )}
                                                            size={1}
                                                            color={commodity.color}
                                                            style={{ padding: 0, verticalAlign: 'middle' }}
                                                        />
                                                    </span>
                                                    {_.get(
                                                        commodity,
                                                        'name.en',
                                                        _.get(commodity, 'payloadInputName.en', 'N/A')
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <div style={{ overflow: 'auto', maxHeight: '750px' }}>
                                <Table style={{ tableLayout: 'fixed', marginBottom: theme.spacing.unit * 2 }}>
                                    <TableBody>
                                        {collectors.map(collector => {
                                            return (
                                                <TableRow>
                                                    <TableCell padding="default">{collector.name}</TableCell>
                                                    {trackedCommodities.map((commodity, i) => {
                                                        return (
                                                            <TableCell key={i} padding={'default'}>
                                                                <TextField
                                                                    error={
                                                                        _.get(
                                                                            collectorInventory,
                                                                            `${collector._id}.${commodity._id}`,
                                                                            0
                                                                        ) < 0
                                                                    }
                                                                    value={_.get(
                                                                        collectorInventory,
                                                                        `${collector._id}.${commodity._id}`,
                                                                        0
                                                                    )}
                                                                    type="number"
                                                                    label=""
                                                                    style={{
                                                                        marginTop: theme.spacing.unit * 2,
                                                                        width: '50px'
                                                                    }}
                                                                    data-cy={`${collector._id}-${commodity._id}-input`}
                                                                    onChange={handleInventoryChange(
                                                                        collector._id,
                                                                        commodity._id
                                                                    )}
                                                                />
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}

export default withTheme()(CollectorInventoryWidget);

const getFormFromCollectors = (collectors, commodities = [], caller = 0) => {
    let form = {};
    collectors.forEach(c => {
        let inventory = _.get(c, 'inventory', {});
        commodities.forEach(commodity => {
            if (_.isNil(_.get(inventory, commodity._id))) {
                _.set(inventory, commodity._id, 0);
            } else {
                _.set(inventory, commodity._id, parseInt(_.get(inventory, commodity._id), 0));
            }
        });
        _.set(form, c._id, inventory);
    });
    return form;
};

import React, { useState, useMemo, useContext, useEffect, useCallback, useRef } from 'react';

import { _bulk, _commodity, _user } from 'std';
import moment from 'moment-timezone';

import _ from 'lodash';
import { formatAsCurrency, getAppBarHeight, getBulkCounterFinancials, isProductionEnv, deviceHelper } from 'utils/misc';

// custom components
import BulkFeesSelectionDialog from 'components/BulkComponents/BulkFeesSelectionDialog';
import BulkReportDialog from 'components/BulkComponents/BulkReportDialog';
import BulkComplaintDialog from 'components/BulkComponents/BulkComplaintDialog';
import BulkErrorDialog from 'components/BulkComponents/BulkErrorDialog';
// panels
import CountInputPanel from './CountInputPanel';
import CountsPanel from './CountsPanel';
import BulkInfoAndHistoryPanel from './BulkInfoAndHistoryPanel';
import AttachCustomerDialog from './AttachCustomerDialog';
import EmailReceiptDialog from './EmailReceiptDialog';
import NumberOfBagsDialog from './NumberOfBagsDialog';
import EditNumberOfBagsDialog from './EditNumberOfBagsDialog';
import CreateEditNumberOfCommoditiesDialog from './CreateEditNumberOfCommoditiesDIalog.js';
import UncountedBagsDialog from './UncountedBagsDialog';
import EnterEmailDialog from './EnterEmailDialog';
import DiscardSessionDialog from './DiscardSessionDialog';

import Receipt from './Receipt';

// npm components
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from '@material-ui/core/Popover';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import withWidth from '@material-ui/core/withWidth';
import CircularProgress from '@material-ui/core/CircularProgress';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    OutlinedInput,
    IconButton,
    TextField,
    Icon,
    Tooltip
} from '@material-ui/core';
import { colors } from '@material-ui/core';
import UploadFileIcon from '@material-ui/icons/CloudUpload';
import VideocamIcon from '@material-ui/icons/Videocam';

import DialogTitlePrimary from 'components/MaterialUIExtensions/DialogTitlePrimary';

import { withTheme } from '@material-ui/core/styles';

import { Icon as MDIcon } from '@mdi/react';

import {
    mdiArrowLeft,
    mdiPencil,
    mdiCheck,
    mdiDialpad,
    mdiFormatListBulleted,
    mdiInformation,
    mdiChartBox,
    mdiDelete,
    mdiPlusBox,
    mdiCodeJson,
    mdiAccount,
    mdiArrowDownDropCircle
} from '@mdi/js';

import HighResImageWidget from 'components/HighResImageWidget/HighResImageWidget';

// custom hooks
import usePORScreen from './hooks/usePORScreen';
import useSpeedMode from './hooks/useSpeedMode';
import useEmailReceiptDialog from './hooks/useEmailReceiptDialog';
import usePopover from 'components/Popovers/usePopover';
import useWindowSize from 'utils/hooks/useWindowSize';
import useImageCompressor from './hooks/useImageCompressor';

// contexts
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import OperatorContext from 'utils/contexts/OperatorContext';

import DebugContext from 'components/CustomDebugDialog/DebugContext';

import { BULK_STATUS_COLORS, COMMODITY_COLORS } from 'constants.js';

import HttpContext from 'utils/contexts/HttpContext';

import Webcam from 'react-webcam';

import uuid from 'uuid';
import { CustomChip } from './HistoryBulkListItem';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { loc } from 'localizations/localizationHandler';

import ImageCapture from 'components/ImageUploads/ImageCapture';
import ImageViewer from 'components/ImageUploads/ImageViewer';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import CustomMUIDialog from 'components/Misc/CustomMUIDialog';
import { getComEquivalent, shortenSubComName } from 'containers/Operators/PORScreen/NumberOfBagsDialog';
import GeneralWarningDialog from 'components/Dialogs/Warning';

const COUNTING_MODES = ['classic', 'keypad'];
const idleTime = 120;
const decisionTime = 60;
const CLERK_VIDEO_FILENAME_PREFIX = 'CV';

function PORScreen({
    theme,
    width,
    location,
    match,
    history,
    onEmitCountsUpdated,
    onEmitSwitchedBulk,
    onSnackbar,
    resetTimeoutCount,
    rolePermissions,
    setShowResultDialog,
    setResultDialogText
}) {
    const { lang } = useContext(LocalizationContext);
    const webcamRef = React.useRef(null);
    const webcamRef2 = React.useRef(null);

    const warnAction = useContext(ConfirmDialogContext);
    const operator = useContext(OperatorContext);
    const [windowWidth, height] = useWindowSize();

    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [countingMode, setCountingMode] = useState(localStorage.getItem('PORScreenCountingMode') || 'keypad');

    const [disclaimer, setDisclaimer] = useState('');
    const [showGeneralWarningDialog, setShowGeneralWarningDialog] = useState(false);
    const [generalWarningBody, setGeneralWarningBody] = useState('');
    const [closeOrderMessage, setCloseOrderMessage] = useState('');

    const http = useContext(HttpContext);

    const [reportOptions, setReportOptions] = useState([]);
    const loadReportOptions = async () => {
        const res = await http.getJSON('/system/configuration/clerkComplaintOptions/en');
        if (res.ok) {
            setReportOptions(res.data.options);
        }
    };

    const [commodities, setCommodities] = useState([]);
    const fetchCommodityColors = async () => {
        const res = await http.getJSON('/commodities/getAllCommodities');
        setCommodities(_.get(res, 'data.collectionData', []));
    };

    const commodityColors = {};
    commodities.forEach(commodity => {
        commodityColors[_commodity.getSkuType(commodity)] = _.get(commodity, 'color');
    });

    const allCommodityColors = { ...COMMODITY_COLORS, ...commodityColors };

    useEffect(() => {
        loadReportOptions();
        fetchCommodityColors();
        return () => {
            onEmitSwitchedBulk(null); //notify live counts screen that the counter left the bulk
        };
    }, []);

    useEffect(() => {
        if (windowWidth > 0 && windowWidth <= 510) {
            setCountingMode('keypad');
        }
    }, [windowWidth]);

    const handleImageDialog = state => {
        setImageDialogOpen(state);
    };

    const onDebug = useContext(DebugContext);
    const [showStreamPopup, setShowStreamPopup] = useState(false);

    const {
        bulk,
        bulkSkus, //use rates from bulk not collector, if the collector rates change the bulk should still be counted with the old rates
        customerHistory,
        countForm,
        customFeesForm,
        inProgress,
        promoCodes,
        bulkDataLoading,
        collector,
        collectors,
        customFeeDialogOpen,
        customFeeDialogCount,
        customerIssueResolution,
        currentCounts,
        complaintDialogOpen,
        errorDialogOpen,
        reportDialogOpen,
        reportType,
        reportReasons,
        reportImages,
        reportPreviews,
        reportIssueDescription,
        bulkDataLoadingError,
        bulkStationConfig,
        allBulksFromSameCustomer,
        attachCustomerDialogOpen,
        customerInfo,
        numberOfBagsDialogOpen,
        nonConformantIssue,
        nonConformantWarning,
        commodityForBulk,
        permissionsForBulk,
        aiCountsConfig,
        showLAFImageDialog,
        lafPreviews,
        adjustmentConfig,
        handleAddLafImage,
        handleDeleteLafImage,
        editNumberOfBagsDialogOpen,
        setEditNumberOfBagsDialogOpen,
        uncountedBagsDialogOpen,
        setUncountedBagsDialogOpen,
        fetchBulkData,
        handleReportIssueDescription,
        handleCreateBulk,
        handleToggleComplete,
        handleCustomFeeDialog,
        handleChangeCollector,
        handleReportReasonsChange,
        handleAddImage,
        handleDeleteImage,
        handleSubmitReport,
        handleCloseReportDialog,
        handleOpenReportDialog,
        //handleUpdateBulkCustomFees,
        handleApplyCustomFee,
        handleRemoveCustomFee,
        handleCountFormChange,
        handleRemoveCounts,
        handleViewBulk,
        handleSubmitCounts,
        handleApplyPromo,
        handleRemovePromo,
        handleUndoBulkReport,
        handleToggleResolveCustomerIssue,
        handleChangeCustomerIssueResolution,
        handleAddCount,
        handleOpenComplaintDialog,
        handleCloseComplaintDialog,
        handleClearCountsAndFees,
        handleDeleteBulk,
        handleSetCharity,
        handleOpenAttachCustomer,
        handleCloseAttachCustomer,
        handleChangeCustomerInfo,
        handleAttachCustomer,
        handleUpdateCommodityProcessed,
        handleUpdateCommodityAmount,
        handleCloseNumberOfBagsDialog,
        handleLAFImageSubmit,
        handleCloseLAFImageDialog,
        handleRemoveCharity,
        handleOpenLAFImageDialog,
        handleCloseEditNumberOfBagsDialog,
        updateCountBags,
        handleUpdateCommoditesProccessedBreakdown,
        handleUpdateAdjustmentReason,
        countingSessions,
        setCountingSessions,
        currentCountingSessionId,
        setCurrentCountingSessionId,
        createEditNumberOfCommoditiesDialogOpen,
        setCreateEditNumberOfCommoditiesDialogOpen,
        handleRemoveCountingSession,
        handleRemoveSessionlessCounts,
        handleRemoveCustomFeeFromSessionAndBulk,
        handleDetachCustomer,
        showAICountImageDialog,
        AICountImages,
        AICountPreviews,
        setAICountPreviews,
        handleDeleteAICountImage,
        handleAddAICountImage,
        setShowAICountImageDialog,
        handleAddAICounts,
        createNewCountingSession,
        creatEditNumberOfCommoditiesMode,
        setCreatEditNumberOfCommoditiesMode,
        addEmptySession,
        handleShowErrorDialog,
        handleUpdateReferenceNote
    } = usePORScreen({
        location,
        match,
        history,
        commodities,
        onEmitCountsUpdated,
        onEmitSwitchedBulk,
        setShowStreamPopup
    });
    const [showDiscardSessionDialog, setShowDiscardSessionDialog] = useState(false);
    const [triggerExit, setTriggerExit] = useState({
        onOk: false,
        path: ''
    });
    const [isDeletingOrder, setIsDeletingOrder] = useState(false);

    const handleGoToIntendedPage = useCallback(location => history.push(location), [history]);

    useEffect(() => {
        if (triggerExit.onOk) {
            handleGoToIntendedPage(triggerExit.path);
        }
        const unblock = history.block(location => {
            setTriggerExit(obj => ({ ...obj, path: location.pathname }));
            if (isDeletingOrder) {
                setTriggerExit(obj => ({
                    ...obj,
                    onOk: true
                }));
                return true;
            }
            if (_.isNil(currentCountingSessionId) || _.isEmpty(currentCountingSessionId)) {
                setTriggerExit(obj => ({
                    ...obj,
                    onOk: true
                }));
                return true;
            }
            let currentSession = _.find(countingSessions, { _id: currentCountingSessionId });
            if (_.isNil(currentSession)) {
                setTriggerExit(obj => ({
                    ...obj,
                    onOk: true
                }));
                return true;
            }
            let items = _.get(currentSession, 'items', []);
            if (!_.isNil(items) && !_.isEmpty(items)) {
                setTriggerExit(obj => ({
                    ...obj,
                    onOk: true
                }));
                return true;
            }
            let fees = _.get(currentSession, 'customFees', []);
            if (!_.isNil(fees) && !_.isEmpty(fees)) {
                setTriggerExit(obj => ({
                    ...obj,
                    onOk: true
                }));
                return true;
            }
            setShowDiscardSessionDialog(true);
            return false;
        });
        return () => {
            unblock();
        };
    }, [handleGoToIntendedPage, history, currentCountingSessionId, countingSessions, isDeletingOrder]);

    const [showEmailDialog, setShowEmailDialog] = useState(false);
    const [emailDialogMode, setEmailDialogMode] = useState('eTransfer');

    const [images, setImages] = useState(_bulk.getPickupOrDropOffPhotoURL(bulk || {}));

    const [showIdleDialog, setShowIdleDialog] = useState(false);
    const [webcamIsStarting, setWebcamIsStarting] = useState(false);
    const [recordFinished, setRecordFinished] = useState(false);
    const [idleCountdown, setIdleCountdown] = useState(0);
    const [videoDevices, setVideoDevices] = useState([]);
    const [currentVideoDevice, setCurrentVideoDevice] = useState({});
    const [webcamSettingsDialogOpen, setWebcamSettingsDialogOpen] = useState(false);

    const [decisionCountdown, setDecisionCountdown] = useState(0);
    const [decisionServiceIDState, setDecisionServiceID] = useState('');
    const [idleServiceIDState, setIdleServiceID] = useState('');

    const [initialCounts, setInitialCounts] = useState(null);

    const [countDifference, setCountDifference] = useState(null);

    const [hidePreset, setHidePreset] = useState(localStorage.getItem('hidePreset') === 'true' || false);
    const [hideKeypad, setHideKeypad] = useState(localStorage.getItem('hideKeypad') === 'true' || false);
    const [hideKeyboard, setHideKeyboard] = useState(localStorage.getItem('hideKeyboard') === 'true' || false);
    const [keySize, setKeySize] = useState(localStorage.getItem('keySize') || 'Normal');

    const [cameraIsOn, setCameraIsOn] = useState(false);
    const [idleServiceHasStarted, setIdleServiceHasStarted] = useState(false);
    const [countsChanged, setCountsChanged] = useState(false);
    const [currentBulkID, setCurrentBulkID] = useState(null);
    const [videoFilename, setVideoFilename] = useState(null);

    const currentBulkType = _.get(bulk, 'bulkType', '');
    const haveVideoPermission = _.get(operator, 'collector.configuration.bulkPermissions.clerkVideo.create', false);
    const haveCloseOrderPermission = _.get(
        operator,
        `collector.configuration.bulkPermissions.${currentBulkType}.close`,
        true
    );
    const haveCloseIncompleteOrderPermission = _.get(
        operator,
        `collector.configuration.bulkPermissions.closeIncompleteOrders.update`,
        true
    );
    let hideCustomerIdentification = false;
    if (operator.accountType === 'Collector Administrator' && !operator.adminView) {
        hideCustomerIdentification = _.get(operator, `collector.hideCustomerIdentification.hideForAdmin`, false);
    } else if (
        operator.accountType === 'Collector Employee' &&
        !operator.adminView &&
        operator.accountPermissions.includes('Clerk')
    ) {
        hideCustomerIdentification = _.get(operator, `collector.hideCustomerIdentification.hideForClerk`, false);
    }
    const isSystemCollectorAdmin =
        operator.accountType === 'System Administrator' ||
        _user.isInternalRole(operator) ||
        operator.accountType === 'Collector Administrator';
    const [printingReceipt, setPrintingReceipt] = useState(false);
    const countsScreenToPrintRef = React.useRef();

    const handleVideoDevice = device => {
        const deviceId = _.get(device, 'deviceId', '');

        localStorage.setItem(`defaultVideoDevice`, deviceId);
        setCurrentVideoDevice(device);
    };

    useEffect(() => {
        const defaultVideoDeviceId = localStorage.getItem('defaultVideoDevice');

        const defaultDevice = _.find(videoDevices, { deviceId: defaultVideoDeviceId });
        if (defaultDevice) {
            setCurrentVideoDevice(defaultDevice);
        } else if (videoDevices.length > 0) {
            setCurrentVideoDevice(videoDevices[0]);
        }
    }, [videoDevices]);

    function handlePrintReceipt(state) {
        setPrintingReceipt(state);
    }

    function bootCamera() {
        // if everything loaded fine and webcam has not started, send signal to boot up camera
        if (_.isNil(bulkDataLoadingError) && !bulkDataLoading && !webcamIsStarting) {
            // only start recording if the user is not an admin and is a clerk, and the bulk has not been completed, and bulk is not adjustment, and collector have permission to capture video
            if (
                operator.accountType === 'Collector Employee' &&
                !operator.adminView &&
                operator.accountPermissions.includes('Clerk') &&
                !_.isNil(bulk) &&
                bulk.bulkType !== 'adjustment' &&
                _.isNil(bulk.dateCompleted) &&
                haveVideoPermission
            ) {
                setWebcamIsStarting(true);
            }
        }
    }

    // if camera has started without problem, then we can start idle service
    if (cameraIsOn && !idleServiceHasStarted) {
        onSnackbar(`Webcam recording started`);
        setIdleServiceHasStarted(true);
        startIdleService();
    }

    const aggregatedCounts = useMemo(
        () =>
            _.isNil(bulkSkus) || _.isNil(bulk)
                ? null
                : _bulk.getCountsItemsWithAmount(bulk, bulkSkus[bulk.skuType] || []),
        [bulk, bulkSkus]
    );

    const getDevices = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(({ kind }) => kind === 'videoinput');

        setVideoDevices(videoDevices);
    };
    const requestStream = constraints =>
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(stream => {
                getDevices();
            })
            .catch(err => {
                // onSnackbar('Unable to access camera', 'error');
            });

    useEffect(() => {
        (async () => {
            const constraints = {
                video: {
                    deviceId: _.get(currentVideoDevice, 'deviceId')
                }
            };
            requestStream(constraints);
        })();
    }, []);

    // useEffect(() => {
    //     (async () => {
    //         const devices = await navigator.mediaDevices.enumerateDevices();
    //         const videoDevices = devices.filter(({ kind }) => kind === 'videoinput');

    //         setVideoDevices(videoDevices);
    //     })();
    // }, []);

    useEffect(() => {
        let bulkChanged = false;
        // keep track of current bulk ID
        if (!_.isNil(bulk) && _.isNil(currentBulkID)) {
            setCurrentBulkID(bulk._id);
        }
        // create video filename
        if (_.isNil(videoFilename)) {
            setVideoFilename(`${CLERK_VIDEO_FILENAME_PREFIX}_${uuid.v4()}.webm`);
        }

        // bulk changed
        if (!_.isNil(bulk) && !_.isNil(currentBulkID) && currentBulkID !== bulk._id) {
            bulkChanged = true;
            setCurrentBulkID(bulk._id);
        }

        if (bulkChanged) {
            setVideoFilename(null);
            setCountDifference([]);
            setCommoditiesProcessedBreakdownDelta([]);
            setCountsChanged(false);
            // set initialCounts once and only once for each bulk
            if (!_.isNil(bulkSkus) && !_.isNil(bulk) && !_.isNil(aggregatedCounts)) {
                setInitialCounts(aggregatedCounts);
            }
        } else {
            // update count difference
            if (!_.isNil(bulkSkus) && !_.isNil(bulk) && !_.isNil(aggregatedCounts) && !_.isNil(initialCounts)) {
                let newCountDifference = getCountDifference(initialCounts, aggregatedCounts);
                setCountDifference(newCountDifference);
                setCountsChanged(!_.isEmpty(newCountDifference));
            }
            // set initialCounts once and only once for each bulk
            if (!_.isNil(bulkSkus) && !_.isNil(bulk) && !_.isNil(aggregatedCounts) && _.isNil(initialCounts)) {
                setInitialCounts(aggregatedCounts);
            }
        }

        // if user switched to a different bulk, reset video capture control states
        if (cameraIsOn && !_.isNil(bulk) && bulkChanged) {
            finishRecording();
            resetCamera();
        }
    }, [bulk, bulkSkus, initialCounts]);

    useEffect(() => {
        if (idleCountdown >= idleTime) {
            clearInterval(idleServiceIDState);
            startDecisionService();
            setShowIdleDialog(true);
        }
    }, [idleCountdown]);
    useEffect(() => {
        if (decisionCountdown >= decisionTime) {
            clearInterval(decisionServiceIDState);
            setShowIdleDialog(false);
            finishRecording();
        }
    }, [decisionCountdown]);

    function startIdleService() {
        let idleServiceID = setInterval(() => {
            setIdleCountdown(state => state + 1);
        }, 1000);
        setIdleServiceID(idleServiceID);
    }

    function startDecisionService() {
        let decisionServiceID = setInterval(() => {
            setDecisionCountdown(state => state + 1);
        }, 1000);
        setDecisionServiceID(decisionServiceID);
    }

    const setSubPayloadsDisclaimer = () => {
        const subPayloads = _.get(bulk, 'pickup.subPayloads', []);
        if (_.isEmpty(subPayloads)) return '';

        const tmpSubPayloads = _.cloneDeep(subPayloads);
        _commodity.populateSubPayloadsSubCommodities(tmpSubPayloads, commodities);

        let commodityUnitEquivalent = 0;
        const subPayloadsTextBreakdown = [];

        for (let subPayload of tmpSubPayloads) {
            const amount = subPayload.amount;
            const name = _.get(subPayload, 'subCommodity.name.en', 'Unknown');
            const multiplier = _.get(subPayload, 'subCommodity.multiplier', 0);

            commodityUnitEquivalent += Math.ceil(amount * multiplier);
            subPayloadsTextBreakdown.push(`${amount}x ${name}`);
        }

        setDisclaimer(`Driver reported ${subPayloadsTextBreakdown.join(', ')}${' '}
        which represents ${commodityUnitEquivalent} of the ${bulk.commodityAmount} bags`);
    };

    useEffect(() => {
        setSubPayloadsDisclaimer();
    }, [bulk, commodities]);

    const comodityTotalAmount = _.get(bulk, 'commodityAmount', 0);
    const commoditiesProcessed = _.get(bulk, 'commoditiesProcessed', 0);
    const commodityAmountRemaining = comodityTotalAmount - commoditiesProcessed;
    const bulkHasNoBags = comodityTotalAmount === 0;

    const [bagsToProcess, setBagsToProcess] = useState(
        getInitialBagsToProcess(bulkHasNoBags, commodityAmountRemaining, collector)
    );
    const [commoditiesProcessedBreakdownDelta, setCommoditiesProcessedBreakdownDelta] = useState([]);
    const [initialBulk, setInitialBulk] = useState(null);
    useEffect(() => {
        if (!_.isNil(bulk) && _.isNil(initialBulk)) {
            setInitialBulk(bulk);
        }
    }, [bulk]);
    useEffect(() => {
        if (!_.isNil(initialBulk) && !_.isNil(bulk)) {
            const initialCommoditiesProcessedBreakdown = _.cloneDeep(
                _.get(initialBulk, 'commoditiesProcessedBreakdown', [])
            );
            const newCommoditiesProcessedBreakdown = _.cloneDeep(_.get(bulk, 'commoditiesProcessedBreakdown', []));
            let commodityMap = {};
            newCommoditiesProcessedBreakdown.forEach(item => {
                if (!item.isSubCommodity) {
                    commodityMap['main'] = item;
                } else {
                    commodityMap[item.subCommodity] = item;
                }
            });
            initialCommoditiesProcessedBreakdown.forEach(item => {
                if (!item.isSubCommodity) {
                    if (commodityMap['main'] === undefined) {
                        commodityMap['main'] = { ...item, amount: item.amount * -1 };
                    } else {
                        commodityMap['main'].amount -= item.amount;
                    }
                } else {
                    if (commodityMap[item.subCommodity] === undefined) {
                        commodityMap[item.subCommodity] = { ...item, amount: item.amount * -1 };
                    } else {
                        commodityMap[item.subCommodity].amount -= item.amount;
                    }
                }
            });
            let commodityArray = Object.values(commodityMap).filter(item => item.amount !== 0);
            setCommoditiesProcessedBreakdownDelta(commodityArray);
        } else {
            setCommoditiesProcessedBreakdownDelta([]);
        }
    }, [bulk, initialBulk]);

    function finishRecording() {
        if (recordFinished) return;
        if (cameraIsOn) onSnackbar(`Webcam recording finished`);
        setRecordFinished(true);
    }
    function resetCamera() {
        clearInterval(idleServiceIDState);
        clearInterval(decisionServiceIDState);
        setWebcamIsStarting(false);
        setRecordFinished(false);
        setIdleServiceHasStarted(false);
        setCameraIsOn(false);
        setIdleCountdown(0);
        setDecisionCountdown(0);
    }
    function restartRecording() {
        setVideoFilename(null);
        setCountDifference([]);
        setCommoditiesProcessedBreakdownDelta([]);
        setCountsChanged(false);
        // reset initialCounts
        if (!_.isNil(bulkSkus) && !_.isNil(bulk) && !_.isNil(aggregatedCounts)) {
            setInitialCounts(aggregatedCounts);
        }
        resetCamera();
        setTimeout(() => {
            setWebcamIsStarting(true);
        }, 10);
    }

    const { speedMode, handleToggleSpeedMode } = useSpeedMode();

    const handleToggleHidePreset = () => {
        const newHidePreset = !hidePreset;
        setHidePreset(newHidePreset);
        localStorage.setItem('hidePreset', JSON.stringify(newHidePreset));
    };
    const handleToggleHideKeypad = () => {
        const newHideKeypad = !hideKeypad;
        setHideKeypad(newHideKeypad);
        localStorage.setItem('hideKeypad', JSON.stringify(newHideKeypad));
    };
    const handleToggleHideKeyboard = () => {
        const newHideKeyboard = !hideKeyboard;
        setHideKeyboard(newHideKeyboard);
        localStorage.setItem('hideKeyboard', JSON.stringify(newHideKeyboard));
    };

    const { adminView, disableEditing, disableAddingFees, disableReopen, disableCreateBulk } = useMemo(
        () =>
            getSettings({
                bulk,
                operator,
                commodityForBulk,
                bulkPermissions: _.get(collector, 'configuration.bulkPermissions', {})
            }),
        [bulk, operator, commodityForBulk, _.get(collector, 'configuration.bulkPermissions', {})]
    );

    // grab financials
    const { totalCountFormAmount, grandTotal, totalFees } = useMemo(
        () => getBulkCounterFinancials({ bulk, skus: bulkSkus, countForm, currentCounts }),
        [bulk, countForm, currentCounts, bulkSkus]
    );

    const handleBack = () => {
        if (totalCountFormAmount !== 0) {
            warnAction(() => {
                history.push(`/operators/${operator._id}/bulks`);
            }, 'You have unsubmitted counts. If you leave, your progress will be lost. Are you sure you want to do this?');
        } else {
            history.push(`/operators/${operator._id}/bulks`);
        }
    };

    const { anchorEl, handleClose: handleClosePopover, handleOpen: handleOpenPopover } = usePopover();

    const {
        customerEmail,
        error: customerEmailError,
        emailReceiptDialogOpen,
        handleOpenEmailReceiptDialog,
        handleCloseEmailReceiptDialog,
        handleCustomerEmailChange,
        handleSubmitEmailReceipt
    } = useEmailReceiptDialog({
        bulk,
        initialEmail: _.get(bulk, 'owner.email', ''),
        handleBack,
        setShowResultDialog,
        setResultDialogText,
        emailDialogMode
    });

    const [showCloseOrderDialog, setShowCloseOrderDialog] = useState(false);
    const handleCloseOrder = () => {
        handleToggleComplete();
        clearInterval(idleServiceIDState);
        clearInterval(decisionServiceIDState);
        finishRecording();
        setShowCloseOrderDialog(false);
    };

    if (!_.isNil(bulkDataLoadingError)) {
        return <Typography variant="h5">{bulkDataLoadingError}</Typography>;
    } else if (bulkDataLoading) {
        return (
            <div className="fade-in" style={{ margin: '0 auto', marginTop: '34vh', textAlign: 'center' }}>
                <Typography variant="h6" style={{ marginBottom: theme.spacing.unit * 2 }}>
                    Loading Order...
                </Typography>
                <CircularProgress size={60} style={{ color: theme.palette.envColor[500] }} />
            </div>
        );
    }

    const handleToggleLogic = async total => {
        if (_bulk.isWalkIn(bulk) && !_bulk.isCompleted(bulk)) {
            clearInterval(idleServiceIDState);
            clearInterval(decisionServiceIDState);
            finishRecording();
            handleOpenEmailReceiptDialog();
        } else if (_bulk.isCompleted(bulk)) {
            warnAction(() => {
                handleToggleComplete();
            }, 'You are about to re-open this bulk and notify the customer. Are you sure you want to do this?');
        } else {
            await createCloseOrderMessage(bulk, `${formatAsCurrency(total)}`);
            setShowCloseOrderDialog(true);

            // warnAction(() => {
            //     handleToggleComplete();
            //     clearInterval(idleServiceIDState);
            //     clearInterval(decisionServiceIDState);
            //     finishRecording();
            // }, `You are about to submit an order for ${formatAsCurrency(total)}. Is this correct?`);
        }
    };

    // Fetch most up to date bulk data prior to submitting/reopenning
    const handleSubmit = async () => {
        resetTimeoutCount();
        const { bulk, currentCounts } = await fetchBulkData();
        const { grandTotal, totalCountFormAmount } = getBulkCounterFinancials({
            bulk,
            skus: bulkSkus,
            countForm,
            currentCounts
        });
        const total = grandTotal + totalCountFormAmount;
        handleToggleLogic(total); // New total is passed as argument so the confirm dialog matches the new data without having to wait for state to update
    };

    const bulkStatus = _bulk.getStatus(bulk);
    const heightOffset = ['xs', 'sm'].includes(width) ? theme.spacing.unit * 4 : -theme.spacing.unit;

    const updatedCountingMode = countingMode;
    const isDesktopOrTabletView = width !== 'xs' && width !== 'sm';

    const labelStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `0 ${theme.spacing.unit}px`
    };

    const tabStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };

    const disableCompleteSystemAdmin =
        inProgress || _bulk.isRedeemed(bulk) || _bulk.isInvoiced(bulk) || totalCountFormAmount > 0;
    const disableCompleteOtherAccountTypes =
        inProgress ||
        _bulk.isRedeemed(bulk) ||
        _bulk.isInvoiced(bulk) ||
        !_commodity.isManuallyCompleted(commodityForBulk) ||
        totalCountFormAmount > 0 ||
        (_bulk.isCompleted(bulk) && disableReopen) ||
        !_.get(permissionsForBulk, 'update', false);

    const disableCompleteButton =
        _user.isSystemAdmin(operator) || _user.isInternalRole(operator)
            ? disableCompleteSystemAdmin
            : disableCompleteOtherAccountTypes;

    const enabledCollectors = _.filter(collectors, collector => {
        if (_.isNil(collector.disabled)) return true;
        if (collector.disabled === false) return true;
        return false;
    });

    function checkAndSubmit() {
        if (!haveCloseIncompleteOrderPermission && !isSystemCollectorAdmin && !_bulk.isCompleted(bulk)) {
            const currentSkuCom = _.find(commodities, {
                skuType: _.get(bulk, 'skuType', '')
            });
            let numOfSubCom = getComEquivalent(bulk, commodities, true);
            let totalNumOfBags = _.get(bulk, 'commodityAmount', 0) - numOfSubCom;
            let totalSubCommodities = _.get(bulk, 'subPayloads', []);
            let sortedSubCommodities = _.get(bulk, 'commoditiesProcessedBreakdown', []);
            let sortedNumOfBags = _.get(
                sortedSubCommodities.find(c => c.isSubCommodity === false),
                'amount',
                0
            );
            let remainingCommodities = [];
            let remainingBags = totalNumOfBags - sortedNumOfBags;
            if (remainingBags > 0) {
                remainingCommodities.push(`${remainingBags} ${remainingBags > 1 ? 'Bags' : 'Bag'}`);
            }
            for (let i = 0; i < totalSubCommodities.length; i++) {
                let commodity = totalSubCommodities[i];
                let subCommodityId = _.get(commodity, 'subCommodity');
                let totalNumOfCommodity = _.get(commodity, 'amount');
                let sortedNumOfCommodity = _.get(
                    sortedSubCommodities.find(c => c.subCommodity === subCommodityId),
                    'amount',
                    0
                );
                let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
                let subCommodityInfo = _.find(subCommodityLookup, {
                    _id: subCommodityId
                });
                let name = _.get(subCommodityInfo, `name.${lang}`, '');
                let remainingNumOfCommodities = totalNumOfCommodity - sortedNumOfCommodity;
                if (remainingNumOfCommodities > 0) {
                    remainingCommodities.push(
                        `${remainingNumOfCommodities} ${shortenSubComName(name, remainingNumOfCommodities)}`
                    );
                }
            }
            if (remainingCommodities.length > 0) {
                setGeneralWarningBody(
                    `This order cannot be closed until the following items are sorted: ${remainingCommodities.join(
                        ', '
                    )}. Please sort those items or contact your manager or customer support.`
                );
                setShowGeneralWarningDialog(true);
            } else {
                handleSubmit();
            }
        } else {
            handleSubmit();
        }
    }

    async function createCloseOrderMessage(bulk, closeOrderAmount) {
        const res = await http.getJSON(`/bulks/${_.get(bulk, '_id', '')}/getCommoditiesProcessedBreakdown`);
        const commoditiesProcessedBreakdown = _.get(res, 'data.commoditiesProcessedBreakdown', []);

        let newCloseOrderMessage = (
            <Typography>
                You are about to close this order. Everything has been processed accordingly. The customer will be
                notified with the following information: {closeOrderAmount} for {commoditiesProcessed} bag
                {commoditiesProcessed == 1 ? '' : 's'}.
            </Typography>
        );
        setCloseOrderMessage(newCloseOrderMessage);
        const currentSkuCom = _.find(commodities, {
            skuType: _.get(bulk, 'skuType', '')
        });
        let subCommodityLookup = _.get(currentSkuCom, 'subCommodities', []);
        let numOfSubCom = getComEquivalent(bulk, commodities, true);
        let totalNumOfBags = _.get(bulk, 'commodityAmount', 0) - numOfSubCom;
        let extraOrMissingCommodities = [];
        let extraOrMissingCommoditiesMap = {};
        let countedNumOfBags = _.get(_.find(commoditiesProcessedBreakdown, { isSubCommodity: false }), 'amount', 0);
        let extraNumOfBags = countedNumOfBags - totalNumOfBags;
        if (extraNumOfBags !== 0) {
            extraOrMissingCommodities.push({
                amount: extraNumOfBags,
                name: Math.abs(extraNumOfBags) > 0 ? 'bags' : 'bag'
            });
        }
        commoditiesProcessedBreakdown.forEach(com => {
            if (com.isSubCommodity) {
                extraOrMissingCommoditiesMap[com.subCommodity] = com;
            }
        });
        _.get(bulk, 'subPayloads', []).forEach(com => {
            if (extraOrMissingCommoditiesMap[com.subCommodity] !== undefined) {
                let diff = _.get(extraOrMissingCommoditiesMap[com.subCommodity], 'amount', 0) - _.get(com, 'amount', 0);
                _.set(extraOrMissingCommoditiesMap[com.subCommodity], 'amount', diff);
            } else {
                extraOrMissingCommoditiesMap[com.subCommodity] = { ...com, amount: _.get(com, 'amount', 0) * -1 };
            }
        });
        Object.values(extraOrMissingCommoditiesMap).forEach(com => {
            let subCommodity = _.find(subCommodityLookup, { _id: com.subCommodity });
            let name = _.get(subCommodity, `name.${lang}`, '');
            extraOrMissingCommodities.push({
                amount: com.amount,
                name: shortenSubComName(name, com.amount)
            });
        });
        let extraOrMissingText = '';
        let extraCommodities = extraOrMissingCommodities.filter(c => c.amount > 0);
        let missingCommodities = extraOrMissingCommodities.filter(c => c.amount < 0);
        if (!_.isEmpty(extraCommodities)) {
            let extraStringArray = [];
            extraCommodities.forEach(com => {
                extraStringArray.push(`${com.amount} ${com.name}`);
            });
            extraOrMissingText = ` This order has ${extraStringArray.join(', ')} more than expected,`;
        }
        if (!_.isEmpty(missingCommodities)) {
            let missingStringArray = [];
            missingCommodities.forEach(com => {
                missingStringArray.push(`${com.amount * -1} ${com.name}`);
            });
            if (!_.isEmpty(extraOrMissingText)) {
                extraOrMissingText += ` is missing ${missingStringArray.join(', ')},`;
            } else {
                extraOrMissingText = ` This order is missing ${missingStringArray.join(', ')},`;
            }
        }
        if (!_.isEmpty(extraOrMissingText)) {
            extraOrMissingText += ` please verify this is correct. `;
            newCloseOrderMessage = (
                <Typography>
                    You are about to close this order.
                    <strong>{extraOrMissingText}</strong>
                    The customer will be notified with the following information: {closeOrderAmount} for{' '}
                    {commoditiesProcessed} bag
                    {commoditiesProcessed == 1 ? '' : 's'}.
                </Typography>
            );
            setCloseOrderMessage(newCloseOrderMessage);
        }
    }

    return (
        <>
            <div
                style={{
                    padding: theme.spacing.unit * 2,
                    height: `calc(100% - ${getAppBarHeight(theme.breakpoints) + heightOffset}px)`,
                    width: '100%'
                }}
                className="fade-in"
            >
                <GeneralWarningDialog
                    open={showGeneralWarningDialog}
                    onClose={() => {
                        setShowGeneralWarningDialog(false);
                    }}
                    title={'Unable to close order'}
                    body={generalWarningBody}
                />
                <IdleDialog
                    open={showIdleDialog}
                    onClose={() => {
                        setShowIdleDialog(false);
                        resetTimeoutCount();
                    }}
                    text={'You have been inactive for an extended period of time, are you done with counting? '}
                    decisionCountdown={decisionCountdown}
                    onDone={() => {
                        clearInterval(idleServiceIDState);
                        clearInterval(decisionServiceIDState);
                        setShowIdleDialog(false);
                        finishRecording();
                        resetTimeoutCount();
                    }}
                    onNotDone={() => {
                        clearInterval(decisionServiceIDState);
                        startIdleService();
                        setShowIdleDialog(false);
                        setIdleCountdown(0);
                        setDecisionCountdown(0);
                        resetTimeoutCount();
                    }}
                />
                <LostAndFoundImageDialog
                    theme={theme}
                    open={showLAFImageDialog}
                    onClose={() => {
                        handleCloseLAFImageDialog();
                    }}
                    images={lafPreviews}
                    handleAddImage={handleAddLafImage}
                    handleDeleteImage={handleDeleteLafImage}
                    handleLAFImageSubmit={handleLAFImageSubmit}
                />
                <Toolbar style={{ padding: 0, maxWidth: '100%' }}>
                    <Paper
                        className="fade-in"
                        style={{
                            padding: theme.spacing.unit,
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}
                        >
                            <div>
                                <Button
                                    data-cy="por-screen-back-btn"
                                    color="default"
                                    variant="contained"
                                    style={{ marginRight: theme.spacing.unit }}
                                    onClick={() => {
                                        handleBack();
                                        resetTimeoutCount();
                                    }}
                                >
                                    <MDIcon path={mdiArrowLeft} size={1} style={{ marginRight: theme.spacing.unit }} />{' '}
                                </Button>
                            </div>
                            {isDesktopOrTabletView && (
                                <div style={{ overflowX: 'auto', display: 'flex' }}>
                                    {bulk.owner ? (
                                        <div style={{ overflowX: 'auto', display: 'flex' }}>
                                            <div style={labelStyle}>
                                                <Typography variant="caption" noWrap>
                                                    BAGTAG
                                                </Typography>
                                                <Typography variant="subtitle2" noWrap>
                                                    {_bulk.getTruncatedLabel(bulk)}
                                                </Typography>
                                            </div>
                                            <div style={labelStyle}>
                                                <Typography variant="caption" noWrap>
                                                    CUST ID
                                                </Typography>
                                                <Typography variant="subtitle2" noWrap>
                                                    {_user.getUniqueID(bulk.owner)}
                                                </Typography>
                                            </div>
                                            {!hideCustomerIdentification && (
                                                <div style={labelStyle}>
                                                    <Typography variant="caption" noWrap>
                                                        NAME
                                                    </Typography>
                                                    <Typography variant="subtitle2" noWrap>
                                                        {_user.getNameFirstNameAndLastInitial(bulk.owner)}
                                                    </Typography>
                                                </div>
                                            )}
                                            <div style={labelStyle}>
                                                <Typography variant="caption" noWrap>
                                                    STATUS
                                                </Typography>
                                                <Typography variant="subtitle2" noWrap>
                                                    {_.capitalize(bulkStatus)}
                                                </Typography>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ overflowX: 'auto', display: 'flex' }}>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                onClick={() => {
                                                    handleOpenAttachCustomer();
                                                    resetTimeoutCount();
                                                }}
                                                data-cy="bulk-attach-customer"
                                            >
                                                Attach customer
                                            </Button>
                                            <div style={{ ...labelStyle, marginLeft: '5px' }}>
                                                <Typography variant="caption" noWrap>
                                                    STATUS
                                                </Typography>
                                                <Typography variant="subtitle2" noWrap>
                                                    {_.capitalize(bulkStatus)}
                                                </Typography>
                                            </div>
                                        </div>
                                    )}

                                    {!_.isNil(bulk) && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                marginLeft: 5,
                                                overflow: 'hidden'
                                            }}
                                        >
                                            <RecordingStatusIcon
                                                theme={theme}
                                                cameraIsOn={cameraIsOn}
                                                recordFinished={recordFinished}
                                                bulk={bulk}
                                                isEmployee={
                                                    operator.accountType === 'Collector Employee' &&
                                                    !operator.adminView &&
                                                    operator.accountPermissions.includes('Clerk')
                                                }
                                                haveVideoPermission={haveVideoPermission}
                                                finishRecording={finishRecording}
                                                restartRecording={restartRecording}
                                                bootCamera={bootCamera}
                                            >
                                                <WebcamStreamCapture
                                                    webcamIsStarting={webcamIsStarting}
                                                    recordFinished={recordFinished}
                                                    http={http}
                                                    bagtag={_bulk.getTruncatedLabel(bulk)}
                                                    customerUID={_user.getUniqueID(bulk.owner)}
                                                    customerName={_user.getNameFirstNameAndLastInitial(bulk.owner)}
                                                    setCameraIsOn={setCameraIsOn}
                                                    cameraIsOn={cameraIsOn}
                                                    operator={operator}
                                                    countsChanged={countsChanged}
                                                    countDifference={countDifference}
                                                    videoFilename={videoFilename}
                                                    mountWebcam={
                                                        operator.accountType === 'Collector Employee' &&
                                                        !operator.adminView &&
                                                        operator.accountPermissions.includes('Clerk') &&
                                                        bulk.bulkType !== 'adjustment' &&
                                                        _.isNil(bulk.dateCompleted) &&
                                                        haveVideoPermission
                                                    }
                                                    bulk={bulk}
                                                    webcamRef={webcamRef}
                                                    commoditiesProcessedBreakdownDelta={
                                                        commoditiesProcessedBreakdownDelta
                                                    }
                                                    videoDevice={currentVideoDevice}
                                                    setWebcamSettingsDialogOpen={setWebcamSettingsDialogOpen}
                                                />
                                            </RecordingStatusIcon>
                                        </div>
                                    )}
                                </div>
                            )}

                            <div style={{ display: 'inline-flex' }}>
                                {!disableCreateBulk && (
                                    <div style={{ marginTop: 3, marginRight: 5 }}>
                                        <Tooltip title="Create new order" disableFocusListener disableTouchListener>
                                            <IconButton
                                                data-cy="por-screen-create-order"
                                                onClick={() =>
                                                    warnAction(() => {
                                                        handleClosePopover();
                                                        handleCreateBulk();
                                                    }, 'You are about to create an adjustment bulk for this customer. Do you wish to continue?')
                                                }
                                                style={{ padding: 2 }}
                                            >
                                                <Icon style={{ fontSize: '28px', alignSelf: 'center' }}>add_box</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}
                                {!_user.isClerk(operator) && (
                                    <div style={{ marginTop: 3, marginRight: 8 }}>
                                        <Tooltip title="Delete this order" disableFocusListener disableTouchListener>
                                            <IconButton
                                                onClick={() =>
                                                    warnAction(
                                                        () => {
                                                            setIsDeletingOrder(true);
                                                            handleClosePopover();
                                                            handleDeleteBulk();
                                                        },
                                                        'You are about to permanently delete this order! Do you wish to continue?',
                                                        _bulk.getTruncatedLabel(bulk)
                                                    )
                                                }
                                                style={{ padding: 2 }}
                                            >
                                                <Icon style={{ fontSize: '28px', alignSelf: 'center' }}>delete</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}

                                <Button
                                    data-cy="por-screen-drop-down-btn"
                                    color="default"
                                    variant="contained"
                                    onClick={e => {
                                        handleOpenPopover(e);
                                        resetTimeoutCount();
                                    }}
                                    style={{ marginRight: theme.spacing.unit }}
                                    // disabled={inProgress || _bulk.isRedeemed(bulk) || _bulk.isInvoiced(bulk)}
                                >
                                    <MDIcon
                                        path={mdiArrowDownDropCircle}
                                        size={1}
                                        color={theme.palette.action.active}
                                    />
                                </Button>
                                <Button
                                    data-cy="por-screen-toggle-complete-btn"
                                    color={_bulk.isCompleted(bulk) ? 'secondary' : 'primary'}
                                    variant="contained"
                                    onClick={() => {
                                        // const commodityAmount = _.get(bulk, 'commodityAmount', 0);
                                        // const commoditiesProcessed = _.get(bulk, 'commoditiesProcessed', 0);
                                        // if (commoditiesProcessed < commodityAmount && !_bulk.isCompleted(bulk)) {
                                        //     setUncountedBagsDialogOpen(true);
                                        // } else {
                                        checkAndSubmit();
                                        // }
                                    }}
                                    disabled={
                                        isSystemCollectorAdmin
                                            ? disableCompleteButton
                                            : !haveCloseOrderPermission || disableCompleteButton
                                    }
                                >
                                    {formatAsCurrency(grandTotal + totalCountFormAmount)}
                                    <MDIcon
                                        path={_bulk.isCompleted(bulk) ? mdiPencil : mdiCheck}
                                        size={1}
                                        color="#fff"
                                        style={{ marginLeft: theme.spacing.unit }}
                                    />
                                </Button>
                            </div>
                        </div>
                        {!isDesktopOrTabletView && (
                            <div style={{ overflowX: 'auto', marginTop: theme.spacing.unit }}>
                                {bulk.owner ? (
                                    <Typography
                                        variant="caption"
                                        style={{
                                            whiteSpace: 'nowrap',
                                            width: '100%',
                                            overflowX: 'hidden'
                                        }}
                                    >
                                        <span style={{ fontWeight: 500 }}>TAG</span>:{' '}
                                        <CustomChip theme={theme} bgColor={colors.blue[100]}>
                                            {_bulk.getTruncatedLabel(bulk)}
                                        </CustomChip>{' '}
                                        <span style={{ fontWeight: 500 }}>CUST ID</span>:{' '}
                                        <CustomChip theme={theme} bgColor={colors.blueGrey[100]}>
                                            {_user.getUniqueID(bulk.owner)}
                                        </CustomChip>{' '}
                                        <span style={{ fontWeight: 500 }}>STATUS</span>:{' '}
                                        <CustomChip theme={theme} bgColor={BULK_STATUS_COLORS[bulkStatus]}>
                                            {_.capitalize(bulkStatus)}
                                        </CustomChip>
                                    </Typography>
                                ) : (
                                    <div style={{ overflowX: 'auto', display: 'flex' }}>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            fullWidth
                                            onClick={() => {
                                                handleOpenAttachCustomer();
                                                resetTimeoutCount();
                                            }}
                                            data-cy="bulk-attach-customer"
                                        >
                                            Attach customer
                                        </Button>
                                        <Typography
                                            variant="caption"
                                            style={{
                                                whiteSpace: 'nowrap',
                                                marginTop: theme.spacing.unit,
                                                marginLeft: theme.spacing.unit
                                            }}
                                        >
                                            <span style={{ fontWeight: 500 }}>STATUS</span>:{' '}
                                            <CustomChip theme={theme} bgColor={BULK_STATUS_COLORS[bulkStatus]}>
                                                {_.capitalize(bulkStatus)}
                                            </CustomChip>
                                        </Typography>
                                    </div>
                                )}
                            </div>
                        )}
                    </Paper>
                </Toolbar>

                <Grid
                    container
                    spacing={theme.spacing.unit * 2}
                    style={{
                        display: 'flex',
                        height: '100%'
                    }}
                >
                    {/* THE FIRST LARGE PANEL */}
                    {(isDesktopOrTabletView || currentTab === 0) && (
                        <BulkInfoAndHistoryPanel
                            commodityColors={allCommodityColors}
                            adminView={adminView}
                            collectors={enabledCollectors}
                            currentCollector={collector}
                            history={history}
                            operator={operator}
                            bulk={bulk}
                            customerHistory={customerHistory}
                            allBulksFromSameCustomer={allBulksFromSameCustomer}
                            countTotal={grandTotal + totalFees} // used for value per commodity item
                            totalCountFormAmount={totalCountFormAmount}
                            inProgress={inProgress}
                            disableEditing={disableEditing}
                            onImageDialog={handleImageDialog}
                            onViewBulk={handleViewBulk}
                            onCreateBulk={handleCreateBulk}
                            onChangeCollector={handleChangeCollector}
                            http={http}
                            resetTimeoutCount={resetTimeoutCount}
                            handleRemoveCharity={handleRemoveCharity}
                            handleOpenLAFImageDialog={handleOpenLAFImageDialog}
                            reportOptions={reportOptions}
                            rolePermissions={rolePermissions}
                            setImages={setImages}
                            hideCustomerIdentification={hideCustomerIdentification}
                            commodities={commodities}
                            disclaimer={disclaimer}
                            handleSetCharity={handleSetCharity}
                            onSnackbar={onSnackbar}
                            refreshBulk={fetchBulkData}
                            adjustmentConfig={adjustmentConfig}
                            handleUpdateAdjustmentReason={handleUpdateAdjustmentReason}
                            addEmptySession={addEmptySession}
                            handleUpdateReferenceNote={handleUpdateReferenceNote}
                        />
                    )}

                    {/* THE SECOND LARGE PANEL*/}
                    {(isDesktopOrTabletView || currentTab === 1) && (
                        <CountInputPanel
                            bulk={bulk}
                            skus={bulkSkus}
                            inProgress={inProgress}
                            countingMode={updatedCountingMode}
                            operator={operator}
                            config={bulkStationConfig}
                            countForm={countForm}
                            totalCountFormAmount={totalCountFormAmount}
                            disableEditing={disableEditing}
                            customFeesForm={customFeesForm}
                            grandTotal={grandTotal}
                            createBulkDisabled={disableCreateBulk}
                            handleApplyCustomFee={handleApplyCustomFee}
                            onCustomFeeDialog={handleCustomFeeDialog}
                            onAddCount={handleAddCount}
                            onCountFormChange={handleCountFormChange}
                            onCreateBulk={handleCreateBulk}
                            onSubmitCounts={handleSubmitCounts}
                            setIdleCountdown={setIdleCountdown}
                            resetTimeoutCount={resetTimeoutCount}
                            onOpenReportDialog={handleOpenReportDialog}
                            onOpenComplaintDialog={handleOpenComplaintDialog}
                            reportType={reportType}
                            hidePreset={hidePreset}
                            hideKeypad={hideKeypad}
                            keySize={keySize}
                            onOpenAICountDialog={() => {
                                if (_.isEmpty(currentCountingSessionId) || _.isNil(currentCountingSessionId)) {
                                    onSnackbar('Please select or create a counting session first', 'error');
                                    return;
                                }
                                setShowStreamPopup(true);
                            }}
                            currentCountingSessionId={currentCountingSessionId}
                            onSnackbar={onSnackbar}
                        />
                    )}

                    {/* THE THIRD LARGE PANEL */}
                    {(isDesktopOrTabletView || currentTab === 2) && (
                        <CountsPanel
                            bulk={bulk}
                            history={history}
                            skus={bulkSkus}
                            adminView={adminView}
                            grandTotal={grandTotal}
                            totalCountFormAmount={totalCountFormAmount}
                            promoCodes={promoCodes}
                            countTotal={grandTotal + totalFees} // used for value per commodity item
                            disableEditing={disableEditing}
                            disableAddingFees={disableAddingFees}
                            onApplyPromo={handleApplyPromo}
                            inProgress={inProgress}
                            customFeesForm={customFeesForm}
                            onRemoveCustomFee={handleRemoveCustomFee}
                            //onUpdateBulkCustomFees={handleUpdateBulkCustomFees}
                            onRemoveCounts={handleRemoveCounts}
                            onRemovePromo={handleRemovePromo}
                            onOpenReportDialog={handleOpenReportDialog}
                            onOpenComplaintDialog={handleOpenComplaintDialog}
                            onChangeCollector={handleChangeCollector}
                            onClearCountsAndFees={handleClearCountsAndFees}
                            onDonation={handleSetCharity}
                            onUpdateCommodityAmount={handleUpdateCommodityProcessed}
                            setIdleCountdown={setIdleCountdown}
                            cameraIsOn={cameraIsOn}
                            http={http}
                            operator={operator}
                            bagtag={_bulk.getTruncatedLabel(bulk)}
                            recordFinished={recordFinished}
                            customerUID={_user.getUniqueID(bulk.owner)}
                            videoFilename={videoFilename}
                            isEmployee={
                                operator.accountType === 'Collector Employee' &&
                                !operator.adminView &&
                                operator.accountPermissions.includes('Clerk')
                            }
                            haveVideoPermission={haveVideoPermission}
                            resetTimeoutCount={resetTimeoutCount}
                            // countsScreenToPrintRef={countsScreenToPrintRef}
                            printingReceipt={printingReceipt}
                            setEditNumberOfBagsDialogOpen={setEditNumberOfBagsDialogOpen}
                            hideCustomerIdentification={hideCustomerIdentification}
                            commodities={commodities}
                            commodityColors={allCommodityColors}
                            collector={collector}
                            setCurrentCountingSessionId={setCurrentCountingSessionId}
                            currentCountingSessionId={currentCountingSessionId}
                            setCreateEditNumberOfCommoditiesDialogOpen={setCreateEditNumberOfCommoditiesDialogOpen}
                            handleRemoveCountingSession={handleRemoveCountingSession}
                            finishRecording={finishRecording}
                            restartRecording={restartRecording}
                            handleAddCount={handleAddCount}
                            handleRemoveSessionlessCounts={handleRemoveSessionlessCounts}
                            handleRemoveCustomFeeFromSessionAndBulk={handleRemoveCustomFeeFromSessionAndBulk}
                            onImageDialog={handleImageDialog}
                            setImages={setImages}
                            setCreatEditNumberOfCommoditiesMode={setCreatEditNumberOfCommoditiesMode}
                            handleShowErrorDialog={handleShowErrorDialog}
                        />
                    )}
                </Grid>
            </div>
            <Hidden mdUp>
                <AppBar
                    position="fixed"
                    color="default"
                    elevation={0}
                    style={{
                        top: 'auto',
                        bottom: 0,
                        borderTop: `2px solid ${allCommodityColors[bulk.skuType]}`,
                        backgroundColor: theme.palette.background.paper,
                        paddingBottom: 'env(safe-area-inset-bottom)'
                    }}
                >
                    <Tabs
                        value={currentTab}
                        onChange={(e, v) => {
                            console.log('v', v);
                            setCurrentTab(v);
                        }}
                        indicatorColor="primary"
                        variant="fullWidth"
                        textColor="primary"
                        centered
                    >
                        <Tab
                            label={
                                <div style={tabStyle}>
                                    <MDIcon
                                        path={mdiInformation}
                                        size={0.7}
                                        color={
                                            currentTab === 0 ? theme.palette.primary.main : theme.palette.text.secondary
                                        }
                                    />
                                    <Typography variant="caption" color="inherit">
                                        Info
                                    </Typography>
                                </div>
                            }
                            data-cy="por-screen-info"
                        />
                        <Tab
                            label={
                                <div style={tabStyle}>
                                    <MDIcon
                                        path={mdiChartBox}
                                        size={0.7}
                                        color={
                                            currentTab === 1 ? theme.palette.primary.main : theme.palette.text.secondary
                                        }
                                    />
                                    <Typography variant="caption" color="inherit">
                                        Enter Counts
                                    </Typography>
                                </div>
                            }
                            data-cy="por-screen-count"
                        />
                        <Tab
                            label={
                                <div style={tabStyle}>
                                    <MDIcon
                                        path={mdiFormatListBulleted}
                                        size={0.7}
                                        color={
                                            currentTab === 2 ? theme.palette.primary.main : theme.palette.text.secondary
                                        }
                                    />
                                    <Typography variant="caption" color="inherit">
                                        Reciept
                                    </Typography>
                                </div>
                            }
                            data-cy="por-screen-counts"
                        />
                    </Tabs>
                </AppBar>
            </Hidden>
            {/* Dialogs */}
            <BulkFeesSelectionDialog
                open={customFeeDialogOpen}
                customFeesForm={customFeesForm}
                disabled={inProgress}
                operator={operator}
                onClose={() => handleCustomFeeDialog(false)}
                onApply={handleApplyCustomFee}
                collector={collector}
                defaultFeeCount={customFeeDialogCount}
            />
            <CustomMUIDialog open={imageDialogOpen} onClose={() => handleImageDialog(false)}>
                <ZoomViewer src={_.first(images)} />
            </CustomMUIDialog>
            <BulkReportDialog
                http={http}
                open={reportDialogOpen}
                inProgress={inProgress}
                reportType={reportType}
                reportOptions={reportOptions}
                reportReasons={reportReasons}
                reportImages={reportImages}
                reportPreviews={reportPreviews}
                reportIssueDescription={reportIssueDescription}
                nonConformantIssue={nonConformantIssue}
                nonConformantWarning={nonConformantWarning}
                onClose={handleCloseReportDialog}
                onSubmit={handleSubmitReport}
                onUndo={handleUndoBulkReport}
                onReportIssueDescription={handleReportIssueDescription}
                onReasonsChange={handleReportReasonsChange}
                handleAddImage={handleAddImage}
                handleDeleteImage={handleDeleteImage}
                isCustomerReported={_bulk.isReported(bulk)}
                isDriverReported={_bulk.isDriverReported(bulk)}
            />
            <BulkComplaintDialog
                operator={operator}
                open={complaintDialogOpen}
                bulk={bulk}
                inProgress={inProgress}
                customerIssueResolution={customerIssueResolution}
                onToggleResolveCustomerIssue={handleToggleResolveCustomerIssue}
                onChangeCustomerIssueResolution={handleChangeCustomerIssueResolution}
                onClose={handleCloseComplaintDialog}
                http={http}
            />
            <BulkErrorDialog
                http={http}
                bulk={bulk}
                operator={operator}
                open={errorDialogOpen}
                inProgress={inProgress}
                refreshBulk={fetchBulkData}
                onClose={() => handleShowErrorDialog(false)}
            />
            <AttachCustomerDialog
                customerInfo={customerInfo}
                open={attachCustomerDialogOpen}
                onSubmit={handleAttachCustomer}
                onClose={handleCloseAttachCustomer}
                onChangeCustomerInfo={handleChangeCustomerInfo}
            />
            <EmailReceiptDialog
                bulk={bulk}
                email={customerEmail}
                error={customerEmailError}
                open={emailReceiptDialogOpen}
                customer={bulk.owner}
                onSubmitCounts={handleToggleComplete}
                onSubmit={handleSubmitEmailReceipt}
                onClose={handleCloseEmailReceiptDialog}
                onChangeCustomerInfo={handleCustomerEmailChange}
                total={formatAsCurrency(grandTotal + totalCountFormAmount)}
                countsScreenToPrintRef={countsScreenToPrintRef}
                printingReceipt={printingReceipt}
                handlePrintReceipt={handlePrintReceipt}
                handleDetachCustomer={handleDetachCustomer}
                handleAttachCustomer={handleAttachCustomer}
                handleOpenAttachCustomer={handleOpenAttachCustomer}
                onSnackbar={onSnackbar}
                setShowEmailDialog={setShowEmailDialog}
                setEmailDialogMode={setEmailDialogMode}
            />

            <NumberOfBagsDialog
                open={numberOfBagsDialogOpen}
                bulk={bulk}
                commodities={commodities}
                collector={collector}
                disclaimer={disclaimer}
                adjustmentConfig={adjustmentConfig}
                onUpdateCommodityAmount={handleUpdateCommodityAmount}
                onUpdateCommodityProcessed={handleUpdateCommodityProcessed}
                onClose={handleCloseNumberOfBagsDialog}
                onBack={handleBack}
                onImageDialog={handleImageDialog}
                bagsToProcess={bagsToProcess}
                setBagsToProcess={setBagsToProcess}
                setImages={setImages}
                handleUpdateCommoditesProccessedBreakdown={handleUpdateCommoditesProccessedBreakdown}
                handleUpdateAdjustmentReason={handleUpdateAdjustmentReason}
                addEmptySession={addEmptySession}
                http={http}
            />
            {/* <EditNumberOfBagsDialog
                theme={theme}
                open={editNumberOfBagsDialogOpen}
                bulk={bulk}
                onClose={handleCloseEditNumberOfBagsDialog}
                onUpdateCommodityAmount={handleUpdateCommodityAmount}
                setIdleCountdown={setIdleCountdown}
                resetTimeoutCount={resetTimeoutCount}
            /> */}
            <CreateEditNumberOfCommoditiesDialog
                theme={theme}
                open={createEditNumberOfCommoditiesDialogOpen}
                bulk={bulk}
                onClose={() => {
                    setCreateEditNumberOfCommoditiesDialogOpen(false);
                }}
                // onUpdateCommodityAmount={handleUpdateCommodityAmount}
                // setIdleCountdown={setIdleCountdown}
                // resetTimeoutCount={resetTimeoutCount}
                // countingSessions={countingSessions}
                currentCountingSessionId={currentCountingSessionId}
                commodities={commodities}
                handleUpdateCommoditesProccessedBreakdown={handleUpdateCommoditesProccessedBreakdown}
                // setCountingSessions={setCountingSessions}
                createNewCountingSession={createNewCountingSession}
                mode={creatEditNumberOfCommoditiesMode}
                http={http}
            />
            <UncountedBagsDialog
                open={uncountedBagsDialogOpen}
                message={`There are ${_.get(bulk, 'commodityAmount', 0) -
                    _.get(bulk, 'commoditiesProcessed', 0)} remaining bags to be counted. Have they been counted?`}
                onYes={async () => {
                    let newCountIds = [];
                    let initalCountIds = [];
                    _.get(initialBulk, 'counts', []).forEach(count => {
                        if (!_.isNil(_.get(count, '_id', null))) {
                            initalCountIds.push(count._id);
                        }
                    });
                    _.get(bulk, 'counts', []).forEach(count => {
                        if (!_.isNil(_.get(count, '_id', null))) {
                            if (!initalCountIds.includes(count._id)) {
                                newCountIds.push(count._id);
                            }
                        }
                    });
                    if (!_.isEmpty(newCountIds)) {
                        let newBags = '';
                        const initialCommoditiesProcessed = _.get(initialBulk, 'commoditiesProcessed', 0);
                        const totalCommodities = _.get(bulk, 'commodityAmount', 0);
                        let commoditiesProcessed = totalCommodities - initialCommoditiesProcessed;
                        if (commoditiesProcessed === 1) {
                            newBags = `${totalCommodities}`;
                        } else if (commoditiesProcessed > 1) {
                            newBags = `${
                                initialCommoditiesProcessed === 0 ? 1 : initialCommoditiesProcessed + 1
                            }-${totalCommodities}`;
                        }
                        await updateCountBags(newCountIds, newBags);
                    }

                    await handleUpdateCommodityAmount(
                        _.get(bulk, 'commodityAmount', 0),
                        _.get(bulk, 'commodityAmount', 0)
                    );
                    handleSubmit();
                    setUncountedBagsDialogOpen(false);
                }}
                onClose={() => {
                    setUncountedBagsDialogOpen(false);
                }}
                onContinue={() => {
                    handleSubmit();
                    setUncountedBagsDialogOpen(false);
                }}
            />

            <EnterEmailDialog
                theme={theme}
                open={showEmailDialog}
                onClose={() => {
                    setShowEmailDialog(false);
                }}
                handleSubmitEmailReceipt={handleSubmitEmailReceipt}
                emailDialogMode={emailDialogMode}
                setShowResultDialog={setShowResultDialog}
            />
            <DiscardSessionDialog
                theme={theme}
                open={showDiscardSessionDialog}
                onBack={() => {
                    setShowDiscardSessionDialog(false);
                }}
                onOk={() => {
                    handleRemoveCountingSession(currentCountingSessionId);
                    setShowDiscardSessionDialog(false);
                    setTriggerExit(obj => ({
                        ...obj,
                        onOk: true
                    }));
                }}
            />

            <Popover
                open={!_.isNil(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                onClose={handleClosePopover}
            >
                {_user.isSystemAdmin(operator) ||
                    (_user.isInternalRole(operator) && (
                        <>
                            <Typography variant="caption" style={{ padding: theme.spacing.unit }}>
                                Actions
                            </Typography>
                            <Divider />
                            <List dense>
                                <ListItem
                                    disabled={_.isNil(_.get(bulk, 'owner'))}
                                    button
                                    onClick={() => {
                                        if (operator.accountType === 'System Administrator') {
                                            handleClosePopover();
                                            if (deviceHelper.AndroidCordova() || deviceHelper.iOSCordova()) {
                                                history.push(`/customers/${bulk.owner._id}`);
                                            } else {
                                                window.open(
                                                    `${process.env.REACT_APP_ORIGIN_URL}/customers/${bulk.owner._id}`,
                                                    '_blank'
                                                );
                                            }
                                        }
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                                            <MDIcon path={mdiAccount} size={1} color={theme.palette.action.active} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="View Customer"
                                        secondary={
                                            !_.isNil(_.get(bulk, 'owner')) ? (
                                                'Customer view to edit/view their account'
                                            ) : (
                                                <span style={{ color: colors.red[500] }}>
                                                    No customer attached to order
                                                </span>
                                            )
                                        }
                                    />
                                </ListItem>
                                {(!isProductionEnv || _user.isAllowedToDebug(operator)) && (
                                    <ListItem button onClick={() => onDebug(bulk)}>
                                        <ListItemAvatar>
                                            <Avatar style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
                                                <MDIcon
                                                    path={mdiCodeJson}
                                                    size={1}
                                                    color={theme.palette.action.active}
                                                />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="View JSON" />
                                    </ListItem>
                                )}
                            </List>
                        </>
                    ))}
                <Typography variant="caption" style={{ padding: theme.spacing.unit }}>
                    Misc Settings
                </Typography>
                <Divider />
                <div style={{ flexDirection: 'column', display: 'flex', marginBottom: theme.spacing.unit * 2 }}>
                    <FormControlLabel
                        style={{ paddingLeft: theme.spacing.unit }}
                        control={
                            <Checkbox
                                checked={speedMode}
                                onClick={handleToggleSpeedMode}
                                style={{
                                    paddingTop: theme.spacing.unit,
                                    paddingBottom: theme.spacing.unit,
                                    marginLeft: theme.spacing.unit
                                }}
                            />
                        }
                        label="Enable speed mode"
                    />
                    <FormControlLabel
                        style={{ paddingLeft: theme.spacing.unit }}
                        control={
                            <Checkbox
                                checked={hidePreset}
                                onClick={handleToggleHidePreset}
                                style={{
                                    paddingTop: theme.spacing.unit,
                                    paddingBottom: theme.spacing.unit,
                                    marginLeft: theme.spacing.unit
                                }}
                            />
                        }
                        label="Hide preset keys"
                    />
                    <FormControlLabel
                        style={{ paddingLeft: theme.spacing.unit }}
                        control={
                            <Checkbox
                                checked={hideKeypad}
                                onClick={handleToggleHideKeypad}
                                style={{
                                    paddingTop: theme.spacing.unit,
                                    paddingBottom: theme.spacing.unit,
                                    marginLeft: theme.spacing.unit
                                }}
                            />
                        }
                        label="Hide keypad"
                    />
                    <FormControlLabel
                        style={{ paddingLeft: theme.spacing.unit }}
                        control={
                            <Checkbox
                                checked={hideKeyboard}
                                onClick={handleToggleHideKeyboard}
                                style={{
                                    paddingTop: theme.spacing.unit,
                                    paddingBottom: theme.spacing.unit * 2,
                                    marginLeft: theme.spacing.unit
                                }}
                            />
                        }
                        label="Hide on screen keyboard"
                    />

                    <FormControl
                        style={{
                            marginRight: theme.spacing.unit * 2,
                            marginLeft: theme.spacing.unit * 2,
                            height: 48,
                            paddingTop: theme.spacing.unit / 2,
                            paddingBottom: theme.spacing.unit / 2
                        }}
                    >
                        <InputLabel variant="outlined" htmlFor="keySize">
                            {'Key Size'}
                        </InputLabel>
                        <Select
                            data-cy="bulk-type-selection-dropdown"
                            value={keySize}
                            onChange={e => {
                                const newValue = e.target.value;
                                setKeySize(newValue);
                                localStorage.setItem('keySize', newValue);
                            }}
                            inputProps={{
                                name: 'keySize',
                                id: 'keySize'
                            }}
                            input={<OutlinedInput labelWidth={60} name="keySize" id="keySize" />}
                        >
                            {['Large', 'Normal', 'Small'].map(c => {
                                return (
                                    <MenuItem value={c} key={c}>
                                        {c}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                <Typography variant="caption" style={{ padding: theme.spacing.unit }}>
                    Counting Settings
                </Typography>
                <Divider />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: theme.spacing.unit,
                        marginBottom: theme.spacing.unit
                    }}
                >
                    <ToggleButtonGroup
                        value={countingMode}
                        exclusive
                        onChange={(e, mode) => {
                            if (mode) {
                                setCountingMode(mode);
                                localStorage.setItem('PORScreenCountingMode', mode);
                            }
                        }}
                        data-cy={`bulk-counter-dialog-collector-select`}
                    >
                        {COUNTING_MODES.map(mode => {
                            return (
                                <ToggleButton
                                    key={mode}
                                    value={mode}
                                    data-cy={`bulk-counter-dialog-mode-select-${mode}`}
                                >
                                    {`${_.capitalize(mode)} mode`}
                                </ToggleButton>
                            );
                        })}
                    </ToggleButtonGroup>
                </div>
            </Popover>
            <Receipt
                open={printingReceipt}
                collector={collector}
                operator={operator}
                bulk={bulk}
                grandTotal={grandTotal}
                skus={bulkSkus}
                totalCountFormAmount={totalCountFormAmount}
                ref={countsScreenToPrintRef}
            />
            <FrameCapture
                theme={theme}
                http={http}
                open={showStreamPopup}
                webcamRef={webcamRef2}
                handleAddCount={handleAddCount}
                skus={bulkSkus}
                handleCloseStream={() => setShowStreamPopup(false)}
                onSnackbar={onSnackbar}
                handleAddAICounts={handleAddAICounts}
                operator={operator}
                AICountImages={AICountImages}
                showAICountImageDialog={showAICountImageDialog}
                AICountPreviews={AICountPreviews}
                handleAddAICountImage={handleAddAICountImage}
                handleDeleteAICountImage={handleDeleteAICountImage}
                setShowAICountImageDialog={setShowAICountImageDialog}
                videoDevice={currentVideoDevice}
                setWebcamSettingsDialogOpen={setWebcamSettingsDialogOpen}
                aiCountsConfig={aiCountsConfig}
            />
            <WebcamSettingsDialog
                open={webcamSettingsDialogOpen}
                theme={theme}
                onClose={() => setWebcamSettingsDialogOpen(false)}
                videoDevice={currentVideoDevice}
                devices={videoDevices}
                handleVideoDevice={handleVideoDevice}
                webcamRef={webcamRef}
            />
            <Dialog open={showCloseOrderDialog} onClose={() => setShowCloseOrderDialog(false)}>
                <DialogTitle>
                    Close order for {_user.getNameFirstNameAndLastInitial(bulk.owner)} [
                    {_.get(bulk, 'owner.uniqueID', 'ID')}]?
                </DialogTitle>
                <DialogContent>
                    {closeOrderMessage}
                    {!_.isEmpty(bulk.subPayloads) && (
                        <Typography style={{ marginTop: theme.spacing.unit * 2 }}>
                            This order contains subcommodities which have been factored in to the total bag count.
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="default"
                        size="small"
                        onClick={() => setShowCloseOrderDialog(false)}
                        data-cy="por-screen-warning-dialog-cancel"
                    >
                        Nevermind
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        onClick={handleCloseOrder}
                        data-cy="por-screen-warning-dialog-confirm"
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withWidth()(withMobileDialog({ breakpoint: 'xs' })(withTheme()(PORScreen)));

function RecordingStatusIcon(props) {
    const {
        theme,
        cameraIsOn,
        recordFinished,
        bulk,
        children,
        isEmployee,
        haveVideoPermission,
        finishRecording,
        restartRecording,
        bootCamera
    } = props;

    const countIsFinished = !_.isNil(bulk) && !_.isNil(bulk.dateCompleted);

    const cameraDisabled = !isEmployee || bulk.bulkType === 'adjustment' || !haveVideoPermission || countIsFinished;
    if (cameraDisabled) {
        let tooltipMsg;
        if (!isEmployee) tooltipMsg = 'Camera Disabled for Non-Clerks';
        if (bulk.bulkType === 'adjustment') tooltipMsg = 'Camera Disabled for Adjustments';
        if (!haveVideoPermission) tooltipMsg = 'Permissions required';
        if (countIsFinished) tooltipMsg = 'Camera Disabled for Completed Bulks';

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: `0 ${theme.spacing.unit}px`
                }}
            >
                <Typography variant="caption" style={{ color: 'red' }} noWrap>
                    CAMERA
                </Typography>
                <Tooltip title={tooltipMsg}>
                    <Icon style={{ marginTop: 2, fontSize: '16px', alignSelf: 'center', color: 'red' }}>
                        not_interested
                    </Icon>
                </Tooltip>
            </div>
        );
    }

    let onClick = bootCamera;
    if (recordFinished) {
        onClick = restartRecording;
    } else if (cameraIsOn) {
        onClick = finishRecording;
    }

    const showWebcam = cameraIsOn && !recordFinished;

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: `0 ${theme.spacing.unit}px`
                }}
            >
                <Typography variant="caption" style={{ color: showWebcam ? 'green' : 'red' }} noWrap>
                    CAMERA
                </Typography>
                <IconButton onClick={onClick} style={{ padding: 2, margin: -6 }}>
                    {showWebcam && <Icon style={{ fontSize: '24px', alignSelf: 'center', color: 'black' }}>stop</Icon>}
                    {!showWebcam && (
                        <Icon style={{ fontSize: '24px', alignSelf: 'center', color: 'black' }}>
                            fiber_manual_record
                        </Icon>
                    )}
                </IconButton>
            </div>
            <div style={showWebcam ? {} : { height: '0px', visibility: 'hidden' }}>{children}</div>
        </>
    );
}

function getSettings({ operator, bulk, commodityForBulk, bulkPermissions }) {
    const permissionsForBulk = _.get(bulkPermissions, _.get(bulk, 'bulkType'), {});
    const adminView = operator.accountType === 'System Administrator';
    const disableEditingSystemAdmins = _bulk.isRedeemed(bulk) || _bulk.isInvoiced(bulk) || _bulk.isCompleted(bulk);
    const disableEditingOtherAccountTypes =
        _bulk.isRedeemed(bulk) ||
        _bulk.isInvoiced(bulk) ||
        _bulk.isCompleted(bulk) ||
        !_commodity.isManuallyCompleted(commodityForBulk) ||
        !_.get(permissionsForBulk, 'update', false);

    const disableAddingFees = !adminView;
    const disableReopen = !_.get(permissionsForBulk, 'reopen', false);

    const adjustmentBulkType = _.get(bulk, 'bulkType') === 'pickup' ? 'adjustment' : _.get(bulk, 'bulkType');
    const disableCreateBulk = !_.get(bulkPermissions, `${adjustmentBulkType}.create`, false);
    return {
        adminView,
        disableEditing: adminView ? disableEditingSystemAdmins : disableEditingOtherAccountTypes,
        disableAddingFees,
        disableReopen: adminView ? false : disableReopen,
        disableCreateBulk: adminView ? false : disableCreateBulk
    };
}

function getUpdatedCountingMode(width, countingMode, currentTab) {
    if (!['xs', 'sm', 'md'].includes(width)) {
        return countingMode;
    } else {
        if (currentTab === 1) {
            return 'classic';
        } else if (currentTab === 2) {
            return 'keypad';
        } else {
            return countingMode;
        }
    }
}

function getCountDifference(countsBefore, countsAfter) {
    let countsBeforeDict = {};
    let countsAfterDict = {};
    for (let i = 0; i < countsBefore.length; i++) {
        let currentCount = countsBefore[i];
        for (let j = 0; j < currentCount.items.length; j++) {
            let currentItem = currentCount.items[j];
            let sku = currentItem.sku;
            let quantity = currentItem.quantity;
            let value = currentItem.value;
            if (_.isNil(countsBeforeDict[sku])) {
                countsBeforeDict[sku] = { ...currentItem };
                countsBeforeDict[sku].quantity = quantity;
            } else {
                countsBeforeDict[sku].quantity += quantity;
                countsBeforeDict[sku].value += value;
            }
        }
    }
    for (let i = 0; i < countsAfter.length; i++) {
        let currentCount = countsAfter[i];
        for (let j = 0; j < currentCount.items.length; j++) {
            let currentItem = currentCount.items[j];
            let sku = currentItem.sku;
            let quantity = currentItem.quantity;
            let value = currentItem.value;
            if (_.isNil(countsAfterDict[sku])) {
                countsAfterDict[sku] = { ...currentItem };
                countsAfterDict[sku].quantity = quantity;
            } else {
                countsAfterDict[sku].quantity += quantity;
                countsAfterDict[sku].value += value;
            }
        }
    }
    // go through all the counts before, add anything that doesn't exist in counts after to the counts after dictionary with count of 0 then simply subtract before from after to get difference
    for (const sku in countsBeforeDict) {
        if (countsAfterDict[sku] === undefined) {
            countsAfterDict[sku] = { ...countsBeforeDict[sku], quantity: 0, value: 0 };
        }
    }
    let countsDifference = {};
    for (const sku in countsAfterDict) {
        if (_.isNil(countsBeforeDict[sku])) {
            countsDifference[sku] = countsAfterDict[sku];
        } else if (countsAfterDict[sku].quantity - countsBeforeDict[sku].quantity !== 0) {
            countsDifference[sku] = {
                ...countsAfterDict[sku],
                quantity: countsAfterDict[sku].quantity - countsBeforeDict[sku].quantity,
                value: countsAfterDict[sku].value - countsBeforeDict[sku].value
            };
        }
    }
    return countsDifference;
}

function IdleDialog(props) {
    const { open, onClose, text, onDone, onNotDone, decisionCountdown } = props;
    return (
        <Dialog open={open} onClose={onClose} disableBackdropClick>
            <DialogContent>
                <div style={{ flexDirection: 'column' }}>
                    <Typography>{text}</Typography>
                    <Typography style={{ color: 'red', textAlign: 'center' }}>
                        {decisionTime - decisionCountdown}
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" size="small" onClick={onDone}>
                    Exit
                </Button>
                <Button color="primary" size="small" onClick={onNotDone}>
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function WebcamSettingsDialog(props) {
    const { open, theme, devices, handleVideoDevice, onClose, webcamRef, videoDevice } = props;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Camera Select</DialogTitle>
            <DialogContent style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <Webcam
                    style={{ margin: '4px', maxHeight: '200px', maxWidth: '200px' }}
                    audio={false}
                    ref={webcamRef}
                    videoConstraints={{ deviceId: _.get(videoDevice, 'deviceId') }}
                />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {devices.map((device, idx) => (
                        <Button
                            color="primary"
                            variant="contained"
                            style={{ margin: 2 }}
                            onClick={() => handleVideoDevice(device)}
                        >
                            {idx + 1}: {getCameraName(device)}
                        </Button>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

function WebcamStreamCapture(props) {
    const mediaRecorderRef = useRef(null);
    const {
        webcamIsStarting,
        recordFinished,
        http,
        bagtag,
        customerUID,
        customerName,
        setCameraIsOn,
        cameraIsOn,
        operator,
        countsChanged,
        countDifference,
        videoFilename,
        mountWebcam,
        bulk,
        webcamRef,
        commoditiesProcessedBreakdownDelta,
        videoDevice,
        setWebcamSettingsDialogOpen
    } = props;

    const countsChangedRef = useRef(null);
    const countDifferenceRef = useRef(null);

    useEffect(() => {
        countsChangedRef.current = countsChanged;
    }, [countsChanged]);

    useEffect(() => {
        countDifferenceRef.current = countDifference;
    }, [countDifference]);

    const recordFinishedRef = useRef(null);

    useEffect(() => {
        recordFinishedRef.current = recordFinished;
    }, [recordFinished]);

    const commoditiesProcessedBreakdownDeltaRef = useRef(null);

    useEffect(() => {
        commoditiesProcessedBreakdownDeltaRef.current = commoditiesProcessedBreakdownDelta;
    }, [commoditiesProcessedBreakdownDelta]);

    const videoFilenameRef = useRef(null);

    useEffect(() => {
        videoFilenameRef.current = videoFilename;
    }, [videoFilename]);

    const bulkRef = useRef(null);

    useEffect(() => {
        bulkRef.current = bulk;
    }, [bulk]);

    const handleDataAvailable = useCallback(
        data => {
            if (data.data.size > 0) {
                const blob = new Blob([data.data], {
                    type: 'video/webm'
                });
                let filename = videoFilenameRef.current;
                const formData = new FormData();
                formData.append('clerkVideoFile', blob);
                formData.append(
                    'form',
                    JSON.stringify({
                        clerkID: operator._id,
                        bagtag: bagtag,
                        customerUID: customerUID,
                        customerName: customerName,
                        countDifference: countDifferenceRef.current,
                        date: new Date(),
                        commoditiesProcessedBreakdown: commoditiesProcessedBreakdownDeltaRef.current,
                        filename: filename,
                        bulkID: _.get(bulk, '_id', null)
                    })
                );

                http.post('/clerkVideo/upload', formData, false, true);
            }
        },
        [recordFinished, countsChanged]
    );
    const handleStartCaptureClick = useCallback(() => {
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: 'video/webm'
        });
        mediaRecorderRef.current.start();
    }, [webcamRef, setCameraIsOn, mediaRecorderRef]);

    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
    }, [mediaRecorderRef, webcamRef, setCameraIsOn]);

    // react-webcam controls through ref, can't subscribe to a ref change, so using a service to check if ref is ready to use
    useEffect(() => {
        if (webcamIsStarting && !cameraIsOn) {
            let webcamPrepSVCID = setInterval(() => {
                if (!_.isNil(webcamRef.current) && !_.isNil(webcamRef.current.stream)) {
                    setCameraIsOn(true);
                    handleStartCaptureClick();
                    clearInterval(webcamPrepSVCID);
                }
            }, 1000);
        }
    }, [webcamIsStarting]);

    useEffect(() => {
        if (recordFinished && cameraIsOn) {
            finishRecording();
        }
    }, [recordFinished, cameraIsOn]);

    useEffect(() => {
        window.onbeforeunload = function() {
            // don't auto save if the recording is already finished
            if (!recordFinishedRef.current) finishRecording();
        };
        window.onunload = function() {
            // don't auto save if the recording is already finished
            if (!recordFinishedRef.current) finishRecording();
        };

        return () => {
            // don't auto save if the recording is already finished
            if (!recordFinishedRef.current) finishRecording();
        };
    }, []);

    function finishRecording() {
        if (countsChangedRef.current && !_.isNil(mediaRecorderRef.current)) {
            mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
            handleStopCaptureClick();
        }
    }

    return (
        <>
            {mountWebcam && (
                <Webcam
                    style={{ height: '42px', cursor: 'pointer' }}
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{ deviceId: _.get(videoDevice, 'deviceId') }}
                    onClick={() => setWebcamSettingsDialogOpen(true)}
                />
            )}
        </>
    );
}

function LostAndFoundImageDialog(props) {
    const { theme, open, images, handleAddImage, handleDeleteImage, handleLAFImageSubmit, onClose } = props;
    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog open={open} fullWidth maxWidth={'sm'} scroll="paper">
            <DialogTitlePrimary>
                <span style={{ marginLeft: theme.spacing.unit * 2 }}>Upload Photo of Lost and Found Bag(s)</span>
            </DialogTitlePrimary>
            <DialogContent style={{ marginTop: theme.spacing.unit * 2 }}>
                <ImageCapture
                    imageURLs={images}
                    handleAddImage={handleAddImage}
                    handleDeleteImage={handleDeleteImage}
                    placeholderText={<span>Upload your image</span>}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} data-cy="LostAndFoundImageDialog-Cancel">
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => handleLAFImageSubmit()}
                    disabled={_.isEmpty(images)}
                    data-cy="LostAndFoundImageDialog-submit"
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function AICountImageDialog(props) {
    const { theme, open, images, handleAddImage, handleDeleteImage, handleAICountImageSubmit, onClose } = props;
    const { lang } = useContext(LocalizationContext);

    return (
        <Dialog open={open} fullWidth maxWidth={'sm'} scroll="paper">
            <DialogTitlePrimary>
                <span style={{ marginLeft: theme.spacing.unit * 2 }}>Upload Image to BCIP</span>
            </DialogTitlePrimary>
            <DialogContent style={{ marginTop: theme.spacing.unit * 2 }}>
                <ImageCapture
                    imageURLs={images}
                    handleAddImage={handleAddImage}
                    handleDeleteImage={handleDeleteImage}
                    placeholderText={<span>Upload image</span>}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} data-cy="AICountImageDialog-Cancel">
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={handleAICountImageSubmit}
                    disabled={_.isEmpty(images)}
                    data-cy="AICountImageDialog-submit"
                >
                    {loc('submit', lang)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function FrameCapture(props) {
    const {
        theme,
        http,
        open,
        webcamRef,
        skus,
        handleCloseStream,
        onSnackbar,
        handleAddAICounts,
        operator,
        AICountImages,
        showAICountImageDialog,
        AICountPreviews,
        handleAddAICountImage,
        handleDeleteAICountImage,
        setShowAICountImageDialog,
        videoDevice,
        setWebcamSettingsDialogOpen,
        aiCountsConfig
    } = props;

    const { compressImage } = useImageCompressor(aiCountsConfig);
    const [width, height] = useWindowSize();

    const [counts, setCounts] = useState([]);
    const [processingLeft, setProcessingLeft] = useState(0);
    const [loading, setLoading] = useState(false);

    const [imageToShow, setImageToShow] = useState(null);

    const handleUploadFormToServer = async formData => {
        setProcessingLeft(processingLeft => processingLeft + 1);

        onSnackbar('Image is being submit for processing. Please wait...');
        const newCountId = uuid.v4();
        const newCounts = _.cloneDeep(counts);
        newCounts.push({ id: newCountId, isProcessing: true, date: new Date() });
        setCounts(newCounts);
        const res = await http.postJSON('/integrations/processImage', formData, false, true);
        if (res.ok) {
            const predictedCounts = _.get(res, 'data.predictedCounts', []);
            const image = _.get(res, 'data.processedImage', '');

            const beverageSkus = _.get(skus, 'beverage', []);

            const breakdown = [];

            for (const predictedCount of predictedCounts) {
                const predictedCountAmount = _.get(predictedCount, 'amount', 0);
                const predictedCountSku = _.get(predictedCount, 'sku', 10010);

                if (predictedCountAmount <= 0) continue;

                const sku = _.find(beverageSkus, obj => obj.sku == predictedCountSku);

                breakdown.push({
                    count: predictedCountAmount,
                    sku
                });
            }

            if (!_.isEmpty(breakdown)) {
                let newCounts = [];
                for (const breakdownItem of breakdown) {
                    newCounts.push({ amount: breakdownItem.count, sku: breakdownItem.sku, countImageUrl: image });
                }

                await handleAddAICounts(newCounts);
                onSnackbar('Image processed, counts added');
            } else {
                onSnackbar('An image you submitted did not return any counts');
            }
            setCounts(counts => {
                let updatedCounts = _.cloneDeep(counts);
                let newCount = _.find(updatedCounts, { id: newCountId });
                newCount.breakdown = breakdown;
                newCount.bags = 1;
                newCount.image = image;
                newCount.isProcessing = false;
                return updatedCounts;
            });

            setProcessingLeft(processingLeft => processingLeft - 1);
        } else {
            onSnackbar('An error occurred while processing a submitted image.', true);
        }
    };

    const handleCaptureUpload = async () => {
        setLoading(true);
        setTimeout(() => {
            // Superficial delay to offset some load on BCIP and prevent submission spamming
            setLoading(false);
        }, 1500);

        let height = 1080;
        let width = 1920;
        if (videoDevice && 'getCapabilities' in videoDevice) {
            const deviceCapabilities = videoDevice.getCapabilities();

            height = _.get(deviceCapabilities, 'height.max', 1080);
            width = _.get(deviceCapabilities, 'width.max', 1920);
        }

        const screenshot = webcamRef.current.getScreenshot({ height, width });
        const screenshotBlob = await fetch(screenshot).then(res => res.blob());
        const compressedImageBlob = await compressImage(screenshotBlob);
        const compressedImage = await blobToBase64(compressedImageBlob);

        const formData = new FormData();
        formData.append('image', compressedImage);

        await handleUploadFormToServer(formData);
    };

    const handleImageUpload = async () => {
        setShowAICountImageDialog(false);

        setLoading(true);
        setTimeout(() => {
            // Superficial delay to offset some load on BCIP and prevent submission spamming
            setLoading(false);
        }, 1500);

        const compressedImageBlob = await compressImage(AICountImages[0]);
        const compressedImage = await blobToBase64(compressedImageBlob);

        const formData = new FormData();
        formData.append('image', compressedImage);

        await handleUploadFormToServer(formData);
    };

    return (
        <>
            <Dialog
                open={open}
                maxWidth={false}
                maxHeight={false}
                PaperProps={{
                    style: {
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        maxHeight: '50%',
                        padding: '8px'
                    }
                }}
            >
                {!imageToShow && (
                    <Webcam
                        style={{ width: '66%' }}
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{ deviceId: _.get(videoDevice, 'deviceId') }}
                    />
                )}
                {imageToShow && <img src={imageToShow} style={{ width: '66%' }} />}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginLeft: '8px',
                        overflow: 'hidden'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: '100%',
                            border: '1px solid lightgrey',
                            borderRadius: '4px',
                            overflow: 'auto'
                        }}
                    >
                        {counts.map(count => {
                            if (count.isProcessing) {
                                return (
                                    <div
                                        style={{
                                            marginTop: '8px',
                                            marginLeft: '8px',
                                            marginRight: '8px',
                                            border: '1px solid lightgrey',
                                            borderRadius: '4px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            paddingLeft: '5px',
                                            paddingRight: '5px'
                                        }}
                                    >
                                        <Typography style={{}}>{moment(count.date).format('h:mm:ss A')}</Typography>
                                        <CircularProgress size="16px" style={{ margin: '8px' }} />
                                    </div>
                                );
                            }
                            return (
                                <div
                                    style={{
                                        margin: '8px',
                                        padding: '4px',
                                        border: '1px solid lightgrey',
                                        borderRadius: '4px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div>
                                        {count.image != imageToShow && (
                                            <Typography
                                                onClick={() => setImageToShow(count.image)}
                                                style={{ color: theme.palette.linkColor, cursor: 'pointer' }}
                                            >
                                                Show Image
                                            </Typography>
                                        )}
                                        {count.image == imageToShow && (
                                            <Typography
                                                onClick={() => setImageToShow(null)}
                                                style={{ color: theme.palette.linkColor, cursor: 'pointer' }}
                                            >
                                                Hide Image
                                            </Typography>
                                        )}
                                        {_.isEmpty(count.breakdown) && (
                                            <Typography style={{}}>No containers detected</Typography>
                                        )}
                                        {count.breakdown.map(amounts => (
                                            <Typography>
                                                {amounts.sku.description} {amounts.sku.label} ({amounts.count})
                                            </Typography>
                                        ))}
                                        {/* <Typography>{count.image}</Typography> */}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            margin: '8px',
                            justifyContent: 'space-around'
                        }}
                    >
                        {operator.isAllowedToDebug && (
                            <Button
                                style={{ padding: '4px' }}
                                onClick={() => setShowAICountImageDialog(true)}
                                color="primary"
                                variant="contained"
                                disabled={loading}
                            >
                                Upload
                            </Button>
                        )}
                        <Button
                            style={{ padding: '4px' }}
                            onClick={handleCaptureUpload}
                            color="primary"
                            variant="contained"
                            disabled={loading || !_.isNil(imageToShow)}
                        >
                            Capture
                        </Button>
                        <Button
                            style={{ padding: '4px' }}
                            onClick={() => setWebcamSettingsDialogOpen(true)}
                            color="default"
                            variant="contained"
                        >
                            <Icon>settings</Icon>
                        </Button>
                        <Button
                            style={{ padding: '4px' }}
                            onClick={() => {
                                handleCloseStream();
                                setCounts([]);
                                setProcessingLeft(0);
                                setImageToShow(null);
                            }}
                            color="secondary"
                            variant="contained"
                            disabled={loading}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </Dialog>

            <AICountImageDialog
                theme={theme}
                open={showAICountImageDialog}
                onClose={() => setShowAICountImageDialog(false)}
                images={AICountPreviews}
                handleAddImage={handleAddAICountImage}
                handleDeleteImage={handleDeleteAICountImage}
                handleAICountImageSubmit={handleImageUpload}
            />
        </>
    );
}
const ZoomViewer = props => {
    const { src } = props;
    return (
        <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                    <div className="tools" style={{ position: 'absolute', zIndex: 1, top: 10, left: 10 }}>
                        <button onClick={() => zoomIn()} style={{ marginRight: 10 }}>
                            Zoom In
                        </button>
                        <button onClick={() => zoomOut()} style={{ marginRight: 10 }}>
                            Zoom Out
                        </button>
                        <button onClick={() => resetTransform()}>Reset</button>
                    </div>
                    <TransformComponent>
                        <img src={src} alt="countImage" style={{ width: '100%', height: '100%' }} />
                    </TransformComponent>
                </React.Fragment>
            )}
        </TransformWrapper>
    );
};

function getInitialBagsToProcess(bulkHasNoBags, commodityAmountRemaining, collector) {
    if (bulkHasNoBags) {
        return 1;
    } else {
        return _.get(collector, 'configuration.defaultToCountingAllBags', false) ? commodityAmountRemaining : 1;
    }
}

function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

function getCameraName(device) {
    const fullName = _.get(device, 'label', 'Unknown');
    const splitName = fullName.split('(');

    let name = '';
    for (let i = 0; i < splitName.length - 1; i++) {
        name += splitName[i];
    }

    return name;
}

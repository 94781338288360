import React, { Fragment, useState, useEffect, useContext, useMemo } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import queryString from 'query-string';
import HttpContext from 'utils/contexts/HttpContext';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import TripIncompleteDialog from 'containers/Operators/Pickups/TripIncompleteDialog.js';
import {
    formatAsCurrency,
    convertSecondsToHHmm,
    formatsSecondsToTime,
    convertSecondsToMinutes,
    getPlural
} from 'utils/misc';
import LocalizationContext from 'utils/contexts/LocalizationContext';

import DetailedPayloadIndicators from 'components/DriverWidgets/DetailedPayloadIndicators';

import {
    colors,
    Button,
    Divider,
    Avatar,
    Icon,
    IconButton,
    Paper,
    Grid,
    Typography,
    List,
    ListItem,
    ListItemSecondaryAction,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Select,
    FormControl,
    InputLabel,
    Input,
    MenuItem
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';
import { _commodity, _pickup, _time } from 'std';
import { loc } from '../../localizations/localizationHandler';
import { isCONRegion } from '../../utils/misc';

function TripsWidget(props) {
    const { theme, operator, upcomingTrips, commodities, loading } = props;
    const [dayEstimates, setDayEstimates] = useState({});
    const [timeSheet, setTimeSheet] = useState(null);
    const [truckList, setTruckList] = useState([]);
    const [showTruckSelectDialog, setShowTruckSelectDialog] = useState(false);
    const [currentTripId, setCurrentTripId] = useState(null);
    const [showTripIncompleteDialog, setShowTripIncompleteDialog] = useState(false);

    const { lang } = useContext(LocalizationContext);
    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);

    const handleGoToHistory = () => {
        props.history.push(`/operators/${operator._id}/driver-completed?${queryString.stringify({ back: true })}`);
    };

    const handleGoToPickups = trip_id => {
        if (operator.adminView) {
            return window.alert(`Administrators cannot enter Driver's trip view.`);
        }

        let time = _.find(upcomingTrips, trip => trip._id === trip_id).startTime;
        props.onCustomHeader(`Trip ${moment(time).format('h:mm A')}`);
        props.history.push(
            `/operators/${operator._id}/pickups?trip_id=${trip_id}&${queryString.stringify({ back: true })}`
        );
        // if (!_.isNil(window.cordova)) {
        //     window.cordova.plugins.backgroundMode.setEnabled(true);
        //     window.cordova.plugins.backgroundMode.disableWebViewOptimizations();
        //     window.cordova.plugins.backgroundMode.configure({
        //         title: `Active trip.`,
        //         text: `You have pickups to complete.`
        //     });
        // }
    };

    let upcomingTripsGroupedByDate = useMemo(
        () =>
            _.groupBy(_.sortBy(upcomingTrips, trip => trip.startTime), trip =>
                moment(trip.startTime)
                    .tz(operator.collector.timezone)
                    .startOf('day')
                    .toDate()
            ),
        [upcomingTrips]
    );

    useEffect(() => {
        //Get estimated upcoming trips
        (async function() {
            let day = moment()
                .tz(operator.collector.timezone)
                .add(1, 'day');
            const daysWithTrips = Object.keys(upcomingTripsGroupedByDate);
            const daysToEstimate = [];

            while (day < moment().add(6, 'days')) {
                daysToEstimate.push(day.toDate());
                day = day.add(1, 'days');
            }

            daysToEstimate.forEach(dayToEstimate => {
                const hasTrip = _.find(daysWithTrips, dayWithTrip =>
                    moment(dayToEstimate)
                        .tz(operator.collector.timezone)
                        .isSame(dayWithTrip, 'day')
                );
                if (!_.isNil(hasTrip)) {
                    _.pull(daysToEstimate, dayToEstimate);
                }
            });

            const res = await http.getJSON(
                `/pickups/driverWeeklyTrips?driver_id=${operator._id}&collector_id=${
                    operator.collector._id
                }&days=${daysToEstimate}`
            );
            if (res.ok) {
                setDayEstimates(res.data.totals);
            }
        })();
        getTodayTimeSheet();
        getTruckList();
    }, []);

    const tripsToRender = useMemo(() => {
        const allDays = _.merge(dayEstimates, upcomingTripsGroupedByDate);
        const sortedDays = {};

        Object.keys(allDays)
            .sort((a, b) => new Date(a) - new Date(b))
            .forEach(key => {
                sortedDays[key] = allDays[key];
            });

        return sortedDays;
    }, [upcomingTripsGroupedByDate, dayEstimates]);

    const getTodayTimeSheet = async function() {
        const res = await http.getJSON(`/timeSheets/${operator._id}/getTodayTimeSheet`);
        if (res.ok) {
            setTimeSheet(_.get(res, 'data.timeSheet', null));
        }
    };
    async function getTruckList() {
        const res = await http.getJSON(`/trucks/getEnabledTrucks`);
        if (res.ok) {
            setTruckList(_.get(res, 'data.trucks', []));
        }
    }

    function getCurrentTripId() {
        if (currentTripId !== null) {
            return currentTripId;
        }
        let today = moment()
            .tz(operator.collector.timezone)
            .startOf('day')
            .toDate();
        const tripsForToday = _.get(upcomingTripsGroupedByDate, today, []);
        let currentTrip = _.find(tripsForToday, trip => _.isNil(trip.offloadTime));
        return currentTrip._id;
    }
    async function handleClockIn(tripId) {
        await http.post(`/timeSheets/${operator._id}/clockIn`);
        handleGoToPickups(tripId);
    }
    let clockInWidget = <></>;
    if (timeSheet === null) {
        if (!_.isEmpty(getTodayTrips(tripsToRender, operator))) {
            clockInWidget = (
                <Button
                    variant="contained"
                    color="primary"
                    style={{}}
                    onClick={() => {
                        if (_.isEmpty(getTodayTrips(tripsToRender, operator))) return;
                        // setShowTruckSelectDialog(true);
                        handleGoToPickups(getCurrentTripId());
                    }}
                >
                    {loc('goToTrip', lang)}
                </Button>
            );
        }
    } else if (_.get(timeSheet, 'totalWorkingHours', null) === null) {
        clockInWidget = (
            <>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Icon color="action">timer</Icon>
                    <OffloadElapsedTime startTime={timeSheet.clockInTime} />
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 10 }}
                    data-cy="driver-dashboard-clock-out"
                    onClick={() => {
                        if (_.isEmpty(upcomingTrips)) return;
                        let tripIncomplete = _.some(_.get(_.first(upcomingTrips), 'pickups', []), {
                            aborted: false,
                            complete: false
                        });
                        if (tripIncomplete) {
                            setShowTripIncompleteDialog(true);
                        } else {
                            warnAction(async () => {
                                await http.post(`/timeSheets/${operator._id}/clockOut`, {
                                    clockOutTime: moment().toDate()
                                });
                                await getTodayTimeSheet();
                            }, 'Are you sure you want to clock out right now?');
                        }
                    }}
                >
                    {loc('clockOut', lang)}
                </Button>
            </>
        );
    } else {
        clockInWidget = (
            <Typography data-cy="driver-worked-time" variant="h6">
                Worked {totalWorkingHoursToHourMin(timeSheet.totalWorkingHours)}
            </Typography>
        );
    }
    if (!_.get(operator, 'collector.configuration.enableClockin', true)) {
        clockInWidget = <></>;
    }

    return (
        <>
            {/* <TruckSelectionDialog
                open={showTruckSelectDialog}
                onClose={() => {
                    setShowTruckSelectDialog(false);
                    setCurrentTripId(null);
                }}
                truckList={truckList}
                onSubmit={truckId => {
                    handleClockIn(truckId, getCurrentTripId());
                }}
            /> */}
            <TripIncompleteDialog
                open={showTripIncompleteDialog}
                handleClose={() => {
                    setShowTripIncompleteDialog(false);
                }}
            />
            <Paper style={{ padding: theme.spacing.unit * 2 }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: theme.spacing.unit
                    }}
                >
                    <Typography variant="h6">{loc('today', lang)}</Typography>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                    >
                        {clockInWidget}
                    </div>
                </div>
                <Divider />
                {loading ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: theme.spacing.unit,
                            marginBottom: theme.spacing.unit
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <Grid container>
                        <Grid item xs={12}>
                            {_.isEmpty(_.keys(getTodayTrips(tripsToRender, operator))) && (
                                <Typography style={{ margin: theme.spacing.unit * 2, marginBottom: 0 }}>
                                    {loc('notrips', lang)}
                                </Typography>
                            )}
                            <List
                                style={{
                                    paddingLeft: theme.spacing.unit * 0.5,
                                    paddingRight: theme.spacing.unit * 0.5
                                }}
                            >
                                {_.keys(getTodayTrips(tripsToRender, operator)).map((date, index) => {
                                    const isEstimates = typeof tripsToRender[date] === 'number';
                                    if (isEstimates && tripsToRender[date] <= 0) return null;

                                    const tripsForDay = _.get(upcomingTripsGroupedByDate, date, []);

                                    return (
                                        <Fragment key={date}>
                                            {!isEstimates && tripsForDay.length > 1 && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginTop: theme.spacing.unit * 2
                                                    }}
                                                >
                                                    <Icon
                                                        style={{
                                                            color: colors.red[500],
                                                            marginRight: theme.spacing.unit
                                                        }}
                                                    >
                                                        error
                                                    </Icon>
                                                    <Typography
                                                        variant="subtitle2"
                                                        style={{
                                                            fontWeight: 400,
                                                            color: colors.red[500]
                                                        }}
                                                    >
                                                        {moment(date)
                                                            .tz(operator.collector.timezone)
                                                            .format('dddd, MMM D')}{' '}
                                                        ({tripsForDay.length} routes)
                                                    </Typography>
                                                </div>
                                            )}
                                            {(isEstimates || tripsForDay.length <= 1) && (
                                                <Typography
                                                    variant="subtitle2"
                                                    style={{ marginTop: theme.spacing.unit * 2, fontWeight: 400 }}
                                                >
                                                    {moment(date)
                                                        .tz(operator.collector.timezone)
                                                        .format('dddd, MMM D')}
                                                </Typography>
                                            )}

                                            {isEstimates ? (
                                                <ListItem
                                                    disabled
                                                    disableGutters
                                                    style={{
                                                        paddingTop: theme.spacing.unit / 2,
                                                        paddingBottom: theme.spacing.unit / 2
                                                    }}
                                                >
                                                    <Avatar
                                                        style={{
                                                            width: 54,
                                                            height: 54,
                                                            borderRadius: 8,
                                                            backgroundColor: theme.palette.primary.secondary,
                                                            flexWrap: 'wrap'
                                                        }}
                                                    >
                                                        <div
                                                            data-cy="driver-bag-count"
                                                            style={{
                                                                width: '100%',
                                                                textAlign: 'center',
                                                                fontSize: 18,
                                                                marginBottom: -8
                                                            }}
                                                        >
                                                            —
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                textAlign: 'center',
                                                                fontSize: 12,
                                                                marginTop: -8
                                                            }}
                                                        >
                                                            bags
                                                        </div>
                                                    </Avatar>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flex: '1 1 auto',
                                                            padding: `0px ${theme.spacing.unit * 2}px`,
                                                            minWidth: 0
                                                        }}
                                                    >
                                                        <div style={{ width: '100%' }}>
                                                            <Typography variant="body2" color="primary" noWrap>
                                                                Approximately {tripsToRender[date]} pickups
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="default"
                                                                style={{ fontSize: '0.75rem' }}
                                                            >
                                                                Distance: TBD
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </ListItem>
                                            ) : (
                                                tripsForDay.map(trip => {
                                                    const combinedPayloadItems = _commodity.getCombinedPayloadItemsForPickups(
                                                        trip.pickups,
                                                        commodities
                                                    );
                                                    const groupedPayloadTotals = _commodity.groupPayloadItemCountsByUnit(
                                                        combinedPayloadItems
                                                    );

                                                    let numOfBinsForTrip = 0;

                                                    const appointments = [];

                                                    trip.pickups.forEach(pickup => {
                                                        if (pickup.customer) {
                                                            let numberOfBinsForPickup = _pickup.getNumberOfBins(
                                                                pickup,
                                                                commodities
                                                            );
                                                            numOfBinsForTrip += numberOfBinsForPickup;
                                                        }
                                                        if (pickup.timeSlotBooking && pickup.date) {
                                                            const timezone = _.get(
                                                                trip,
                                                                'timezone',
                                                                process.env.REACT_APP_REGION_TIMEZONE
                                                            );
                                                            const timeToPush = moment(pickup.date)
                                                                .tz(timezone)
                                                                .format('hh:mm A');
                                                            appointments.push(timeToPush);
                                                        }
                                                    });

                                                    const distance = _.isNil(trip.distance)
                                                        ? '...'
                                                        : _.round(trip.distance / 1000, 1) + ' km';
                                                    const duration = _.isNil(trip.duration)
                                                        ? '...'
                                                        : convertSecondsToHHmm(trip.duration);

                                                    const currentDateInteger = _time.getDateInteger(
                                                        operator.collector.timezone,
                                                        new Date()
                                                    );
                                                    const disabled = trip.startDateInteger !== currentDateInteger;

                                                    const lunchBreakDuration = _.reduce(
                                                        _.get(trip, 'route.legs', []),
                                                        (sum, leg) => (sum += _.get(leg, 'lunchBreakDuration', 0)),
                                                        0
                                                    );
                                                    let lunchLeg, lunchPickup, estimatedLunchTime;
                                                    lunchLeg = _.find(
                                                        _.get(trip, 'route.legs', []),
                                                        l => l.lunchBreakDuration > 0
                                                    );
                                                    if (!_.isNil(lunchLeg)) {
                                                        lunchPickup = _.find(
                                                            trip.pickups,
                                                            p => p._id.toString() === lunchLeg.pickup_id.toString()
                                                        );

                                                        if (!_.isNil(lunchPickup)) {
                                                            estimatedLunchTime = moment(
                                                                _.last(lunchPickup.estimatedCompletionDates)
                                                            )
                                                                .subtract(lunchBreakDuration, 'seconds')
                                                                .subtract(lunchLeg.fullDuration, 'seconds');
                                                        }
                                                    }

                                                    return (
                                                        <ListItem
                                                            key={trip._id}
                                                            button
                                                            dense
                                                            disableGutters
                                                            disabled={disabled}
                                                            style={{
                                                                paddingTop: theme.spacing.unit / 2,
                                                                paddingBottom: theme.spacing.unit / 2
                                                            }}
                                                            onClick={() => {
                                                                // if (timeSheet === null) {
                                                                //     setCurrentTripId(trip._id);
                                                                //     setShowTruckSelectDialog(true);
                                                                // } else {
                                                                handleGoToPickups(trip._id);
                                                                // }
                                                                // handleClockIn(trip._id);
                                                            }}
                                                        >
                                                            <Avatar
                                                                style={{
                                                                    borderRadius: 6,
                                                                    width: 64,
                                                                    height:
                                                                        Object.keys(groupedPayloadTotals).length * 24,
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'space-around',
                                                                    alignItems: 'center',
                                                                    backgroundColor: theme.palette.primary.main
                                                                }}
                                                            >
                                                                {Object.keys(groupedPayloadTotals).map(payloadType => {
                                                                    const amount = groupedPayloadTotals[payloadType];

                                                                    return (
                                                                        <div
                                                                            data-cy={`driver-${payloadType}-count`}
                                                                            style={{
                                                                                width: '100%',
                                                                                textAlign: 'center',
                                                                                fontSize: 14
                                                                            }}
                                                                        >
                                                                            {`${amount} ${
                                                                                amount > 1
                                                                                    ? getPlural(payloadType)
                                                                                    : payloadType
                                                                            }`}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </Avatar>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flex: '1 1 auto',
                                                                    padding: `0px ${theme.spacing.unit * 2}px`,
                                                                    minWidth: 0,
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                <div style={{ width: '100%' }}>
                                                                    <Typography variant="body2" color="primary" noWrap>
                                                                        {new moment(trip.startTime)
                                                                            .subtract(
                                                                                _.get(trip, 'maxStartTripTime', 30),
                                                                                'm'
                                                                            )
                                                                            .tz(operator.collector.timezone)
                                                                            .format('h:mm A')}
                                                                        {lunchBreakDuration > 0 &&
                                                                            !_.isNil(estimatedLunchTime) && (
                                                                                <span
                                                                                    style={{
                                                                                        color: colors.grey[700]
                                                                                    }}
                                                                                >
                                                                                    {' ('}
                                                                                    {convertSecondsToMinutes(
                                                                                        lunchBreakDuration,
                                                                                        0
                                                                                    )}{' '}
                                                                                    lunch break at{' '}
                                                                                    {new moment(
                                                                                        estimatedLunchTime
                                                                                    ).format('HH:mm')}
                                                                                    {')'}
                                                                                </span>
                                                                            )}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="default"
                                                                        style={{ fontSize: '0.75rem' }}
                                                                    >
                                                                        {loc('tripIs', lang)}: {distance} ({duration})
                                                                    </Typography>
                                                                    {numOfBinsForTrip > 0 && (
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center'
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                style={{
                                                                                    color: colors.yellow[700],
                                                                                    marginRight: theme.spacing.unit / 2,
                                                                                    fontSize: '16px'
                                                                                }}
                                                                            >
                                                                                warning
                                                                            </Icon>
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="default"
                                                                                style={{ fontSize: '0.75rem' }}
                                                                            >
                                                                                <strong>
                                                                                    Please load {numOfBinsForTrip} bins
                                                                                </strong>
                                                                            </Typography>
                                                                        </div>
                                                                    )}
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            flexWrap: 'wrap'
                                                                        }}
                                                                    >
                                                                        <DetailedPayloadIndicators
                                                                            showUnits
                                                                            commodities={commodities}
                                                                            indicatorSize={0.7}
                                                                            pickups={trip.pickups}
                                                                            labelStyle={{
                                                                                fontSize: '0.75rem'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {!_.isEmpty(appointments) && (
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="default"
                                                                            style={{ fontSize: '0.75rem' }}
                                                                        >
                                                                            Pickups: {appointments.join(', ')}
                                                                        </Typography>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    disabled={disabled}
                                                                    data-cy={`${index}-trips-widget-trip-button`}
                                                                    onClick={() => {
                                                                        // if (timeSheet === null) {
                                                                        //     setCurrentTripId(trip._id);
                                                                        //     setShowTruckSelectDialog(true);
                                                                        // } else {
                                                                        handleGoToPickups(trip._id);
                                                                        // }
                                                                        // handleClockIn(trip._id);
                                                                    }}
                                                                >
                                                                    <Icon>keyboard_arrow_right</Icon>
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    );
                                                })
                                            )}
                                        </Fragment>
                                    );
                                })}
                            </List>
                            {timeSheet !== null && _.get(timeSheet, 'totalWorkingHours', null) === null && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    style={{
                                        marginTop: theme.spacing.unit * 1.5,
                                        width: `calc(100% - ${theme.spacing.unit * 2}px)`
                                    }}
                                    onClick={handleGoToHistory}
                                >
                                    {loc('undoPickups', lang)}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Paper>
            <Paper style={{ padding: theme.spacing.unit * 2, marginTop: theme.spacing.unit }}>
                <Typography variant="h6">{loc('upcomingTrips', lang)}</Typography>
                <Divider />
                {loading ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: theme.spacing.unit,
                            marginBottom: theme.spacing.unit
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <Grid container>
                        <Grid item xs={12}>
                            {!_.some(Object.values(tripsToRender), value => value !== 0) && (
                                <Typography style={{ margin: theme.spacing.unit * 2, marginBottom: 0 }}>
                                    {loc('notrips', lang)}
                                </Typography>
                            )}
                            <List
                                style={{
                                    paddingLeft: theme.spacing.unit * 0.5,
                                    paddingRight: theme.spacing.unit * 0.5
                                }}
                            >
                                {_.keys(tripsToRender).map((date, index) => {
                                    const isEstimates = typeof tripsToRender[date] === 'number';
                                    if (isEstimates && tripsToRender[date] <= 0) return null;

                                    const currentDateInteger = _time.getDateInteger(
                                        operator.collector.timezone,
                                        new Date()
                                    );
                                    const tripDateInteger = _time.getDateInteger(
                                        operator.collector.timezone,
                                        new Date(date)
                                    );
                                    if (
                                        currentDateInteger === tripDateInteger ||
                                        new Date().getTime() >= new Date(date).getTime()
                                    ) {
                                        return null;
                                    }

                                    const tripsForDay = _.get(upcomingTripsGroupedByDate, date, []);

                                    return (
                                        <Fragment key={date}>
                                            {!isEstimates && tripsForDay.length > 1 && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginTop: theme.spacing.unit * 2
                                                    }}
                                                >
                                                    <Icon
                                                        style={{
                                                            color: colors.red[500],
                                                            marginRight: theme.spacing.unit
                                                        }}
                                                    >
                                                        error
                                                    </Icon>
                                                    <Typography
                                                        variant="subtitle2"
                                                        style={{
                                                            fontWeight: 400,
                                                            color: colors.red[500]
                                                        }}
                                                    >
                                                        {moment(date)
                                                            .tz(operator.collector.timezone)
                                                            .format('dddd, MMM D')}{' '}
                                                        ({tripsForDay.length} routes)
                                                    </Typography>
                                                </div>
                                            )}
                                            {(isEstimates || tripsForDay.length <= 1) && (
                                                <Typography
                                                    variant="subtitle2"
                                                    style={{ marginTop: theme.spacing.unit * 2, fontWeight: 400 }}
                                                >
                                                    {moment(date)
                                                        .tz(operator.collector.timezone)
                                                        .format('dddd, MMM D')}
                                                </Typography>
                                            )}

                                            {isEstimates ? (
                                                <ListItem
                                                    disabled
                                                    disableGutters
                                                    style={{
                                                        paddingTop: theme.spacing.unit / 2,
                                                        paddingBottom: theme.spacing.unit / 2
                                                    }}
                                                >
                                                    <Avatar
                                                        style={{
                                                            width: 54,
                                                            height: 54,
                                                            backgroundColor: theme.palette.primary.secondary,
                                                            flexWrap: 'wrap'
                                                        }}
                                                    >
                                                        <div
                                                            data-cy="driver-bag-count"
                                                            style={{
                                                                width: '100%',
                                                                textAlign: 'center',
                                                                fontSize: 18,
                                                                marginBottom: -8
                                                            }}
                                                        >
                                                            —
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                textAlign: 'center',
                                                                fontSize: 12,
                                                                marginTop: -8
                                                            }}
                                                        >
                                                            bags
                                                        </div>
                                                    </Avatar>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flex: '1 1 auto',
                                                            padding: `0px ${theme.spacing.unit * 2}px`,
                                                            minWidth: 0
                                                        }}
                                                    >
                                                        <div style={{ width: '100%' }}>
                                                            <Typography variant="body2" color="primary" noWrap>
                                                                Approximately {tripsToRender[date]} pickups
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="default"
                                                                style={{ fontSize: '0.75rem' }}
                                                            >
                                                                Distance: TBD
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </ListItem>
                                            ) : (
                                                tripsForDay.map(trip => {
                                                    const combinedPayloadItems = _commodity.getCombinedPayloadItemsForPickups(
                                                        trip.pickups,
                                                        commodities
                                                    );
                                                    const groupedPayloadTotals = _commodity.groupPayloadItemCountsByUnit(
                                                        combinedPayloadItems
                                                    );

                                                    let numOfBinsForTrip = 0;

                                                    const appointments = [];

                                                    trip.pickups.forEach(pickup => {
                                                        if (pickup.customer) {
                                                            let numberOfBinsForPickup = _pickup.getNumberOfBins(
                                                                pickup,
                                                                commodities
                                                            );
                                                            numOfBinsForTrip += numberOfBinsForPickup;
                                                        }
                                                        if (pickup.timeSlotBooking && pickup.date) {
                                                            const timezone = _.get(
                                                                trip,
                                                                'timezone',
                                                                process.env.REACT_APP_REGION_TIMEZONE
                                                            );
                                                            const timeToPush = moment(pickup.date)
                                                                .tz(timezone)
                                                                .format('hh:mm A');
                                                            appointments.push(timeToPush);
                                                        }
                                                    });

                                                    const distance = _.isNil(trip.distance)
                                                        ? '...'
                                                        : _.round(trip.distance / 1000, 1) + ' km';
                                                    const duration = _.isNil(trip.duration)
                                                        ? '...'
                                                        : convertSecondsToHHmm(trip.duration);
                                                    const currentDateInteger = _time.getDateInteger(
                                                        operator.collector.timezone,
                                                        new Date()
                                                    );
                                                    const disabled = trip.startDateInteger !== currentDateInteger;

                                                    return (
                                                        <ListItem
                                                            key={trip._id}
                                                            button
                                                            dense
                                                            disableGutters
                                                            disabled={disabled}
                                                            style={{
                                                                paddingTop: theme.spacing.unit / 2,
                                                                paddingBottom: theme.spacing.unit / 2
                                                            }}
                                                            onClick={() => {}}
                                                        >
                                                            <Avatar
                                                                style={{
                                                                    borderRadius: 6,
                                                                    width: 64,
                                                                    height:
                                                                        Object.keys(groupedPayloadTotals).length * 24,
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'space-around',
                                                                    alignItems: 'center',
                                                                    backgroundColor: theme.palette.primary.main
                                                                }}
                                                            >
                                                                {Object.keys(groupedPayloadTotals).map(payloadType => {
                                                                    const amount = groupedPayloadTotals[payloadType];

                                                                    return (
                                                                        <div
                                                                            data-cy={`driver-${payloadType}-count`}
                                                                            style={{
                                                                                width: '100%',
                                                                                textAlign: 'center',
                                                                                fontSize: 14
                                                                            }}
                                                                        >
                                                                            {`${amount} ${
                                                                                amount > 1
                                                                                    ? getPlural(payloadType)
                                                                                    : payloadType
                                                                            }`}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </Avatar>
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flex: '1 1 auto',
                                                                    padding: `0px ${theme.spacing.unit * 2}px`,
                                                                    minWidth: 0,
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                <div style={{ width: '100%' }}>
                                                                    <Typography variant="body2" color="primary" noWrap>
                                                                        {new moment(trip.startTime)
                                                                            .subtract(
                                                                                _.get(trip, 'maxStartTripTime', 30),
                                                                                'm'
                                                                            )
                                                                            .tz(operator.collector.timezone)
                                                                            .format('h:mm A')}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body2"
                                                                        color="default"
                                                                        style={{ fontSize: '0.75rem' }}
                                                                    >
                                                                        {loc('tripIs', lang)}: {distance} ({duration})
                                                                    </Typography>
                                                                    {numOfBinsForTrip > 0 && (
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                alignItems: 'center'
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                style={{
                                                                                    color: colors.yellow[700],
                                                                                    marginRight: theme.spacing.unit / 2,
                                                                                    fontSize: '16px'
                                                                                }}
                                                                            >
                                                                                warning
                                                                            </Icon>
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="default"
                                                                                style={{ fontSize: '0.75rem' }}
                                                                            >
                                                                                <strong>
                                                                                    Please load {numOfBinsForTrip} bins
                                                                                </strong>
                                                                            </Typography>
                                                                        </div>
                                                                    )}

                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            flexWrap: 'wrap'
                                                                        }}
                                                                    >
                                                                        <DetailedPayloadIndicators
                                                                            showUnits
                                                                            commodities={commodities}
                                                                            indicatorSize={0.7}
                                                                            pickups={trip.pickups}
                                                                            labelStyle={{
                                                                                fontSize: '0.75rem'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {!_.isEmpty(appointments) && (
                                                                        <Typography
                                                                            variant="body2"
                                                                            color="default"
                                                                            style={{ fontSize: '0.75rem' }}
                                                                        >
                                                                            Pickups: {appointments.join(', ')}
                                                                        </Typography>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    disabled={disabled}
                                                                    data-cy={`${index}-trips-widget-trip-button`}
                                                                    onClick={() => {}}
                                                                >
                                                                    <Icon>keyboard_arrow_right</Icon>
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    );
                                                })
                                            )}
                                        </Fragment>
                                    );
                                })}
                            </List>
                        </Grid>
                    </Grid>
                )}
            </Paper>
        </>
    );
}

export default withTheme()(TripsWidget);

function TruckSelectionDialog(props) {
    const { open, onClose, truckList = [], onSubmit = () => {} } = props;
    const [selectedTruckId, setSelectedTruckId] = useState('');
    return (
        <Dialog open={open} onClose={() => onClose()}>
            <DialogTitle>Please Select Your Truck</DialogTitle>
            <DialogContent>
                <FormControl>
                    <InputLabel>Truck</InputLabel>
                    <Select
                        value={selectedTruckId}
                        onChange={newValue => {
                            setSelectedTruckId(newValue.target.value);
                        }}
                        input={<Input name="Select Truck" />}
                        displayEmpty
                        name="Select Truck"
                        style={{
                            minWidth: 300
                        }}
                    >
                        {truckList.map(truck => {
                            let truckName = _.get(truck, 'name', '');
                            let truckId = _.get(truck, '_id', '');
                            return (
                                <MenuItem value={truckId}>
                                    <em>{truckName}</em>
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color={'primary'} onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color={'primary'}
                    disabled={selectedTruckId === '' || _.isNil(selectedTruckId)}
                    onClick={() => {
                        onSubmit(selectedTruckId);
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
function OffloadElapsedTime({ startTime }) {
    const [timeElapsed, setTimeElapsed] = useState(getTimeDifferenceFromNow(startTime));

    function getTimeDifferenceFromNow(time) {
        return formatsSecondsToTime(moment(new Date()).diff(moment(time), 'second'), true);
    }
    setInterval(() => {
        setTimeElapsed(getTimeDifferenceFromNow(startTime));
    }, 1000);
    return (
        <Typography variant="body2" color="textSecondary" style={{ fontSize: '1.0rem' }}>
            {timeElapsed}
        </Typography>
    );
}

function totalWorkingHoursToHourMin(totalWorkingHours) {
    let hour = Math.floor(totalWorkingHours);
    let min = (totalWorkingHours % 1) * 60;
    let minString = min
        .toFixed(0)
        .toString()
        .padStart(2, '0');
    return hour.toString() + ':' + minString;
}
function getTodayTrips(tripsToRender, operator) {
    let newTripsToRender = {};
    _.keys(tripsToRender).map((date, index) => {
        const currentDateInteger = _time.getDateInteger(operator.collector.timezone, new Date());
        const tripDateInteger = _time.getDateInteger(operator.collector.timezone, new Date(date));
        if (currentDateInteger === tripDateInteger) {
            newTripsToRender[date] = tripsToRender[date];
        }
    });
    return newTripsToRender;
}

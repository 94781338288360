import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { withTheme } from '@material-ui/core/styles';
import {
    Tooltip,
    IconButton,
    Icon,
    Button,
    colors,
    withStyles,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    InputAdornment,
    DialogTitle
} from '@material-ui/core';

import OTCLocationForm from './Forms/OTCLocationForm';

import CRUDTable from './CRUDTable';
import useCRUD from './hooks/useCRUD';

import { useContext } from 'react';

import HttpContext from 'utils/contexts/HttpContext';

import { TIMEZONES } from 'constants.js';
import { _commodity } from 'std';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import DatePicker from 'components/DateTimePickersTz/DatePicker';
import useDatePicker from 'components/DateTimePickersTz/hooks/useDatePicker';

const styles = theme => ({
    badgeOk: {
        backgroundColor: colors.green[500],
        color: 'white'
    },
    badgeError: {
        backgroundColor: colors.red[500],
        color: 'white'
    }
});

const frequency_to_text = {
    0: 'Only Once',
    7: 'Weekly',
    14: 'Every 2 Weeks',
    21: 'Every 3 Weeks',
    28: 'Every 4 Weeks',
    35: 'Every 5 Weeks'
};
function OTCLocationTable(props) {
    const { theme, operator, onSnackbar, google, setGroupFilter, groupFilter } = props;

    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState(null);
    const [activeOrder, setActiveOrder] = useState('asc');
    const [showBookingDIalog, setShowBookingDialog] = useState(false);

    const OTClocationEndpoints = {
        getEndPoint: '/getAllExternalLocations',
        getEditEndPoint: _id => `/updateExternalLocation/${_id}`, // url to edit documents NOTE: this should be a function
        createEndPoint: '/createExternalLocation' // url to add new documents
    };
    const {
        data: allOTCLocations,
        otherStats,
        startDateFilterStats,
        endDateFilterStats,
        loading,
        setLoading,
        setStartDateFilterStats,
        setEndDateFilterStats,
        dateFilterStatsErrorMessage,
        handleCreate,
        handleEdit,
        handleReloadData
    } = useCRUD({
        endpoints: OTClocationEndpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });
    let filteredOTCLocations = allOTCLocations;

    const OTCGroupEndpoints = {
        getEndPoint: '/getAllExternalGroups',
        getEditEndPoint: _id => `/updateExternalGroup/${_id}`, // url to edit documents NOTE: this should be a function
        createEndPoint: '/createExternalGroup' // url to add new documents
    };

    const { data: allOTCGroups } = useCRUD({
        endpoints: OTCGroupEndpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });

    const columns = [
        {
            key: 'name',
            header: 'Name'
        },
        { key: 'location.description', header: 'Address' },
        { key: 'group.name', header: 'Group' },
        {
            key: 'frequency',
            header: 'Frequency',
            formatValue: value => frequency_to_text[value]
        },
        {
            key: 'pickup.date',
            header: 'Next Date',
            formatValue: (value, rowData) =>
                value && _.isNil(_.get(rowData, 'pickup.completionDate', null))
                    ? moment(value)
                          .tz(process.env.REACT_APP_REGION_TIMEZONE)
                          .format('MMM D, YYYY')
                    : 'N/A'
        },
        { key: 'accessCode', header: 'Access Code' }
    ];

    const defaultColumnFilters = columns.map(c => c.key);
    const editForm = (
        <OTCLocationForm
            OTCLocation={selectedDocument}
            allOTCLocations={allOTCLocations}
            allOTCGroups={allOTCGroups}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
            loading={loading}
            editing={!_.isNil(selectedDocument)}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            operator={operator}
            http={http}
            google={google}
        />
    );
    const onEditRow = document => {
        setSelectedDocument(document);
        setEditDialogOpen(true);
    };

    async function bookPickup(OTCLocation, date) {
        const res = await http.post(
            '/stops/create',
            {
                date,
                stopName: _.get(OTCLocation, 'name', ''),
                location: _.get(OTCLocation, 'location', ''),
                comment: _.get(OTCLocation, 'description', ''),
                stopDuration: _.get(OTCLocation, 'stopDuration', 600),
                stopPayAmount: 0,
                frequency: _.get(OTCLocation, 'frequency', 0),
                stopIcon: _.get(OTCLocation, 'group.defaultIcon', 'truck'),
                stopIconColor: _.get(OTCLocation, 'group.defaultColor', 'black'),
                OTCLocationId: _.get(OTCLocation, '_id', '')
            },
            true
        );
        if (res.ok) {
            onSnackbar('Pickup booked for the location ' + _.get(OTCLocation, 'name', ''));
            setShowBookingDialog(false);
            handleReloadData();
        }
    }
    const handleDeleteStop = async (pickup_id, OTCLocationId) => {
        const res = await http.post(`/stops/${pickup_id}/delete`, { OTCLocationId: OTCLocationId });
        if (res.ok) {
            onSnackbar('Pickup cancelled');
            handleReloadData();
        }
    };
    const handleDeleteOTCLocation = async OTCLocationId => {
        const res = await http.post(`/deleteExternalLocation`, { _id: OTCLocationId });
        if (res.ok) {
            onSnackbar('Location Removed');
            handleReloadData();
        }
    };

    const renderExtraActions = document => {
        return (
            <>
                <Tooltip title="Edit">
                    <IconButton
                        data-cy={`edit-button`}
                        onClick={e => {
                            onEditRow(document);
                        }}
                    >
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                {_.isNil(_.get(document, 'pickup._id')) ||
                (!_.isNil(_.get(document, 'pickup._id')) && !_.isNil(_.get(document, 'pickup.completionDate'))) ? (
                    <Tooltip title="Book Pickup">
                        <IconButton
                            data-cy={`edit-button`}
                            onClick={e => {
                                setSelectedDocument(document);
                                setShowBookingDialog(true);
                            }}
                        >
                            <Icon>local_shipping</Icon>
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title="Cancel Pickup">
                        <IconButton
                            data-cy={`edit-button`}
                            onClick={e => {
                                warnAction(() => {
                                    handleDeleteStop(_.get(document, 'pickup._id'), _.get(document, '_id'));
                                }, 'Are you sure you want to cancel this pickup?');
                            }}
                        >
                            <Icon>cancel</Icon>
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title="Delete">
                    <IconButton
                        data-cy={`edit-button`}
                        onClick={e => {
                            if (!_.isNil(_.get(document, 'pickup._id'))) {
                                onSnackbar('Cannot delete locations with existing pickup booking', 'error');
                                return;
                            }
                            warnAction(() => {
                                handleDeleteOTCLocation(_.get(document, '_id'));
                            }, 'Are you sure you want to delete this location?');
                        }}
                    >
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    let headerContent = null;
    if (!_.isNil(groupFilter) && !_.isEmpty(groupFilter)) {
        const filterGroupObj = allOTCGroups.find(g => {
            return g._id === groupFilter;
        });
        headerContent = (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: 35 }}>
                <Icon style={{ color: 'orange', fontSize: 30 }}>error</Icon>
                <Typography style={{ color: theme.palette.primary.main, marginLeft: 10 }}>
                    Currently filtering for {_.get(filterGroupObj, 'name')}
                </Typography>
                <Tooltip title="Remove filter">
                    <IconButton
                        style={{ padding: 0, marginLeft: 5 }}
                        onClick={() => {
                            setGroupFilter('');
                        }}
                    >
                        <Icon style={{ fontSize: 20 }}>cancel</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        );
        filteredOTCLocations = allOTCLocations.filter(l => {
            return _.get(l, 'group._id') === groupFilter;
        });
    }

    return (
        <>
            <BookingDialog
                open={showBookingDIalog}
                onClose={() => {
                    setShowBookingDialog(false);
                }}
                onSubmit={date => {
                    bookPickup(selectedDocument, date);
                }}
            />
            <CRUDTable
                headerContent={headerContent}
                operator={operator}
                columns={columns}
                data={filteredOTCLocations}
                editForm={editForm}
                documentIsDisabled={g => _.get(g, 'disabled', false)}
                defaultRowsPerPage={10}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'OTC Locations'}
                loading={loading}
                renderExtraActions={renderExtraActions}
                hideDateFilters
                defaultColumnFilters={defaultColumnFilters}
                hideEditButton={true}
                hideViewJSON={true}
                showEmptyTable
            />
        </>
    );
}

export default withStyles(styles)(withTheme()(OTCLocationTable));

function BookingDialog({ open, onClose, onSubmit }) {
    const { date, timezone, handleChangeDate, handleChangeTimezone } = useDatePicker({
        saveStateInURL: false,
        timezones: TIMEZONES
    });
    return (
        <Dialog open={open}>
            <DialogTitle style={{ paddingBottom: 0 }}>Select Pickup Date</DialogTitle>
            <DialogContent style={{ paddingBottom: 10 }}>
                <div style={{ marginTop: 10 }}>
                    <DatePicker
                        timezone={process.env.REACT_APP_REGION_TIMEZONE}
                        data-cy="pickup-date-select"
                        autoOk
                        label="Pickup Date"
                        name="date"
                        value={date}
                        availableDates={getNext60Days(process.env.REACT_APP_REGION_TIMEZONE)}
                        variant="outlined"
                        onChange={handleChangeDate}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>date_range</Icon>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    type="submit"
                    color="primary"
                    data-cy="submit"
                    onClick={() => {
                        onSubmit(date);
                    }}
                >
                    submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function getNext60Days(timezone) {
    return _.range(0, 59).map(daysToAdd =>
        moment()
            .tz(timezone)
            .startOf('day')
            .add(daysToAdd, 'days')
            .toDate()
    );
}

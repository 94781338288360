import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { _bulk } from 'std';

import { getCustomerName, truncateBagtag, formatAsCurrency } from 'utils/misc';

// npm components
import * as colors from '@material-ui/core/colors';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withTheme } from '@material-ui/core/styles';

import { Menu, ListItem, Avatar, Tooltip, Icon, MenuItem } from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Icon as MDIcon } from '@mdi/react';
import { mdiEye, mdiAlertOutline, mdiAlertDecagram, mdiArrowDownDropCircle } from '@mdi/js';

import bulkHelper from 'helpers/bulkHelper';
import { Typography, IconButton } from '@material-ui/core';
import { LABEL_ICONS } from 'constants.js';
import useWindowSize from 'utils/hooks/useWindowSize';
import { isCONRegion, isEXPRegion } from 'utils/misc';

function HistoryListItem({ theme, key, historyItem }) {
    const [width, height] = useWindowSize();

    const [expandBagtags, setExpandBagtags] = useState(false);

    const [labelType, setLabelType] = useState('printed');
    const [labels, setLabels] = useState([]);

    useEffect(() => {
        const printedLabels = _.get(historyItem, 'data.printedLabels', []);
        const labelType = !_.isEmpty(printedLabels) ? 'printed' : 'scanned';

        let labels;
        if (labelType === 'printed') {
            labels = _.get(historyItem, 'data.printedLabels', []);
        } else {
            labels = _.get(historyItem, 'data.scannedLabels', []);
        }

        setLabels(labels);
        setLabelType(labelType);
    }, [historyItem]);

    return (
        <ListItem
            key={key}
            style={{
                borderRadius: 4,
                border: `1px solid ${theme.palette.background.paper}`
            }}
        >
            <div
                style={{
                    display: 'inline-grid',
                    textAlign: 'center',
                    borderRight: `1px solid ${colors.grey[500]}`,
                    padding: theme.spacing.unit,
                    paddingLeft: 0,
                    marginRight: theme.spacing.unit
                }}
            >
                <Avatar style={{ backgroundColor: colors.blue[500] }}>
                    <MDIcon path={LABEL_ICONS[labelType]} size={1} color={theme.palette.background.paper} />
                </Avatar>
            </div>
            <div>
                <Typography variant="body2">
                    <CustomChip theme={theme} bgColor={colors.green[400]}>
                        <span data-cy="bulk-list-item-bulk-type">{_.capitalize(labelType)}</span>
                    </CustomChip>
                    {moment(historyItem.date).format('MMM D YYYY, h:mm A')}
                </Typography>
                <Typography variant="body2">
                    {`${labels.length} bagtags ${labelType} at ${_.get(historyItem, 'collector.name', 'N/A')}`}
                </Typography>
                {labels.length > 0 && (
                    <Typography
                        noWrap={!expandBagtags}
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                        variant="body2"
                    >
                        {_.capitalize(labelType)} bagtags:{' '}
                        <span
                            onClick={() => setExpandBagtags(!expandBagtags)}
                            style={{ color: theme.palette.linkColor, cursor: 'pointer' }}
                        >
                            {expandBagtags ? 'Collapse' : 'Show'}
                        </span>
                    </Typography>
                )}
                {expandBagtags && <Typography variant="body2">{labels.join(', ')}</Typography>}
            </div>
        </ListItem>
    );
}

export default withMobileDialog({ breakpoint: 'xs' })(withTheme()(HistoryListItem));

export const CustomChip = ({ theme, children, bgColor }) => {
    return (
        <span
            style={{
                background: bgColor,
                padding: '2px 4px',
                marginRight: 4,
                borderRadius: 4,
                marginBottom: theme.spacing.unit / 2
            }}
        >
            {children}
        </span>
    );
};

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import * as turf from '@turf/turf';
import { formatAsCurrency, deviceHelper, downloadObjectAsCSV, getCustomerName, getQuickDropName } from 'utils/misc';
import { RESIDENTIAL, COMMERCIAL, CONDO } from '../../../../constants';
import { DROPNGO, ADJUSTMENT, PICKUP, WALKIN } from '../../../../constants';
import { getClosestRegion } from 'utils/latlngFunctions';
import { _zone } from 'std';
import * as terms from 'localizations/terms';

import { colors } from '@material-ui/core';

const initialPickupTypes = [RESIDENTIAL, COMMERCIAL, CONDO];
const initialBulkTypes = [DROPNGO, ADJUSTMENT, PICKUP, WALKIN];

const trendLines = [
    { name: 'New Customers', displayName: 'New', dataKey: 'newCustomers', stroke: colors.pink[300], type: 'customer' },
    {
        name: 'Active Customers',
        displayName: 'Active',
        dataKey: 'activeCustomers',
        stroke: colors.deepOrange[300],
        type: 'customer'
    },
    {
        name: 'Deleted Customers',
        displayName: 'Deleted',
        dataKey: 'deletedCustomers',
        stroke: colors.amber[300],
        type: 'customer'
    },
    {
        name: 'Disabled Customers',
        displayName: 'Disabled',
        dataKey: 'disabledCustomers',
        stroke: colors.green[300],
        type: 'customer'
    },

    {
        name: 'Containers [Residential]',
        displayName: 'Residential',
        dataKey: 'containersResidential',
        stroke: colors.teal[400],
        type: 'container'
    },
    {
        name: 'Containers [Commercial]',
        displayName: 'Commercial',
        dataKey: 'containerCommercial',
        stroke: colors.blue[400],
        type: 'container'
    },
    {
        name: 'Containers [Condo]',
        displayName: 'Condo',
        dataKey: 'containerCondo',
        stroke: colors.indigo[400],
        type: 'container'
    },
    {
        name: 'Containers [Adjustment]',
        displayName: 'Adjustment',
        dataKey: 'containerAdjustment',
        stroke: colors.purple[400],
        type: 'container'
    },
    {
        name: 'Containers [Drop&Go]',
        displayName: 'Drop&Go',
        dataKey: 'containerDropNGo',
        stroke: colors.pink[400],
        type: 'container'
    },
    {
        name: 'Containers [RVM]',
        displayName: 'RVM',
        dataKey: 'containerRVM',
        stroke: colors.blueGrey[400],
        type: 'container'
    },

    {
        name: 'Clothing (lbs)',
        displayName: 'Clothing (lbs)',
        dataKey: 'clothing',
        stroke: colors.deepOrange[500],
        type: 'commodity'
    },
    {
        name: 'Electronics (Items)',
        displayName: 'Electronics (Items)',
        dataKey: 'electronics',
        stroke: colors.amber[500],
        type: 'commodity'
    },
    {
        name: 'Sun Care (Items)',
        displayName: 'Sun Care (Items)',
        dataKey: 'sunCare',
        stroke: colors.green[500],
        type: 'commodity'
    },
    { name: 'Food (Items)', displayName: 'Food (Items)', dataKey: 'food', stroke: colors.teal[500], type: 'commodity' },
    {
        name: 'Beverage (Containers)',
        displayName: 'Beverage (Containers)',
        dataKey: 'containers',
        stroke: colors.blue[500],
        type: 'commodity'
    },
    {
        name: 'Synthetic (Containers)',
        displayName: 'Synthetic (Containers)',
        dataKey: 'syntheticContainers',
        stroke: colors.indigo[500],
        type: 'commodity'
    },

    {
        name: 'Containers Per Bag',
        displayName: 'Containers Per Bag',
        dataKey: 'containersPerBag',
        stroke: colors.purple[600],
        type: 'metrics'
    },
    {
        name: 'Containers Per Pickup',
        displayName: 'Containers Per Pickup',
        dataKey: 'containersPerPickup',
        stroke: colors.pink[600],
        type: 'metrics'
    },

    {
        name: 'Booked Pickups',
        displayName: 'Booked',
        dataKey: 'requestedPickups',
        stroke: colors.deepOrange[700],
        type: 'pickup'
    },
    {
        name: 'Completed Pickups',
        displayName: 'Completed',
        dataKey: 'completedPickups',
        stroke: colors.amber[700],
        type: 'pickup'
    },
    {
        name: 'Aborted Pickups',
        displayName: 'Aborted',
        dataKey: 'abortedPickups',
        stroke: colors.green[700],
        type: 'pickup'
    },
    {
        name: 'Completed Residential Pickups',
        displayName: 'Completed Residential',
        dataKey: 'completedResidentialPickups',
        stroke: colors.teal[700],
        type: 'pickup'
    },
    {
        name: 'Completed Condo Pickups',
        displayName: 'Completed Condo',
        dataKey: 'completedCondoPickups',
        stroke: colors.blue[700],
        type: 'pickup'
    },
    {
        name: 'Completed Commercial Pickups',
        displayName: 'Completed Commercial',
        dataKey: 'completedCommercialPickups',
        stroke: colors.indigo[700],
        type: 'pickup'
    },

    // { name: 'Completed OOS', dataKey: 'completedOOS', stroke: colors.teal[300] },
    {
        name: 'Depot Revenue (dollar)',
        displayName: 'Depot Revenue (dollar)',
        dataKey: 'depotRevenue',
        stroke: colors.purple[800],
        type: 'finance'
    },
    {
        name: 'Depot Cost (dollar)',
        displayName: 'Depot Cost (dollar)',
        dataKey: 'depotCost',
        stroke: colors.pink[800],
        type: 'finance'
    },
    {
        name: 'Depot Profit (dollar)',
        displayName: 'Depot Profit (dollar)',
        dataKey: 'depotProfit',
        stroke: colors.deepOrange[800],
        type: 'finance'
    },
    {
        name: 'System Revenue (dollar)',
        displayName: 'System Revenue (dollar)',
        dataKey: 'stdRevenue',
        stroke: colors.amber[800],
        type: 'finance'
    },
    {
        name: 'System Cost (dollar)',
        displayName: 'System Cost (dollar)',
        dataKey: 'stdCost',
        stroke: colors.green[800],
        type: 'finance'
    },
    {
        name: 'System Profit (dollar)',
        displayName: 'System Profit (dollar)',
        dataKey: 'stdProfit',
        stroke: colors.teal[800],
        type: 'finance'
    }
];

const useTrends = ({ http, collectors, startDate, endDate, regions, onSnackbar }) => {
    const [loading, setLoading] = useState(false);
    const [chartCount, setChartCount] = useState(0);

    const [collectorsSelected, setCollectorsSelected] = useState(collectors.map(c => c._id));
    const [zonesWithRegion, setZonesWithRegion] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);

    const [chartStats, setChartStats] = useState([]);
    const [chartStatsList, setChartStatsList] = useState([]);
    const [visibleTrendKeys, setVisibleTrendKeys] = useState(trendLines.map(line => line.dataKey));

    const [chartTitle, setChartTitle] = useState('');
    const [size, setSize] = useState(12);
    const [height, setHeight] = useState(700);
    const [chartInterval, setChartInterval] = useState('WEEK');
    const [bulkTypesSelected, setBulkTypesSelected] = useState(initialBulkTypes);
    const [pickupTypesSelected, setPickupTypesSelected] = useState(initialPickupTypes);
    const [zonesSelected, setZonesSelected] = useState([]);
    const [provincesSelected, setProvincesSelected] = useState(['Alberta']);
    const [citiesSelected, setCitiesSelected] = useState(['Calgary']);
    const [regionsSelected, setRegionsSelected] = useState(regions.map(region => region._id));

    const [allDrivers, setAllDrivers] = useState([]);
    const [clerks, setClerks] = useState([]);
    const [driversSelected, setDriversSelected] = useState([]);
    const [clerksSelected, setClerksSelected] = useState([]);
    const [allCollectorAdmins, setAllCollectorAdmins] = useState([]);
    const [collectorAdminsSelected, setCollectorAdminsSelected] = useState([]);

    const [addressKeys, setAddressKeys] = useState('');
    const [isCollectorChanged, setIsCollectorChanged] = useState(false);
    const [isProvinceChanged, setIsProvinceChanged] = useState(false);
    
    const fetchTrends = async () => {
        const res = await http.getJSON(`/trends`);
        if (res.ok) {
            setChartStatsList(res.data.trends.reverse());
        } else {
            onSnackbar('Error fetching trends', 'error');
        }
    };

    const handleTrend = async (_id = null) => {
        setLoading(true);

        const body = {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            interval: chartInterval,
            collectors: collectorsSelected,
            bulkTypes: bulkTypesSelected,
            pickupTypes: pickupTypesSelected,
            zones: zonesSelected,
            drivers: driversSelected,
            clerks: clerksSelected,
            collectorAdmins: collectorAdminsSelected,
            addressKeys: addressKeys,
            cities: citiesSelected,
            provinces: provincesSelected,
            size,
            height,
            title: chartTitle,
            visibleTrendKeys
        };
        if (body.provinces.includes('Québec') && !body.provinces.includes('Quebec')) {
            body.provinces.push('Quebec');
        }
        body.cities = body.cities.flatMap(city => {
            if (city.province === 'Québec') {
                return [city, { ...city, province: 'Quebec' }];
            } else {
                return [city];
            }
        });
        const res = _.isNil(_id)
            ? await http.postJSON('/trends/create', body)
            : await http.postJSON(`/trends/${_id}/update`, body);
        if (res.ok) {
            await fetchTrends();
        } else {
            onSnackbar(`Error ${_.isNil(_id) ? 'creating' : 'updating'} chart`, 'error');
        }
        setLoading(false);
    };

    const handleCollectorsSelected = input => {
        let value;
        
        // Check if input is an event or direct data
        if (input && input.target) {
            value = input.target.value; 
        } else {
            value = input; 
        }
        setCollectorsSelected(value);
        setIsCollectorChanged(prevState => !prevState);
    };

    const handleProvincesSelected = input => {
        let value;

        // Check if input is an event or direct data
        if (input && input.target) {
            value = input.target.value; 
        } else {
            value = input; 
        }
        setProvincesSelected(value); 
        setIsProvinceChanged(prevState => !prevState); 
    };

    const handleRegionsSelected = regionsSelected => {
        setRegionsSelected(regionsSelected);
        setZonesSelected(
            _.filter(zonesWithRegion, zone => _.some(zone.regions, region => regionsSelected.includes(region._id))).map(
                zone => zone._id
            )
        );
    };

    const handleVisibleTrendKeys = trendKeys => {
        setVisibleTrendKeys(trendKeys);
    };

    const handleFiltersForm = chart => {
        setChartTitle(chart.title);
        setCollectorsSelected(chart.collectorsSelected);
        setCollectorAdminsSelected(chart.collectorAdminsSelected);
        setDriversSelected(chart.driversSelected);
        setClerksSelected(chart.clerksSelected);
        setZonesSelected(chart.zonesSelected);
        setProvincesSelected(chart.provincesSelected);
        setCitiesSelected(chart.citiesSelected);
        setSize(chart.size);
        setHeight(chart.height);
        setAddressKeys(chart.addressKeys.join(','));
    };

    const handleRefresh = async _id => {
        const chart = chartStatsList.find(trend => trend._id === _id);
        setLoading(true);

        const body = {
            startDate: chart.startDate,
            endDate: chart.endDate,
            interval: chart.chartInterval,
            collectors: chart.collectorsSelected,
            bulkTypes: bulkTypesSelected,
            pickupTypes: pickupTypesSelected,
            zones: chart.zonesSelected,
            drivers: chart.driversSelected,
            clerks: chart.clerksSelected,
            collectorAdmins: chart.collectorAdminsSelected,
            addressKeys: chart.addressKeys.join(','),
            cities: chart.citiesSelected,
            provinces: chart.provincesSelected,
            size: chart.size,
            height: chart.height,
            title: chart.title,
            visibleTrendKeys: chart.visibleTrendKeys
        };

        const res = await http.postJSON(`/trends/${_id}/update`, body);
        if (res.ok) {
            await fetchTrends();
        } else {
            onSnackbar(`Error refreshing chart data`, 'error');
        }
        setLoading(false);
    };

    const handleRemoveChart = async _id => {
        setLoading(true);
        const res = await http.post(`/trends/${_id}/delete`, {});
        if (res.ok) {
            fetchTrends();
        } else {
            onSnackbar('Error deleting chart', 'error');
        }

        setLoading(false);
    };

    const handleDownload = async _id => {
        const trendNamesByKey = {};
        trendLines.forEach(trend => {
            const key = trend.dataKey;
            const name = trend.name;

            trendNamesByKey[key] = name;
        });

        const chartStatsByIndex = chartStatsList.find(trend => trend._id === _id);

        const breakdown = chartStatsByIndex.data.map(data => {
            let updatedLine = {};

            // Update data keys to use trend names
            const dataKeys = Object.keys(data);
            dataKeys.forEach(key => {
                if (trendNamesByKey[key]) {
                    const name = trendNamesByKey[key];
                    updatedLine[name] = data[key];
                } else if (key === 'label') {
                    updatedLine['label'] = data['label'];
                }
            });

            if (chartStatsByIndex.chartInterval === 'WEEK') {
                updatedLine = {
                    period: 'Week Of ' + updatedLine.label,
                    ...updatedLine
                };
            } else {
                updatedLine = {
                    period: updatedLine.label,
                    ...updatedLine
                };
            }

            return _.omit(updatedLine, ['_id', 'label']);
        });

        const date = moment().format('YYYY-MM-DD HH:mm');
        const fileName = `trends_${date}_${chartStatsByIndex.title}`;

        try {
            await downloadObjectAsCSV(breakdown, fileName);
            if (deviceHelper.isNativeApp()) {
                onSnackbar(`${fileName} saved to documents folder`);
            }
        } catch (err) {
            onSnackbar(`Cannot download file`, 'error');
        }
    };

    useEffect(() => {
        (async () => {
            const res = await http.getJSON('/zone/allZones');
            if (res.ok) {
                let zones = _.get(res, 'data.zones', []);
                populateRegionOnZones(zones, regions);
                setZonesWithRegion(zones);
                setZonesSelected(zones.map(zone => zone._id));
            }
        })();

        (async () => {
            const res = await http.getJSON('/users/getAllDrivers');
            if (res.ok) {
                let drivers = _.get(res, 'data.drivers', []);
                setAllDrivers(drivers);
                setDriversSelected(drivers.map(driver => driver._id));
            }
        })();

        (async () => {
            const res = await http.getJSON('/users/getAllClerks');
            if (res.ok) {
                let clerks = _.get(res, 'data.clerks', []);
                setClerks(clerks);
                setClerksSelected(clerks.map(clerk => clerk._id));
            }
        })();

        (async () => {
            const res = await http.getJSON('/users/getAllCollectorAdmins');
            if (res.ok) {
                let newCollectorAdmins = _.get(res, 'data.collectorAdmins', []);
                setAllCollectorAdmins(newCollectorAdmins);
                setCollectorAdminsSelected(newCollectorAdmins.map(user => user._id));
            }
        })();

        (async () => {
            const res = await http.getJSON('/system/cities');
            if (res.ok) {
                let filteredCities = _.get(res, 'data.cities', []).filter(item => item.province !== 'Quebec');

                _.get(res, 'data.cities', []).forEach(item => {
                    if (item.province === 'Quebec') {
                        const existsInFiltered = filteredCities.some(
                            city => city.name === item.name && city.province === 'Québec'
                        );

                        if (!existsInFiltered) {
                            filteredCities.push({
                                name: item.name,
                                province: 'Québec'
                            });
                        }
                    }
                });
                let filteredProvinces = _.get(res, 'data.provinces', []).filter(province => province !== 'Quebec');
                setProvinces(filteredProvinces);
                setProvincesSelected(filteredProvinces);
                setCities(filteredCities);
                setCitiesSelected(filteredCities.map(n => n.name));
            }
        })();
    }, []);

    useEffect(() => {
        setClerksSelected(
            _(clerks)
                .filter(clerk => collectorsSelected.includes(clerk.collector._id))
                .map(clerk => clerk._id)
                .value()
        );
        setDriversSelected(
            _(allDrivers)
                .filter(driver => collectorsSelected.includes(driver.collector._id))
                .map(driver => driver._id)
                .value()
        );
        setCollectorAdminsSelected(
            _(allCollectorAdmins)
                .filter(user => collectorsSelected.includes(user.collector._id))
                .map(user => user._id)
                .value()
        );
    }, [isCollectorChanged]);

    useEffect(() => {
        let newCitiesSelected = _.filter(cities, c => provincesSelected.includes(c.province)).map(c => c.name);
        if (citiesSelected.includes('')) {
            newCitiesSelected.push('');
        }
        setCitiesSelected(newCitiesSelected);
    }, [isProvinceChanged]);

    return {
        loading,
        addressKeys,
        visibleTrendKeys,
        trendLines,
        collectorsSelected,
        handleTrend,
        fetchTrends,
        chartStats,
        chartStatsList,
        chartInterval,
        bulkTypesSelected,
        pickupTypesSelected,
        zonesWithRegion,
        zonesSelected,
        provinces,
        cities,
        citiesSelected,
        provincesSelected,
        regionsSelected,
        allDrivers,
        clerks,
        driversSelected,
        clerksSelected,
        allCollectorAdmins,
        collectorAdminsSelected,
        handleCollectorsSelected,
        handleProvincesSelected,
        setChartInterval,
        setBulkTypesSelected,
        setPickupTypesSelected,
        setZonesSelected,
        setCitiesSelected,
        setProvincesSelected,
        handleRegionsSelected,
        setDriversSelected,
        setClerksSelected,
        setCollectorAdminsSelected,
        handleVisibleTrendKeys,
        handleDownload,
        handleRemoveChart,
        setAddressKeys,
        chartTitle,
        setChartTitle,
        handleFiltersForm,
        handleRefresh,
        size,
        setSize,
        height,
        setHeight
    };
};

export default useTrends;

function populateLabels(data, interval) {
    let dateFormat = '';
    if (interval === 'DAY' || interval === 'WEEK') {
        dateFormat = 'MMM D';
    } else {
        dateFormat = 'MMM YYYY';
    }
    const dataCopy = _.cloneDeep(data);
    dataCopy.forEach(d => {
        d.label = moment(d._id)
            .tz(process.env.REACT_APP_REGION_TIMEZONE)
            .format(dateFormat);
    });
    return dataCopy;
}

function populateRegionOnZones(zones, regions) {
    //Note: some zones contain multiple regions and some regions multiple zones
    // zones.forEach(zone => {
    //     zone.regions = [];
    //     regions.forEach(region => {
    //         if (_zone.isLatLngWithinZone(zone, region.lat, region.lng)) {
    //             zone.regions.push(region);
    //         }
    //     });
    // });
    regions.forEach(region => {
        let regionPoint = turf.point([parseFloat(region.lng), parseFloat(region.lat)]);
        zones.forEach(zone => {
            zone.regions = [];
            let overlap = false;
            zone.bounds.forEach(b => {
                const point = turf.point([parseFloat(b.lng), parseFloat(b.lat)]);
                let distance = turf.distance(regionPoint, point, { units: 'kilometers' });
                if (distance <= region.rad) {
                    overlap = true;
                }
            });
            if (overlap) {
                zone.regions.push(region);
            }
        });
    });
    zones.forEach(zone => {
        //if no regions contained in the zone use the closes region obtained by the zones center point
        if (_.isEmpty(zone.regions)) {
            const points = turf.points(zone.bounds.map(({ lat, lng }) => [lat, lng]));
            const centerCoords = turf.center(points).geometry.coordinates;

            zone.regions = [getClosestRegion({ lat: centerCoords[0], lng: centerCoords[1] }, regions)];
        }
    });
}

import React, { useState, useContext } from 'react';
import { withTheme, Typography, Paper, Divider, Avatar, Collapse, IconButton, Icon } from '@material-ui/core';
import { mdiAlert } from '@mdi/js';
import LocalizationContext from 'utils/contexts/LocalizationContext';

import { loc } from '../../localizations/localizationHandler';
import { isCONRegion } from '../../utils/misc';

const DIAMETER = 48;

const DriverCodeWidget = ({ theme, driverCode }) => {
    const { lang } = useContext(LocalizationContext);
    const [collapsed, setCollapsed] = useState(false);

    return (
        <Paper style={{ padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px` }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
                    {driverCode ? (
                        <Avatar
                            style={{
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.secondary.contrastText,
                                height: DIAMETER,
                                width: DIAMETER,
                                fontSize: theme.typography.display1.fontSize
                            }}
                        >
                            {driverCode}
                        </Avatar>
                    ) : (
                        <Icon path={mdiAlert} size={3} color={theme.palette.error.main} />
                    )}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="h6" style={{ marginLeft: 10 }}>
                            {loc('driverCodeTitle', lang)} <strong>{driverCode}</strong>
                        </Typography>
                    </div>
                </div>
                <IconButton onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? <Icon size={0.75}>expand_less</Icon> : <Icon size={0.75}>expand_more</Icon>}
                </IconButton>
            </div>
            <Collapse in={collapsed}>
                <Divider />
                <div
                    style={{
                        marginTop: theme.spacing.unit,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Typography style={{ marginLeft: theme.spacing.unit * 2 }}>
                        {driverCode ? (
                            <>
                                To save time, only use the customer 4 digit code if the bags are pre-labeled and secure.
                                Otherwise use your <strong>driver code + next available number</strong>. Boxes are
                                accepted! To save time, place boxes directly into{' '}
                                {process.env.REACT_APP_REGION_EXT === 'STD' ? 'skip bags' : 'bags'} and move multiple
                                boxes at the same time.
                            </>
                        ) : (
                            `Please contact ${process.env.REACT_APP_BRAND_NAME} for your Driver Code`
                        )}
                    </Typography>
                </div>
            </Collapse>
        </Paper>
    );
};

export default withTheme()(DriverCodeWidget);

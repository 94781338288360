import React, { useContext } from 'react';
import moment from 'moment-timezone';

import _ from 'lodash';

import { Icon, withTheme, Typography, Paper, Divider, Avatar, Link, CircularProgress } from '@material-ui/core';

import { List, ListItem, ListItemText } from '@material-ui/core';
import { loc } from '../../localizations/localizationHandler';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import { isCONRegion } from '../../utils/misc';

function ComplimentsWidget(props) {
    const { lang } = useContext(LocalizationContext);

    const { operator, pickupsWithCompliments, theme, loading, noPadding, style } = props;

    let allCompliments = (pickupsWithCompliments || []).map(pickup => {
        //currently no way to resolve pickup compliments, display as resolved by default
        return {
            complimentId: pickup._id,
            complimentItemType: 'pickup',
            date: pickup.completionDate,
            unresolved: false,
            complimentDescription: pickup.customerCompliment.complimentDescription
        };
    });

    //sort by unresolved/resolved then by date
    allCompliments.sort((a, b) => {
        if (a.date > b.date) {
            return -1;
        } else if (a.date < b.date) {
            return 1;
        } else {
            return 0;
        }
    });

    return (
        <div style={{ padding: noPadding ? 0 : theme.spacing.unit }}>
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: theme.spacing.unit,
                        marginBottom: theme.spacing.unit
                    }}
                >
                    <CircularProgress />
                </div>
            ) : _.isEmpty(allCompliments) ? (
                <Typography style={{ margin: theme.spacing.unit * 2 }}>{loc('noCompliments', lang)}</Typography>
            ) : (
                <List dense style={{ maxHeight: 200, overflowY: 'scroll' }}>
                    {allCompliments.map(compliment => (
                        <ListItem>
                            <Avatar style={{ backgroundColor: theme.palette.primary.main }}>
                                <Icon>favorite</Icon>
                            </Avatar>
                            <ListItemText
                                style={{ fontSize: '0.875rem' }}
                                primary={`"${compliment.complimentDescription}"`}
                                secondary={`Pickup compliment - ${moment(compliment.date).format('MMM DD, YYYY')}`}
                            />
                        </ListItem>
                    ))}
                </List>
            )}
        </div>
    );
}

export default withTheme()(ComplimentsWidget);

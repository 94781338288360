import { createMuiTheme } from '@material-ui/core/styles';

import _ from 'lodash';
import { colors } from '@material-ui/core';
import { deviceHelper, isAUSRegion, isEXPRegion, isSTDRegion } from 'utils/misc';
import { StatusBar, StatusBarStyle } from '@capacitor/status-bar';

// custom theme colors for AUS
const GREEN_THEME_COLORS = {
    50: '#78B526',
    100: '#78B526',
    200: '#78B526',
    300: '#78B526',
    400: '#78B526',
    500: '#78B526',
    600: '#78B526',
    700: '#78B526',
    800: '#78B526',
    900: '#78B526',
    A100: '#78B526',
    A200: '#78B526',
    A400: '#78B526',
    A700: '#78B526',
    main: '#78B526',
    light: '#78B526',
    dark: '#78B526'
};

// custom theme colors for EXP
const EXPRESS_BLUE_THEME_COLORS = {
    50: '#0033A0',
    100: '#0033A0',
    200: '#0033A0',
    300: '#0033A0',
    400: '#0033A0',
    500: '#0033A0',
    600: '#0033A0',
    700: '#0033A0',
    800: '#0033A0',
    900: '#0033A0',
    A100: '#0033A0',
    A200: '#0033A0',
    A400: '#0033A0',
    A700: '#0033A0',
    main: '#0033A0',
    light: '#0033A0',
    dark: '#0033A0'
};

const EXPRESS_LIGHT_BLUE_THEME_COLORS = {
    50: '#93ACFF',
    100: '#93ACFF',
    200: '#93ACFF',
    300: '#93ACFF',
    400: '#93ACFF',
    500: '#93ACFF',
    600: '#93ACFF',
    700: '#93ACFF',
    800: '#93ACFF',
    900: '#93ACFF',
    A100: '#93ACFF',
    A200: '#93ACFF',
    A400: '#93ACFF',
    A700: '#93ACFF',
    main: '#93ACFF',
    light: '#93ACFF',
    dark: '#93ACFF'
};

// custom theme colors for EXP
const EXPRESS_YELLOW_THEME_COLORS = {
    50: '#FFA300',
    100: '#FFA300',
    200: '#FFA300',
    300: '#FFA300',
    400: '#FFA300',
    500: '#FFA300',
    600: '#FFA300',
    700: '#FFA300',
    800: '#FFA300',
    900: '#FFA300',
    A100: '#FFA300',
    A200: '#FFA300',
    A400: '#FFA300',
    A700: '#FFA300',
    main: '#FFA300',
    light: '#FFA300',
    dark: '#FFA300'
};

// custom theme colors for MXD
const MXD_DARK_BLUE_THEME_COLORS = {
    50: '#392d79',
    100: '#392d79',
    200: '#392d79',
    300: '#392d79',
    400: '#392d79',
    500: '#392d79',
    600: '#392d79',
    700: '#392d79',
    800: '#392d79',
    900: '#392d79',
    A100: '#392d79',
    A200: '#392d79',
    A400: '#392d79',
    A700: '#392d79',
    main: '#392d79',
    light: '#392d79',
    dark: '#392d79'
};

// custom theme colors for MXD
const MXD_GREY_THEME_COLORS = {
    50: '#e6e6e6',
    100: '#e6e6e6',
    200: '#e6e6e6',
    300: '#e6e6e6',
    400: '#e6e6e6',
    500: '#e6e6e6',
    600: '#e6e6e6',
    700: '#e6e6e6',
    800: '#e6e6e6',
    900: '#e6e6e6',
    A100: '#e6e6e6',
    A200: '#e6e6e6',
    A400: '#e6e6e6',
    A700: '#e6e6e6',
    main: '#e6e6e6',
    light: '#e6e6e6',
    dark: '#e6e6e6'
};

// custom theme colors for MXD
const MXD_LIGHT_BLUE_THEME_COLORS = {
    50: '#566bba',
    100: '#566bba',
    200: '#566bba',
    300: '#566bba',
    400: '#566bba',
    500: '#566bba',
    600: '#566bba',
    700: '#566bba',
    800: '#566bba',
    900: '#566bba',
    A100: '#566bba',
    A200: '#566bba',
    A400: '#566bba',
    A700: '#566bba',
    main: '#566bba',
    light: '#566bba',
    dark: '#566bba'
};

// custom theme colors for EXP
const CON_PRIMARY_THEME_COLORS = {
    50: '#27A737',
    100: '#27A737',
    200: '#27A737',
    300: '#27A737',
    400: '#27A737',
    500: '#27A737',
    600: '#27A737',
    700: '#27A737',
    800: '#27A737',
    900: '#27A737',
    A100: '#27A737',
    A200: '#27A737',
    A400: '#27A737',
    A700: '#27A737',
    main: '#27A737',
    light: '#27A737',
    dark: '#27A737'
};

// custom theme colors for EXP
const CON_SECONDARY_THEME_COLORS = {
    50: '#bfac26',
    100: '#bfac26',
    200: '#bfac26',
    300: '#bfac26',
    400: '#bfac26',
    500: '#bfac26',
    600: '#bfac26',
    700: '#bfac26',
    800: '#bfac26',
    900: '#bfac26',
    A100: '#bfac26',
    A200: '#bfac26',
    A400: '#bfac26',
    A700: '#bfac26',
    main: '#bfac26',
    light: '#bfac26',
    dark: '#bfac26'
};

const CHARITY_RED = '#F44336';
const SCHEME_USER_PIN_COLOR = '#202f7e';


const ABD_PRIMARY_THEME_COLORS = generatePalette('#1f609a');
const ABD_SECONDARY_THEME_COLORS = generatePalette('#93cc3e');
const SAR_PRIMARY_THEME_COLORS = generatePalette('#092E47');
const SAR_SECONDARY_THEME_COLORS = generatePalette('#96AB97');

function generatePalette(color) {
    const keys = [
        50,
        100,
        200,
        300,
        400,
        500,
        600,
        700,
        800,
        900,
        'A100',
        'A200',
        'A300',
        'A300',
        'A700',
        'main',
        'light',
        'dark'
    ];
    const newPalette = {};

    for (let key of keys) {
        newPalette[key] = color;
    }

    return newPalette;
}

// environment themes
const GREY_ENV_THEME = { '500': '#454545', contrastText: '#FFFFFF' };

let envColor, primaryColor, secondaryColor, darkEnvColor, darkPrimaryColor, darkSecondaryColor;

switch (process.env.REACT_APP_ENV) {
    case 'PROD':
        switch (process.env.REACT_APP_REGION_EXT) {
            case 'AUS':
                GREEN_THEME_COLORS.contrastText = '#FFFFFF';

                envColor = GREY_ENV_THEME;
                primaryColor = GREEN_THEME_COLORS;
                secondaryColor = colors.blueGrey;

                darkEnvColor = GREY_ENV_THEME;
                darkPrimaryColor = GREEN_THEME_COLORS;
                darkSecondaryColor = colors.blueGrey;
                break;
            case 'EXP':
                envColor = EXPRESS_BLUE_THEME_COLORS;
                primaryColor = EXPRESS_BLUE_THEME_COLORS;
                secondaryColor = EXPRESS_YELLOW_THEME_COLORS;

                darkEnvColor = EXPRESS_LIGHT_BLUE_THEME_COLORS;
                darkPrimaryColor = EXPRESS_LIGHT_BLUE_THEME_COLORS;
                darkSecondaryColor = EXPRESS_YELLOW_THEME_COLORS;
                break;
            case 'MXD':
                envColor = MXD_DARK_BLUE_THEME_COLORS;
                primaryColor = MXD_DARK_BLUE_THEME_COLORS;
                secondaryColor = MXD_LIGHT_BLUE_THEME_COLORS;

                darkEnvColor = MXD_DARK_BLUE_THEME_COLORS;
                darkPrimaryColor = MXD_DARK_BLUE_THEME_COLORS;
                darkSecondaryColor = MXD_LIGHT_BLUE_THEME_COLORS;
                break;
            case 'CON':
                envColor = CON_PRIMARY_THEME_COLORS;
                primaryColor = CON_PRIMARY_THEME_COLORS;
                secondaryColor = CON_SECONDARY_THEME_COLORS;

                darkEnvColor = CON_PRIMARY_THEME_COLORS;
                darkPrimaryColor = CON_PRIMARY_THEME_COLORS;
                darkSecondaryColor = CON_SECONDARY_THEME_COLORS;
                break;
            default:
                envColor = colors.blue;
                primaryColor = colors.blue;
                secondaryColor = colors.pink;

                darkEnvColor = colors.blue;
                darkPrimaryColor = colors.blue;
                darkSecondaryColor = colors.pink;
        }
        break;
    case 'TEST':
    case 'TRAIN':
        switch (
            process.env.REACT_APP_REGION_EXT_DEMO || process.env.REACT_APP_REGION_EXT // demo overrides theme colors
        ) {
            case 'AUS':
                GREEN_THEME_COLORS.contrastText = '#FFFFFF';

                envColor = GREY_ENV_THEME;
                primaryColor = GREEN_THEME_COLORS;
                secondaryColor = colors.blueGrey;

                darkEnvColor = GREY_ENV_THEME;
                darkPrimaryColor = GREEN_THEME_COLORS;
                darkSecondaryColor = colors.blueGrey;
                break;
            case 'EXP':
                envColor = EXPRESS_BLUE_THEME_COLORS;
                primaryColor = EXPRESS_BLUE_THEME_COLORS;
                secondaryColor = EXPRESS_YELLOW_THEME_COLORS;

                darkEnvColor = EXPRESS_LIGHT_BLUE_THEME_COLORS;
                darkPrimaryColor = EXPRESS_LIGHT_BLUE_THEME_COLORS;
                darkSecondaryColor = EXPRESS_YELLOW_THEME_COLORS;
                break;
            case 'MXD':
                envColor = MXD_DARK_BLUE_THEME_COLORS;
                primaryColor = MXD_DARK_BLUE_THEME_COLORS;
                secondaryColor = MXD_LIGHT_BLUE_THEME_COLORS;

                darkEnvColor = MXD_DARK_BLUE_THEME_COLORS;
                darkPrimaryColor = MXD_DARK_BLUE_THEME_COLORS;
                darkSecondaryColor = MXD_LIGHT_BLUE_THEME_COLORS;
                break;
            case 'CON':
                envColor = CON_PRIMARY_THEME_COLORS;
                primaryColor = CON_PRIMARY_THEME_COLORS;
                secondaryColor = CON_SECONDARY_THEME_COLORS;

                darkEnvColor = CON_PRIMARY_THEME_COLORS;
                darkPrimaryColor = CON_PRIMARY_THEME_COLORS;
                darkSecondaryColor = CON_SECONDARY_THEME_COLORS;
                break;
            case 'ABD':
                envColor = ABD_PRIMARY_THEME_COLORS;
                primaryColor = ABD_PRIMARY_THEME_COLORS;
                secondaryColor = ABD_SECONDARY_THEME_COLORS;

                darkEnvColor = ABD_PRIMARY_THEME_COLORS;
                darkPrimaryColor = ABD_PRIMARY_THEME_COLORS;
                darkSecondaryColor = ABD_SECONDARY_THEME_COLORS;
                break;
            case 'SAR':
                envColor = SAR_PRIMARY_THEME_COLORS;
                primaryColor = SAR_PRIMARY_THEME_COLORS;
                secondaryColor = SAR_SECONDARY_THEME_COLORS;

                darkEnvColor = SAR_PRIMARY_THEME_COLORS;
                darkPrimaryColor = SAR_PRIMARY_THEME_COLORS;
                darkSecondaryColor = SAR_SECONDARY_THEME_COLORS;

                break;
            default:
                envColor = colors.deepOrange;
                primaryColor = colors.blue;
                secondaryColor = colors.pink;

                darkEnvColor = colors.deepOrange;
                darkPrimaryColor = colors.blue;
                darkSecondaryColor = colors.pink;
        }
        break;
    case 'LOCAL':
        switch (
            process.env.REACT_APP_REGION_EXT_DEMO || process.env.REACT_APP_REGION_EXT // demo overrides theme colors
        ) {
            case 'AUS':
                const updateColors = { '500': '#78B526' };
                updateColors.contrastText = '#FFFFFF';

                envColor = GREY_ENV_THEME;
                primaryColor = updateColors;
                secondaryColor = colors.blueGrey;

                darkEnvColor = GREY_ENV_THEME;
                darkPrimaryColor = updateColors;
                darkSecondaryColor = colors.blueGrey;
                break;
            case 'EXP':
                envColor = EXPRESS_BLUE_THEME_COLORS;
                primaryColor = EXPRESS_BLUE_THEME_COLORS;
                secondaryColor = EXPRESS_YELLOW_THEME_COLORS; // #faa31d

                darkEnvColor = EXPRESS_LIGHT_BLUE_THEME_COLORS;
                darkPrimaryColor = EXPRESS_LIGHT_BLUE_THEME_COLORS;
                darkSecondaryColor = EXPRESS_YELLOW_THEME_COLORS;
                break;
            case 'MXD':
                envColor = MXD_DARK_BLUE_THEME_COLORS;
                primaryColor = MXD_DARK_BLUE_THEME_COLORS;
                secondaryColor = MXD_LIGHT_BLUE_THEME_COLORS;

                darkEnvColor = MXD_DARK_BLUE_THEME_COLORS;
                darkPrimaryColor = MXD_DARK_BLUE_THEME_COLORS;
                darkSecondaryColor = MXD_LIGHT_BLUE_THEME_COLORS;
                break;
            case 'CON':
                envColor = CON_PRIMARY_THEME_COLORS;
                primaryColor = CON_PRIMARY_THEME_COLORS;
                secondaryColor = CON_SECONDARY_THEME_COLORS;

                darkEnvColor = CON_PRIMARY_THEME_COLORS;
                darkPrimaryColor = CON_PRIMARY_THEME_COLORS;
                darkSecondaryColor = CON_SECONDARY_THEME_COLORS;
                break;
            case 'ABD':
                envColor = ABD_PRIMARY_THEME_COLORS;
                primaryColor = ABD_PRIMARY_THEME_COLORS;
                secondaryColor = ABD_SECONDARY_THEME_COLORS;

                darkEnvColor = ABD_PRIMARY_THEME_COLORS;
                darkPrimaryColor = ABD_PRIMARY_THEME_COLORS;
                darkSecondaryColor = ABD_SECONDARY_THEME_COLORS;
                break;
            case 'SAR':
                envColor = SAR_PRIMARY_THEME_COLORS;
                primaryColor = SAR_PRIMARY_THEME_COLORS;
                secondaryColor = SAR_SECONDARY_THEME_COLORS;

                darkEnvColor = SAR_PRIMARY_THEME_COLORS;
                darkPrimaryColor = SAR_PRIMARY_THEME_COLORS;
                darkSecondaryColor = SAR_SECONDARY_THEME_COLORS;
                break;

            default:
                envColor = colors.blue;
                primaryColor = colors.blue;
                secondaryColor = colors.pink;

                darkEnvColor = colors.blue;
                darkPrimaryColor = colors.blue;
                darkSecondaryColor = colors.pink;
        }
        break;
    default:
        throw new Error('Critical error: REACT_APP_ENV is missing in your environment file.');
}

const ENV_COLOR = envColor;
const PRIMARY_COLOR = primaryColor;
const SECONDARY_COLOR = secondaryColor;

const PRIMARY_COLOR_ADMIN = colors.blueGrey;
const SECONDARY_COLOR_ADMIN = colors.blueGrey;

const DARK_ENV_COLOR = darkEnvColor;
const DARK_PRIMARY_COLOR = darkPrimaryColor;
const DARK_SECONDARY_COLOR = darkSecondaryColor;

const baseLightTheme = createMuiTheme({
    palette: {
        type: 'light',
        primary: PRIMARY_COLOR,
        secondary: SECONDARY_COLOR,
        envColor: ENV_COLOR,
        errorTextColor: colors.red[500]
    }
});

const baseDarkTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: DARK_PRIMARY_COLOR,
        secondary: DARK_SECONDARY_COLOR,
        envColor: DARK_ENV_COLOR,
        errorTextColor: colors.red[400]
    }
});

const themeSharedProps = {
    typography: {
        h6: {
            fontWeight: 300
        },
        useNextVariants: true, // TEMP
        suppressDeprecationWarnings: true // TEMP
    },
    overrides: {
        MuiDialog: {
            paper: {
                margin: 32
            }
        },
        MuiTooltip: {
            tooltip: {
                maxWidth: 'none'
            }
        }
    }
};

export const lightTheme = createMuiTheme(
    _.merge(
        {
            palette: {
                type: 'light',
                primary: PRIMARY_COLOR,
                secondary: SECONDARY_COLOR,
                linkColor: PRIMARY_COLOR[500],
                envColor: ENV_COLOR
            },
            overrides: {
                MuiButton: {
                    text: {
                        color: baseLightTheme.palette.text.secondary
                    }
                }
            }
        },
        themeSharedProps
    )
);

export const darkTheme = createMuiTheme(
    _.merge(
        {
            palette: {
                type: 'dark',
                primary: DARK_PRIMARY_COLOR,
                secondary: DARK_SECONDARY_COLOR,
                linkColor: DARK_PRIMARY_COLOR[300],
                envColor: DARK_ENV_COLOR
            },
            overrides: {
                MuiButton: {
                    text: {
                        color: baseDarkTheme.palette.text.secondary
                    }
                }
            }
        },
        themeSharedProps
    )
);

export const adminTheme = createMuiTheme(
    _.merge(
        {
            palette: {
                type: 'light',
                primary: PRIMARY_COLOR_ADMIN,
                secondary: SECONDARY_COLOR_ADMIN,
                envColor: PRIMARY_COLOR_ADMIN
            },
            overrides: {
                MuiButton: {
                    text: {
                        color: baseLightTheme.palette.text.secondary
                    }
                }
            }
        },
        themeSharedProps
    )
);

export const markerPinTheme = createMuiTheme({
    charityPin: CHARITY_RED,
    schemeUserPin: SCHEME_USER_PIN_COLOR,
    pinLabelStyle: isSTDRegion() || isAUSRegion() ? 'solid' : 'outline',
    technologyLogo: 'mdiTagArrowDown',
    noTechnologyLogo: 'mdiTagArrowDown'
});

if (deviceHelper.isNativeApp()) {
    StatusBar.setBackgroundColor({ color: `${envColor[500]}` });
    StatusBar.setStyle({ style: StatusBarStyle.Dark });
}

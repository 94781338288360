import React, { useContext } from 'react';

import _ from 'lodash';
import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';

import { withTheme, Typography, Paper, Divider, Grid, Icon } from '@material-ui/core';
import { formatAsCurrency, isCONRegion } from 'utils/misc';
import { DEFAULT_GST_RATE_STD } from 'constants.js';
import RankBadge from 'components/CustomIcons/RankBadge';

import LocalizationContext from 'utils/contexts/LocalizationContext';
import { _commodity } from 'std';
import { loc } from '../../localizations/localizationHandler';

const GST_RATE = process.env.REACT_APP_REGION_EXT === 'STD' ? DEFAULT_GST_RATE_STD : null;

function PickupRatingWidget(props) {
    const {
        theme,
        pickupRating,
        operator,
        driverFinancialInfo,
        driverRanking,
        isDriver,
        pickedUpCommodities,
        commodities,
        tipBalance,
        height
    } = props;

    const { lang } = useContext(LocalizationContext);

    const distancePerDayFormatted = isNaN(
        _.get(driverFinancialInfo, 'distanceDriven', 0) / _.get(driverFinancialInfo, 'datesWorking', []).length
    )
        ? 'N/A'
        : (
              _.get(driverFinancialInfo, 'distanceDriven', 0) / _.get(driverFinancialInfo, 'datesWorking', []).length
          ).toFixed(2);

    const pickupAccuracyFormatted = isNaN(pickupRating.goodPickups / pickupRating.totalPickups)
        ? 'N/A'
        : `${((pickupRating.goodPickups / pickupRating.totalPickups) * 100).toFixed(1)}%`;

    const timePerPickupFormatted =
        _.get(driverRanking, 'timePerPickup', 0) / 60 === 0
            ? 'N/A'
            : (_.get(driverRanking, 'timePerPickup', 0) / 60).toFixed(2);

    const timePerPickupRankFormatted =
        timePerPickupFormatted !== 'N/A' ? `${_.get(driverRanking, 'timePerPickupRank', 'N/A')}` : 'N/A';

    const timePerBagFormatted =
        _.get(driverRanking, 'timePerBag', 0) === 0 ? 'N/A' : (_.get(driverRanking, 'timePerBag', 0) / 60).toFixed(2);

    const timePerBagRankFormatted =
        timePerBagFormatted !== 'N/A' ? `${_.get(driverRanking, 'timePerBagRank', 'N/A')}` : 'N/A';

    const isPayThroughSystem = _.get(operator, 'permissions.payThroughSystem', false);
    const isCommission = isPayThroughSystem && _.get(operator, 'permissions.paymentModel', '') === 'Commission';

    const totalPay =
        _.get(driverFinancialInfo, 'totalPayment', 0) -
        _.get(driverFinancialInfo, 'gasCost', 0) +
        (isPayThroughSystem ? tipBalance : 0);
    const totalPayFormatted = isNaN(totalPay) ? 'N/A' : formatAsCurrency(totalPay);

    const averagePayFormatted =
        totalPayFormatted !== 'N/A' && _.get(driverFinancialInfo, 'hoursDriven', 0) > 0
            ? formatAsCurrency(totalPay / _.get(driverFinancialInfo, 'totalDuration', 0))
            : 'N/A';

    let totalGstFormatted;
    if (!_.isNil(GST_RATE) && isCommission) {
        totalGstFormatted = totalPayFormatted !== 'N/A' ? formatAsCurrency(totalPay * GST_RATE) : 'N/A';
    }

    const boxStyleFlex = {
        border: '2px solid rgba(0, 0, 0, 0.12)',
        margin: theme.spacing.unit,
        padding: theme.spacing.unit * 2,
        flex: `calc(20% - ${theme.spacing.unit * 2}px)`,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center'
    };

    return (
        <Paper style={{ padding: theme.spacing.unit * 2, height: height ? height : undefined }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBottom: theme.spacing.unit / 2
                }}
            >
                <Typography variant="h6">
                    {!isDriver && 'Weekly '}
                    {loc('pickupStats', lang)}
                </Typography>
            </div>
            <Divider />
            {isDriver && (
                <>
                    <Grid container>
                        <Grid container item xs justify="center" style={{ marginTop: theme.spacing.unit * 2 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{ textAlign: 'center', marginBottom: theme.spacing.unit }}
                                >
                                    {loc('timeSlashPickup', lang)}
                                    (mins)
                                </Typography>
                                <RankBadge value={timePerPickupRankFormatted} />
                                <Typography
                                    variant="h6"
                                    color="default"
                                    style={{
                                        fontWeight: 400,
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                        marginTop: theme.spacing.unit
                                    }}
                                >
                                    {timePerPickupFormatted}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid container item xs justify="center" style={{ marginTop: theme.spacing.unit * 2 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{ textAlign: 'center', marginBottom: theme.spacing.unit }}
                                >
                                    {loc('timeSlashBag', lang)}
                                    (mins)
                                </Typography>
                                <RankBadge value={timePerBagRankFormatted} />
                                <Typography
                                    variant="h6"
                                    color="default"
                                    style={{
                                        fontWeight: 400,
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                        marginTop: theme.spacing.unit
                                    }}
                                >
                                    {timePerBagFormatted}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>

                    <Divider style={{ margin: `${theme.spacing.unit * 2}px 0` }} />
                </>
            )}
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    justifyContent: 'center',
                    marginTop: !isDriver ? theme.spacing.unit * 2 : 'none'
                }}
            >
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        local_shipping
                    </Icon>
                    <Typography variant="h6" color="default" style={{ fontWeight: 400, marginTop: theme.spacing.unit }}>
                        {pickupRating.goodPickups}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {loc('pickup', lang)}
                    </Typography>
                </div>
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        check_circle
                    </Icon>
                    <Typography variant="h6" color="default" style={{ fontWeight: 400, marginTop: theme.spacing.unit }}>
                        {pickupRating.totalPickups}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {loc('complete', lang)}
                    </Typography>
                </div>
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        report_problem
                    </Icon>
                    <Typography variant="h6" color="default" style={{ fontWeight: 400, marginTop: theme.spacing.unit }}>
                        {pickupRating.totalPickups - pickupRating.goodPickups}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {loc('complaints', lang)}
                    </Typography>
                </div>
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        not_interested
                    </Icon>
                    <Typography variant="h6" color="default" style={{ fontWeight: 400, marginTop: theme.spacing.unit }}>
                        {pickupRating.abortedCount}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {loc('aborted', lang)}
                    </Typography>
                </div>
                {isDriver && (
                    <>
                        <div style={boxStyleFlex}>
                            <Icon color="primary" style={{ fontSize: '30px' }}>
                                data_usage
                            </Icon>
                            <Typography
                                variant="h6"
                                color="default"
                                style={{ fontWeight: 400, marginTop: theme.spacing.unit }}
                            >
                                {pickupAccuracyFormatted}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {loc('accuracy', lang)}
                            </Typography>
                        </div>
                        {pickedUpCommodities &&
                            pickedUpCommodities.map(({ commodity, total }) => {
                                const cmdty = _.find(commodities, { skuType: commodity });
                                const iconName = _.get(cmdty, 'iconName', 'mdiRecycle');
                                const unit = _commodity.getUnit(cmdty, lang);
                                return (
                                    <div style={boxStyleFlex}>
                                        <MDIcon
                                            key={iconName}
                                            path={allIcons[iconName]}
                                            color={theme.palette.primary.main}
                                            size="30px"
                                        />
                                        <Typography
                                            variant="h6"
                                            color="default"
                                            style={{ fontWeight: 400, marginTop: theme.spacing.unit }}
                                        >
                                            {total}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {_.startCase(_.toLower(commodity))}
                                            {unit && ` (${_.capitalize(unit)}s)`}
                                        </Typography>
                                    </div>
                                );
                            })}
                        <div style={boxStyleFlex}>
                            <Icon color="primary" style={{ fontSize: '30px' }}>
                                local_gas_station
                            </Icon>
                            <Typography
                                variant="h6"
                                color="default"
                                style={{ fontWeight: 400, marginTop: theme.spacing.unit }}
                            >
                                {distancePerDayFormatted}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                KM/{loc('day', lang)}
                            </Typography>
                        </div>
                    </>
                )}
                {isPayThroughSystem && (
                    <div style={boxStyleFlex}>
                        <Icon color="primary" style={{ fontSize: '30px' }}>
                            currency_exchange
                        </Icon>
                        <Typography
                            variant="h6"
                            color="default"
                            style={{ fontWeight: 400, marginTop: theme.spacing.unit }}
                            data-cy="driver-pay-per-hour"
                        >
                            {averagePayFormatted}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Pay ($/Hr)
                        </Typography>
                    </div>
                )}
                <div style={boxStyleFlex}>
                    <Icon color="primary" style={{ fontSize: '30px' }}>
                        timer
                    </Icon>
                    <Typography
                        variant="h6"
                        color="default"
                        style={{ fontWeight: 400, whiteSpace: 'nowrap', marginTop: theme.spacing.unit }}
                        data-cy="driver-total-time"
                    >
                        {_.get(driverFinancialInfo, 'totalDuration', 0)
                            ? _.round(_.get(driverFinancialInfo, 'totalDuration', 0), 2)
                            : 'N/A'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        <span style={{ whiteSpace: 'nowrap' }}> {loc('totalTime', lang)}</span> (Hr)
                    </Typography>
                </div>
                {isDriver && (
                    <>
                        {isPayThroughSystem && (
                            <div style={boxStyleFlex}>
                                <Icon color="primary" style={{ fontSize: '30px' }}>
                                    attach_money
                                </Icon>
                                <Typography
                                    variant="h6"
                                    color="default"
                                    style={{ fontWeight: 400, whiteSpace: 'nowrap', marginTop: theme.spacing.unit }}
                                    data-cy="driver-total-pay"
                                >
                                    {totalPayFormatted}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'nowrap' }}>
                                    Total Pay
                                </Typography>
                            </div>
                        )}
                        {totalGstFormatted && (
                            <div style={boxStyleFlex}>
                                <Icon color="primary" style={{ fontSize: '30px' }}>
                                    percent
                                </Icon>
                                <Typography
                                    variant="h6"
                                    color="default"
                                    style={{ fontWeight: 400, whiteSpace: 'nowrap', marginTop: theme.spacing.unit }}
                                >
                                    {totalGstFormatted}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'nowrap' }}>
                                    Total GST
                                </Typography>
                            </div>
                        )}
                    </>
                )}
            </div>
        </Paper>
    );
}

export default withTheme()(PickupRatingWidget);

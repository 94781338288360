import React, { useState, useEffect, useContext, useMemo } from 'react';
import _ from 'lodash';

import { withTheme } from '@material-ui/core/styles';
import { Tooltip, IconButton, Icon, Badge, colors, withStyles } from '@material-ui/core';

import CRUDTable from './CRUDTable';
import useCRUD from './hooks/useCRUD';

import HttpContext from 'utils/contexts/HttpContext';
import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';

import InlineChip from 'components/Chips/InlineChip';

import useGetJSON from 'utils/hooks/useGetJSON';
import useTaxGroups from 'utils/hooks/useTaxGroups';

import ServiceFeesForm from './Forms/CollectorForms/ServiceFeesForm';

import { Icon as MDIcon } from '@mdi/react';
import * as allIcons from '@mdi/js';
import moment from 'moment-timezone';

function ServiceFeesTable(props) {
    const { operator, theme } = props;

    const http = useContext(HttpContext);
    const onSnackbar = useContext(SnackbarContext);

    const endpoints = {
        getEndPoint: '/serviceFees',
        getEditEndPoint: _id => `/serviceFees/${_id}/updateServiceFee`, // url to edit documents NOTE: this should be a function
        createEndPoint: '/serviceFees/createServiceFee' // url to add new documents
    };

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [activeOrderBy, setActiveOrderBy] = useState('createdAt');
    const [activeOrder, setActiveOrder] = useState('desc');
    const {
        data,
        otherStats,
        startDateFilterStats,
        endDateFilterStats,
        loading,
        setLoading,
        setStartDateFilterStats,
        setEndDateFilterStats,
        dateFilterStatsErrorMessage,
        handleCreate,
        handleEdit,
        handleReloadData
    } = useCRUD({
        endpoints,
        setEditDialogOpen,
        setActiveOrderBy,
        setActiveOrder,
        http,
        onSnackbar
    });

    const { loading: commoditiesLoading, error: commoditiesLoadingErr, data: commodities } = useGetJSON(
        `/commodities/getAllCommodities`,
        'collectionData'
    );
    const { loading: ratesLoading, error: ratesError, data: ratesObj = { rates: [], masterRatesId: 0 } } = useGetJSON(
        '/rates/allRates'
    );
    const { loading: collectorsLoading, error: collectorsError, data: collectors } = useGetJSON(
        '/collectors',
        'collectors'
    );
    const { taxGroups, taxGroupsLoading } = useTaxGroups();

    const masterRates = useMemo(() => {
        return _.find(_.get(ratesObj, 'rates', []), { _id: _.get(ratesObj, 'masterRatesId') });
    }, [ratesObj]);

    const columns = [
        {
            key: 'default',
            header: 'Default',
            formatValue: value => {
                return value && <Icon style={{ color: colors.green[500] }}>check_circle</Icon>;
            }
        },
        {
            key: 'description',
            header: 'Description'
        },
        {
            key: `skuType`,
            header: 'Commodity',
            formatValue: skuType => {
                const commodity = _.find(commodities, { skuType });
                return (
                    <InlineChip
                        style={{ margin: theme.spacing.unit * 0.5 }}
                        color={_.get(commodity, 'color')}
                        textColor={'white'}
                        text={
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                <MDIcon
                                    path={_.get(allIcons, _.get(commodity, 'iconName'))}
                                    size={0.65}
                                    color="white"
                                    style={{ marginRight: theme.spacing.unit * 0.5 }}
                                />
                                {skuType}
                            </span>
                        }
                    />
                );
            }
        },
        {
            key: 'serviceFee',
            header: 'Service Fee',
            formatValue: (value, serviceFee) => {
                return (
                    <InlineChip
                        style={{ margin: theme.spacing.unit * 0.5 }}
                        color={_.get(serviceFee, 'type') === 'Percent Fee' ? colors.blue[500] : colors.green[500]}
                        textColor={'white'}
                        text={`${_.get(serviceFee, 'type')}: ${getTypePayoutText(serviceFee)}`}
                    />
                );
            }
        },
        {
            key: 'tax',
            header: 'Tax',
            formatValue: (value, serviceFee) => {
                const taxGroup = _.find(taxGroups, {
                    _id: serviceFee.tax
                });
                const taxes = _.get(taxGroup, 'taxes', []);
                return (
                    <div>
                        <InlineChip
                            style={{ margin: theme.spacing.unit * 0.5 }}
                            color={colors.blue[500]}
                            textColor={'white'}
                            text={_.get(taxGroup, 'name')}
                        />
                        {taxes.map(tax => `${tax.name} (${_.round(tax.configuration.value * 100, 5)}%)`).join(', ')}
                    </div>
                );
            }
        },
        {
            key: '_id',
            header: 'Collectors',
            formatValue: (value, serviceFee) => {
                const collectorsWithServiceFee = _.filter(collectors, c => {
                    const serviceFeesForCollector = _.get(c, 'configuration.serviceFees', []);
                    return !_.isNil(_.find(serviceFeesForCollector, { serviceFeeId: serviceFee._id }));
                });
                return (
                    <div>
                        {collectorsWithServiceFee.map(collector => {
                            return (
                                <InlineChip
                                    key={collector._id}
                                    style={{ margin: theme.spacing.unit * 0.5 }}
                                    color={colors.blue[500]}
                                    textColor={'white'}
                                    text={_.get(collector, 'code')}
                                />
                            );
                        })}
                    </div>
                );
            }
        },
        {
            key: '_id',
            header: 'Missing Service Fees',
            formatValue: (value, serviceFee) => {
                const serviceFeeSkuType = _.get(serviceFee, 'skuType');
                const collectorsWithoutServiceFee = _.filter(collectors, c => {
                    const serviceFeesForCollector = _.get(c, 'configuration.serviceFees', []);
                    return _.isNil(_.find(serviceFeesForCollector, { skuType: serviceFeeSkuType }));
                });
                return (
                    <div>
                        {collectorsWithoutServiceFee.map(collector => {
                            return (
                                <InlineChip
                                    key={collector._id}
                                    style={{ margin: theme.spacing.unit * 0.5 }}
                                    color={colors.red[500]}
                                    textColor={'white'}
                                    text={_.get(collector, 'code')}
                                />
                            );
                        })}
                    </div>
                );
            }
        }
    ];
    /*<ServiceFeeDialog
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            rates={masterRates}
            commodities={commodities}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
        />*/

    const editForm = (
        <ServiceFeesForm
            taxGroups={taxGroups}
            loading={taxGroupsLoading}
            rates={masterRates}
            commodities={commodities}
            theme={theme}
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
            serviceFee={selectedDocument}
            onSubmit={_.isNil(selectedDocument) ? handleCreate : handleEdit}
        />
    );

    return (
        <>
            <CRUDTable
                operator={operator}
                columns={columns}
                data={data}
                editForm={editForm}
                documentIsDisabled={serviceFee => _.get(serviceFee, 'disabled', false)}
                defaultRowsPerPage={5}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                editDialogOpen={editDialogOpen}
                setEditDialogOpen={setEditDialogOpen}
                activeOrderBy={activeOrderBy}
                setActiveOrderBy={setActiveOrderBy}
                activeOrder={activeOrder}
                setActiveOrder={setActiveOrder}
                enabledHeaderText={'Service Fees'}
                disabledHeaderText={'Disabled Service Fees'}
                loading={loading}
                hideDateFilters
            />
        </>
    );
}

export default withTheme()(ServiceFeesTable);

function getTypePayoutText(serviceFee) {
    if (_.get(serviceFee, 'type', '') === 'Percent Fee') {
        return `${serviceFee.value * 100}%`;
    } else if (_.get(serviceFee, 'type', '') === 'Count Split') {
        return `${_.get(serviceFee, 'split.customer', 0)}¢/${_.get(serviceFee, 'split.charity', 0)}¢/${_.get(
            serviceFee,
            'split.collector',
            0
        )}¢/${_.get(serviceFee, 'split.processor', 0)}¢`;
    }
}

import React, { useContext, useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';

import _ from 'lodash';
import { _time } from 'std';

import {
    formatsSecondsToTime,
    getWindowWidth,
    getWindowHeight,
    isAUSRegion,
    isEXPRegion,
    isCONRegion
} from 'utils/misc.js';

import {
    CircularProgress,
    Paper,
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    Icon,
    withWidth,
    Grid,
    InputAdornment,
    Divider
} from '@material-ui/core';

import { withTheme } from '@material-ui/core/styles';
/*import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';*/

import { _charity } from 'std';

import SnackbarContext from 'components/CustomSnackbar/SnackbarContext';
import HttpContext from 'utils/contexts/HttpContext';
import LocalizationContext from 'utils/contexts/LocalizationContext';
import useIsMounted from 'utils/hooks/useIsMounted';

import { loc } from 'localizations/localizationHandler';

import { AVAILABLE_LANGS, REGISTRATION_TABS } from '../../constants';
import useDefaultCoordinates from 'utils/hooks/useDefaultCoordinates';

import DatePicker from 'components/DateTimePickersTz/DatePicker';
import useDatePicker from 'components/DateTimePickersTz/hooks/useDatePicker';
import { TIMEZONES } from 'constants.js';
import ConfirmDialogContext from 'components/Dialogs/Confirm/ConfirmDialogContext';
import { useLocation } from 'react-router-dom';
import * as qs from 'query-string';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import ContactForm from 'components/ContactForm/ContactForm';

const splashScreenBackgroundOverlay = require('icons/Bottle-Pattern-White-Tiles-2.svg');
const splashScreenBackgroundOverlayNoMilk = require('icons/Bottle-Pattern-No-Jug.svg');
const splashScreenBackgroundOverlayEXP_1 = require('icons/Beverage_Icons_Express.png');
const splashScreenBackgroundOverlayEXP_2 = require('icons/Beverage_Icons_Express_2.png');

const backgroundImage = isSmallScreen => {
    if (isEXPRegion() && isSmallScreen) {
        return splashScreenBackgroundOverlayEXP_1;
    }

    if (isEXPRegion() && !isSmallScreen) {
        return splashScreenBackgroundOverlayEXP_2;
    }

    if (isCONRegion()) {
        // return splashScreenBackgroundOverlayNoMilk;
        return null;
    }

    return splashScreenBackgroundOverlay;
};
const frequency_to_text = {
    0: 'OTCFrequencyOnce',
    7: 'OTCFrequencyWeekly',
    14: 'OTCFrequencyOnce2Week',
    21: 'OTCFrequencyOnce3Week',
    28: 'OTCFrequencyOnce4Week',
    35: 'OTCFrequencyOnce5Week'
};
function ExternalOTCPortal(props) {
    const {
        history,
        location: reactRouterLocation,
        dispatch,
        theme,
        useFallbackData,
        showThirdPartyLogins,
        promosEnabled,
        helpCenterFunctions,
        auth
    } = props;

    const location = useLocation();

    const { lang, setLang } = useContext(LocalizationContext);
    const onSnackbar = useContext(SnackbarContext);
    const http = useContext(HttpContext);
    const warnAction = useContext(ConfirmDialogContext);

    const [splashScreen, setSplashScreen] = useState(false);

    const [windowWidth, setWindowWidth] = useState(getWindowWidth);
    const [windowHeight, setWindowHeight] = useState(getWindowHeight);

    const logo = require(process.env.REACT_APP_LOGO);

    const splashScreenBackgroundColor = isAUSRegion() ? '#454545' : theme.palette.primary.main;
    let angleAdjustment = windowHeight * 0.1;
    let splashScreenAdjustment = splashScreen ? windowHeight * 0.2 : 0;
    let mySVG = `<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 ${windowWidth} ${windowHeight}" class="css-conixt ekalwmn1"><path d="M0 ${windowHeight} L0 ${windowHeight /
        2 +
        angleAdjustment +
        splashScreenAdjustment} L${windowWidth} ${windowHeight / 2 -
        angleAdjustment +
        splashScreenAdjustment} L${windowWidth} ${windowHeight} Z" fill="#dde3e8" ></path></svg>`;
    let mySVG64 = window.btoa(mySVG);

    const isEXP = isEXPRegion();
    const isSmallScreen = windowWidth < 1000;

    const [loading, setLoading] = useState(false);
    const [accessCode, setAccessCode] = useState('');
    const [bookingFound, setBookingFound] = useState(false);
    const [OTCType, setOTCType] = useState('');
    const [OTCGroup, setOTCGroup] = useState({});
    const [OTCLocation, setOTCLocation] = useState({});
    const [showWarning, setShowWarning] = useState(false);
    const [showBookingDIalog, setShowBookingDialog] = useState(false);
    const [OTCLocationForBooking, setOTCLocationForBooking] = useState({});
    const [pageIsLoading, setPageIsLoading] = useState(false);
    const [contactFormOpen, setContactFormOpen] = useState(false);

    async function handleSeachAccessCode(accessCode) {
        setShowWarning(false);
        setLoading(true);
        const res = await http.postJSON('/getOTCBookings', { accessCode });
        if (res.ok) {
            let OTCGroupRes = _.get(res, 'data.group', null);
            if (!_.isNil(OTCGroupRes)) {
                setOTCGroup(OTCGroupRes);
                setBookingFound(true);
                setOTCType('group');
            } else {
                let OTCLocationRes = _.get(res, 'data.location', null);
                if (!_.isNil(OTCLocationRes)) {
                    setOTCLocation(OTCLocationRes);
                    setBookingFound(true);
                    setOTCType('location');
                } else {
                    setShowWarning(true);
                }
            }
        }
        setPageIsLoading(false);
        setLoading(false);
    }
    function handleReload() {
        if (OTCType === 'location') {
            handleSeachAccessCode(_.get(OTCLocation, 'accessCode', ''));
        } else if (OTCType === 'group') {
            handleSeachAccessCode(_.get(OTCGroup, 'accessCode', ''));
        }
    }

    async function bookPickup(OTCLocation, date, description) {
        const res = await http.post(
            '/bookOTCPickup',
            {
                date,
                OTCLocationId: _.get(OTCLocation, '_id', ''),
                description
            },
            true
        );
        if (res.ok) {
            onSnackbar(loc('OTCPickupSuccess', lang));
            setShowBookingDialog(false);
            handleReload();
        }
    }
    const handleDeleteStop = async OTCLocationId => {
        const res = await http.post(`/cancelOTCPickup`, { OTCLocationId: OTCLocationId });
        if (res.ok) {
            onSnackbar(loc('OTCCancelSuccess', lang));
            handleReload();
        }
    };

    useEffect(() => {
        const query = qs.parse(location.search);
        if (!_.isNil(query.code) && !_.isEmpty(query.code)) {
            setPageIsLoading(true);
            handleSeachAccessCode(query.code);
        }
    }, [location]);

    const searchView = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: theme.spacing.unit * 2
            }}
        >
            <Typography variant="h5" align="center" style={{ fontSize: '1.75rem' }}>
                {loc('OTCPortalTitle', lang)}
            </Typography>
            <Typography variant="subtitle1" align="center" style={{ fontSize: 14, marginTop: theme.spacing.unit }}>
                <span>
                    {loc('OTCInstruction', lang)}{' '}
                    <b
                        style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                        onClick={() => {
                            setContactFormOpen(true);
                        }}
                    >
                        <u>{`${process.env.REACT_APP_BRAND_NAME} support`}</u>
                    </b>
                </span>
            </Typography>
            <FormControl fullWidth style={{}}>
                <TextField
                    variant="outlined"
                    name="twoFactorAuthenticationPhoneNumber"
                    label={'Access Code'}
                    value={accessCode}
                    onChange={e => {
                        setAccessCode(e.target.value);
                    }}
                    style={{ marginTop: theme.spacing.unit }}
                />
                {showWarning && (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                        <Typography style={{ color: 'red' }}>{loc('OTCInvalidCode', lang)}</Typography>
                    </div>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: theme.spacing.unit }}
                    disabled={_.isEmpty(accessCode)}
                    onClick={() => {
                        handleSeachAccessCode(accessCode);
                    }}
                >
                    {loc('continue', lang)}{' '}
                    <CircularProgress
                        size={22}
                        thickness={4.8}
                        style={{
                            display: loading ? '' : 'none',
                            marginLeft: 4,
                            color: 'white',
                            verticalAlign: 'center'
                        }}
                    />
                </Button>
            </FormControl>
        </div>
    );
    const OTCLocationPickupIsBooked =
        !_.isNil(_.get(OTCLocation, 'pickup.date', null)) && _.isNil(_.get(OTCLocation, 'pickup.completionDate', null));
    const locationView = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: theme.spacing.unit * 2
            }}
        >
            <Typography variant="h5" align="center" style={{ fontSize: '1.75rem' }}>
                {loc('OTCLocationDetails', lang)}
            </Typography>

            <Grid container spacing={theme.spacing.unit} style={{ marginTop: 10 }}>
                <Grid item xs={4}>
                    <Typography variant="subtitle1">{loc('Name', lang)} </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1">{_.get(OTCLocation, 'name', '')} </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1">{loc('address', lang)} </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1">{_.get(OTCLocation, 'location.description', '')} </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1">{loc('Frequency', lang)} </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1">
                        {loc(frequency_to_text[_.get(OTCLocation, 'frequency', 0)], lang)}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1">{loc('description', lang)} </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1">{_.get(OTCLocation, 'description', '')}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1">{loc('status', lang)} </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography
                        variant="subtitle1"
                        style={{ color: _.isNil(_.get(OTCLocation, 'pickup', null)) ? 'red' : 'green' }}
                    >
                        {OTCLocationPickupIsBooked ? 'booked' : 'not booked'}
                    </Typography>
                </Grid>

                {OTCLocationPickupIsBooked && (
                    <Grid item xs={4}>
                        <Typography variant="subtitle1">{loc('OTCPickupDate', lang)} </Typography>
                    </Grid>
                )}
                {OTCLocationPickupIsBooked && (
                    <Grid item xs={8}>
                        <Typography variant="subtitle1">
                            {moment(_.get(OTCLocation, 'pickup.date'))
                                .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                .format('MMM D, YYYY')}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography variant="subtitle1" align="center" style={{ fontSize: 13 }}>
                        <span>
                            {loc('OTCHelpMessage', lang)}{' '}
                            <b
                                style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                                onClick={() => {
                                    setContactFormOpen(true);
                                }}
                            >
                                <u>{`${process.env.REACT_APP_BRAND_NAME} support`}</u>
                            </b>
                        </span>
                    </Typography>
                </Grid>
            </Grid>

            <FormControl
                fullWidth
                style={{
                    marginTop: theme.spacing.unit * 2
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end'
                    }}
                >
                    {OTCLocationPickupIsBooked ? (
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ marginLeft: theme.spacing.unit }}
                            onClick={() => {
                                warnAction(() => {
                                    handleDeleteStop(OTCLocation._id);
                                }, loc('OTCCancelConfirmation', lang));
                            }}
                        >
                            {loc('OTCCancelBooking', lang)}
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            style={{}}
                            onClick={() => {
                                setOTCLocationForBooking(OTCLocation);
                                setShowBookingDialog(true);
                            }}
                        >
                            {loc('book', lang)}
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: theme.spacing.unit }}
                        onClick={() => {
                            setAccessCode('');
                            setOTCType('');
                            setBookingFound(false);
                        }}
                    >
                        {loc('back', lang)}
                    </Button>
                </div>
            </FormControl>
        </div>
    );

    const groupView = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: theme.spacing.unit * 2
            }}
        >
            <Typography variant="h5" align="center" style={{ fontSize: '1.75rem' }}>
                {loc('OTCGroupDetails', lang)}
            </Typography>

            <Grid container spacing={theme.spacing.unit} style={{ marginTop: 10 }}>
                <Grid item xs={4}>
                    <Typography variant="subtitle1">{loc('Name', lang)}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1">{_.get(OTCGroup, 'name', '')}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1">{loc('email', lang)}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1">{_.get(OTCGroup, 'email', '')}</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="subtitle1">{loc('phoneNumber', lang)}</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="subtitle1">{formatPhoneNumber(_.get(OTCGroup, 'phone', ''))}</Typography>
                </Grid>

                <Grid item xs={12}>
                    {_.get(OTCGroup, 'locations', []).length === 0 ? (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Typography variant="subtitle1">{loc('OTCNoLocations', lang)}</Typography>
                        </div>
                    ) : (
                        <>
                            <Divider style={{ marginTop: 15, marginBottom: 15 }}></Divider>
                            <Typography variant="h5" align="center" style={{ fontSize: '1.75rem' }}>
                                {loc('locations', lang)}
                            </Typography>
                        </>
                    )}
                    {_.get(OTCGroup, 'locations', []).map((OTCLocation, index) => {
                        const OTCLocationPickupIsBooked =
                            !_.isNil(_.get(OTCLocation, 'pickup.date', null)) &&
                            _.isNil(_.get(OTCLocation, 'pickup.completionDate', null));
                        return (
                            <>
                                <Grid
                                    container
                                    spacing={theme.spacing.unit}
                                    style={{ paddingTop: 10, paddingBottom: 10 }}
                                >
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1">{loc('Name', lang)} </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="subtitle1">{_.get(OTCLocation, 'name', '')} </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1">{loc('address', lang)} </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="subtitle1">
                                            {_.get(OTCLocation, 'location.description', '')}{' '}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1">{loc('Frequency', lang)} </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="subtitle1">
                                            {loc(frequency_to_text[_.get(OTCLocation, 'frequency', 0)], lang)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1">{loc('description', lang)} </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="subtitle1">
                                            {_.get(OTCLocation, 'description', '')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle1">{loc('status', lang)} </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography
                                            variant="subtitle1"
                                            style={{
                                                color: _.isNil(_.get(OTCLocation, 'pickup', null)) ? 'red' : 'green'
                                            }}
                                        >
                                            {OTCLocationPickupIsBooked ? 'booked' : 'not booked'}
                                        </Typography>
                                    </Grid>

                                    {OTCLocationPickupIsBooked && (
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1">{loc('OTCPickupDate', lang)} </Typography>
                                        </Grid>
                                    )}
                                    {OTCLocationPickupIsBooked && (
                                        <Grid item xs={8}>
                                            <Typography variant="subtitle1">
                                                {moment(_.get(OTCLocation, 'pickup.date'))
                                                    .tz(process.env.REACT_APP_REGION_TIMEZONE)
                                                    .format('MMM D, YYYY')}
                                            </Typography>
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" align="center" style={{ fontSize: 13 }}>
                                            <span>
                                                {loc('OTCHelpMessage', lang)}{' '}
                                                <b
                                                    style={{ color: theme.palette.primary.main, cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setContactFormOpen(true);
                                                    }}
                                                >
                                                    <u>{`${process.env.REACT_APP_BRAND_NAME} support`}</u>
                                                </b>
                                            </span>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        {OTCLocationPickupIsBooked ? (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                fullWidth
                                                onClick={() => {
                                                    warnAction(() => {
                                                        handleDeleteStop(OTCLocation._id);
                                                    }, loc('OTCCancelConfirmation', lang));
                                                }}
                                            >
                                                {loc('OTCCancelBooking', lang)}
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={() => {
                                                    setOTCLocationForBooking(OTCLocation);
                                                    setShowBookingDialog(true);
                                                }}
                                            >
                                                {loc('book', lang)}
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                                {index < OTCGroup.locations.length - 1 && (
                                    <Divider style={{ marginTop: 10, marginBottom: 10 }}></Divider>
                                )}
                            </>
                        );
                    })}
                </Grid>
            </Grid>

            <FormControl
                fullWidth
                style={{
                    marginTop: theme.spacing.unit * 2
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'end'
                    }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: theme.spacing.unit }}
                        onClick={() => {
                            setAccessCode('');
                            setOTCType('');
                            setBookingFound(false);
                        }}
                    >
                        {loc('back', lang)}
                    </Button>
                </div>
            </FormControl>
        </div>
    );
    if (pageIsLoading) return <LoadingScreen />;
    return (
        <>
            <BookingDialog
                open={showBookingDIalog}
                onClose={() => {
                    setShowBookingDialog(false);
                }}
                onSubmit={(date, description) => {
                    bookPickup(OTCLocationForBooking, date, description);
                }}
                initDescription={_.get(OTCLocationForBooking, 'description', '')}
            />
            <ContactForm
                specification={'OTC'}
                http={http}
                open={contactFormOpen}
                onSubmitSuccess={() => {
                    onSnackbar(loc('OTCMessageSent', lang));
                    setContactFormOpen(false);
                }}
                onClose={() => {
                    setContactFormOpen(false);
                }}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                    backgroundColor: splashScreenBackgroundColor,
                    backgroundImage: `url("${backgroundImage()}")`,
                    backgroundRepeat: isEXP && isSmallScreen ? 'no-repeat' : 'repeat',
                    backgroundSize: isEXP ? (isSmallScreen ? '100%' : '500px') : '310px 310px'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                        minWidth: '100%',
                        backgroundImage: "url('data:image/svg+xml;base64," + mySVG64 + "')",
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'bottom',
                        backgroundSize: '100%',
                        overflowY: 'scroll'
                    }}
                >
                    <img
                        src={_.get(props, 'brandLogoOverride.url', logo)}
                        alt={`${process.env.REACT_APP_BRAND_NAME} logo`}
                        style={{
                            margin: '0px auto',
                            marginTop: '30px', //theme.spacing.unit * 5,
                            height: process.env.REACT_APP_REGION_EXT === 'STD' ? 'auto' : 100,
                            maxWidth: '85%'
                        }}
                    />
                    <div style={{ display: 'flex' }}>
                        <div
                            style={{
                                maxWidth: 480,
                                marginTop: '30px', //theme.spacing.unit * 3,
                                paddingTop: `calc(env(safe-area-inset-top)` //`calc(env(safe-area-inset-top) + ${theme.spacing.unit * 2}px)`
                            }}
                        >
                            <Paper
                                elevation={4}
                                style={{
                                    backgroundColor: theme.palette.background.default,
                                    margin: theme.spacing.unit * 2,
                                    marginTop: 0,
                                    marginBottom: theme.spacing.unit * 4,
                                    padding: theme.spacing.unit,
                                    borderRadius: 15
                                }}
                            >
                                {bookingFound
                                    ? OTCType === 'location'
                                        ? locationView
                                        : OTCType === 'group' && groupView
                                    : searchView}
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withWidth()(withTheme()(withRouter(ExternalOTCPortal)));

function BookingDialog({ open, onClose, onSubmit, lang, initDescription }) {
    const { date, timezone, handleChangeDate, handleChangeTimezone } = useDatePicker({
        saveStateInURL: false,
        timezones: TIMEZONES
    });
    const [description, setDescription] = useState(initDescription);

    useEffect(() => {
        setDescription(initDescription);
    }, [initDescription]);

    return (
        <Dialog open={open}>
            <DialogTitle style={{ paddingBottom: 10 }}>{loc('OTCBookingTitle', lang)}</DialogTitle>
            <DialogContent style={{ paddingBottom: 10 }}>
                <div style={{ marginTop: 10 }}>
                    <DatePicker
                        timezone={process.env.REACT_APP_REGION_TIMEZONE}
                        data-cy="pickup-date-select"
                        autoOk
                        label={loc('OTCPickupDate', lang)}
                        name="date"
                        value={date}
                        availableDates={getNext60Days(process.env.REACT_APP_REGION_TIMEZONE)}
                        variant="outlined"
                        onChange={handleChangeDate}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon>date_range</Icon>
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                <div style={{ marginTop: 10 }}>
                    <FormControl fullWidth>
                        <TextField
                            variant="outlined"
                            name="description"
                            label="Description"
                            value={description}
                            onChange={e => {
                                setDescription(e.target.value);
                            }}
                            multiline
                        />
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    type="submit"
                    color="primary"
                    data-cy="submit"
                    onClick={() => {
                        onSubmit(date, description);
                    }}
                >
                    submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function getNext60Days(timezone) {
    return _.range(0, 59).map(daysToAdd =>
        moment()
            .tz(timezone)
            .startOf('day')
            .add(daysToAdd, 'days')
            .toDate()
    );
}

function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
}
